export const play = [
  { p: "BASTARD", l: "ACT I", acl: "" },
  {
    p: "BASTARD",
    l: "SCENE I. Rome. A street.",
    acl: "",
  },
  {
    p: "BASTARD",
    l: "Enter FLAVIUS, MARULLUS, and certain Commoners",
    acl: "",
  },
  {
    p: "FLAVIUS",
    l: "Hence! home, you idle creatures get you home:",
    acl: "1.1.1",
  },
  { p: "FLAVIUS", l: "Is this a holiday? what! know you not,", acl: "1.1.2" },
  {
    p: "FLAVIUS",
    l: "Being mechanical, you ought not walk",
    acl: "1.1.3",
  },
  { p: "FLAVIUS", l: "Upon a labouring day without the sign", acl: "1.1.4" },
  {
    p: "FLAVIUS",
    l: "Of your profession? Speak, what trade art thou?",
    acl: "1.1.5",
  },
  { p: "First Commoner", l: "Why, sir, a carpenter.", acl: "1.1.6" },
  {
    p: "MARULLUS",
    l: "Where is thy leather apron and thy rule?",
    acl: "1.1.7",
  },
  {
    p: "MARULLUS",
    l: "What dost thou with thy best apparel on?",
    acl: "1.1.8",
  },
  {
    p: "MARULLUS",
    l: "You, sir, what trade are you?",
    acl: "1.1.9",
  },
  {
    p: "Second Commoner",
    l: "Truly, sir, in respect of a fine workman, I am but,",
    acl: "1.1.10",
  },
  { p: "Second Commoner", l: "as you would say, a cobbler.", acl: "1.1.11" },
  {
    p: "MARULLUS",
    l: "But what trade art thou? answer me directly.",
    acl: "1.1.12",
  },
  {
    p: "Second Commoner",
    l: "A trade, sir, that, I hope, I may use with a safe",
    acl: "1.1.13",
  },
  {
    p: "Second Commoner",
    l: "conscience, which is, indeed, sir, a mender of bad soles.",
    acl: "1.1.14",
  },
  {
    p: "MARULLUS",
    l: "What trade, thou knave? thou naughty knave, what trade?",
    acl: "1.1.15",
  },
  {
    p: "Second Commoner",
    l: "Nay, I beseech you, sir, be not out with me: yet,",
    acl: "1.1.16",
  },
  {
    p: "Second Commoner",
    l: "if you be out, sir, I can mend you.",
    acl: "1.1.17",
  },
  {
    p: "MARULLUS",
    l: "What meanest thou by that? mend me, thou saucy fellow!",
    acl: "1.1.18",
  },
  { p: "Second Commoner", l: "Why, sir, cobble you.", acl: "1.1.19" },
  {
    p: "FLAVIUS",
    l: "Thou art a cobbler, art thou?",
    acl: "1.1.20",
  },
  {
    p: "Second Commoner",
    l: "Truly, sir, all that I live by is with the awl: I",
    acl: "1.1.21",
  },
  {
    p: "Second Commoner",
    l: "meddle with no tradesman's matters, nor women's",
    acl: "1.1.22",
  },
  {
    p: "Second Commoner",
    l: "matters, but with awl. I am, indeed, sir, a surgeon",
    acl: "1.1.23",
  },
  {
    p: "Second Commoner",
    l: "to old shoes, when they are in great danger, I",
    acl: "1.1.24",
  },
  {
    p: "Second Commoner",
    l: "recover them. As proper men as ever trod upon",
    acl: "1.1.25",
  },
  {
    p: "Second Commoner",
    l: "neat's leather have gone upon my handiwork.",
    acl: "1.1.26",
  },
  {
    p: "FLAVIUS",
    l: "But wherefore art not in thy shop today?",
    acl: "1.1.27",
  },
  {
    p: "FLAVIUS",
    l: "Why dost thou lead these men about the streets?",
    acl: "1.1.28",
  },
  {
    p: "Second Commoner",
    l: "Truly, sir, to wear out their shoes, to get myself",
    acl: "1.1.29",
  },
  {
    p: "Second Commoner",
    l: "into more work. But, indeed, sir, we make holiday,",
    acl: "1.1.30",
  },
  {
    p: "Second Commoner",
    l: "to see Caesar and to rejoice in his triumph.",
    acl: "1.1.31",
  },
  {
    p: "MARULLUS",
    l: "Wherefore rejoice? What conquest brings he home?",
    acl: "1.1.32",
  },
  { p: "MARULLUS", l: "What tributaries follow him to Rome,", acl: "1.1.33" },
  {
    p: "MARULLUS",
    l: "To grace in captive bonds his chariot-wheels?",
    acl: "1.1.34",
  },
  {
    p: "MARULLUS",
    l: "You blocks, you stones, you worse than senseless things!",
    acl: "1.1.35",
  },
  {
    p: "MARULLUS",
    l: "O you hard hearts, you cruel men of Rome,",
    acl: "1.1.36",
  },
  {
    p: "MARULLUS",
    l: "Knew you not Pompey? Many a time and oft",
    acl: "1.1.37",
  },
  {
    p: "MARULLUS",
    l: "Have you climb'd up to walls and battlements,",
    acl: "1.1.38",
  },
  {
    p: "MARULLUS",
    l: "To towers and windows, yea, to chimney-tops,",
    acl: "1.1.39",
  },
  {
    p: "MARULLUS",
    l: "Your infants in your arms, and there have sat",
    acl: "1.1.40",
  },
  {
    p: "MARULLUS",
    l: "The livelong day, with patient expectation,",
    acl: "1.1.41",
  },
  {
    p: "MARULLUS",
    l: "To see great Pompey pass the streets of Rome:",
    acl: "1.1.42",
  },
  {
    p: "MARULLUS",
    l: "And when you saw his chariot but appear,",
    acl: "1.1.43",
  },
  { p: "MARULLUS", l: "Have you not made an universal shout,", acl: "1.1.44" },
  {
    p: "MARULLUS",
    l: "That Tiber trembled underneath her banks,",
    acl: "1.1.45",
  },
  { p: "MARULLUS", l: "To hear the replication of your sounds", acl: "1.1.46" },
  {
    p: "MARULLUS",
    l: "Made in her concave shores?",
    acl: "1.1.47",
  },
  {
    p: "MARULLUS",
    l: "And do you now put on your best attire?",
    acl: "1.1.48",
  },
  {
    p: "MARULLUS",
    l: "And do you now cull out a holiday?",
    acl: "1.1.49",
  },
  {
    p: "MARULLUS",
    l: "And do you now strew flowers in his way",
    acl: "1.1.50",
  },
  {
    p: "MARULLUS",
    l: "That comes in triumph over Pompey's blood? Be gone!",
    acl: "1.1.51",
  },
  {
    p: "MARULLUS",
    l: "Run to your houses, fall upon your knees,",
    acl: "1.1.52",
  },
  {
    p: "MARULLUS",
    l: "Pray to the gods to intermit the plague",
    acl: "1.1.53",
  },
  {
    p: "MARULLUS",
    l: "That needs must light on this ingratitude.",
    acl: "1.1.54",
  },
  {
    p: "FLAVIUS",
    l: "Go, go, good countrymen, and, for this fault,",
    acl: "1.1.55",
  },
  { p: "FLAVIUS", l: "Assemble all the poor men of your sort,", acl: "1.1.56" },
  {
    p: "FLAVIUS",
    l: "Draw them to Tiber banks, and weep your tears",
    acl: "1.1.57",
  },
  {
    p: "FLAVIUS",
    l: "Into the channel, till the lowest stream",
    acl: "1.1.58",
  },
  {
    p: "FLAVIUS",
    l: "Do kiss the most exalted shores of all.",
    acl: "1.1.59",
  },
  { p: "FLAVIUS", l: "Exeunt all the Commoners", acl: "" },
  {
    p: "FLAVIUS",
    l: "See whether their basest metal be not moved,",
    acl: "1.1.60",
  },
  {
    p: "FLAVIUS",
    l: "They vanish tongue-tied in their guiltiness.",
    acl: "1.1.61",
  },
  {
    p: "FLAVIUS",
    l: "Go you down that way towards the Capitol,",
    acl: "1.1.62",
  },
  { p: "FLAVIUS", l: "This way will I disrobe the images,", acl: "1.1.63" },
  {
    p: "FLAVIUS",
    l: "If you do find them deck'd with ceremonies.",
    acl: "1.1.64",
  },
  { p: "MARULLUS", l: "May we do so?", acl: "1.1.65" },
  {
    p: "MARULLUS",
    l: "You know it is the feast of Lupercal.",
    acl: "1.1.66",
  },
  { p: "FLAVIUS", l: "It is no matter, let no images", acl: "1.1.67" },
  {
    p: "FLAVIUS",
    l: "Be hung with Caesar's trophies. I'll about,",
    acl: "1.1.68",
  },
  {
    p: "FLAVIUS",
    l: "And drive away the vulgar from the streets:",
    acl: "1.1.69",
  },
  {
    p: "FLAVIUS",
    l: "So do you too, where you perceive them thick.",
    acl: "1.1.70",
  },
  {
    p: "FLAVIUS",
    l: "These growing feathers pluck'd from Caesar's wing",
    acl: "1.1.71",
  },
  {
    p: "FLAVIUS",
    l: "Will make him fly an ordinary pitch,",
    acl: "1.1.72",
  },
  {
    p: "FLAVIUS",
    l: "Who else would soar above the view of men",
    acl: "1.1.73",
  },
  {
    p: "FLAVIUS",
    l: "And keep us all in servile fearfulness.",
    acl: "1.1.74",
  },
  { p: "FLAVIUS", l: "Exeunt", acl: "" },
  {
    p: "FLAVIUS",
    l: "SCENE II. A public place.",
    acl: "",
  },
  {
    p: "FLAVIUS",
    l: "Flourish. Enter CAESAR, ANTONY, for the course,  CALPURNIA, PORTIA, DECIUS BRUTUS, CICERO, BRUTUS,  CASSIUS, and CASCA, a great crowd following, among them a Soothsayer",
    acl: "",
  },
  { p: "CAESAR", l: "Calpurnia!", acl: "1.2.1" },
  {
    p: "CASCA",
    l: "Peace, ho! Caesar speaks.",
    acl: "1.2.2",
  },
  { p: "CAESAR", l: "Calpurnia!", acl: "1.2.3" },
  {
    p: "CALPURNIA",
    l: "Here, my lord.",
    acl: "1.2.4",
  },
  { p: "CAESAR", l: "Stand you directly in Antonius' way,", acl: "1.2.5" },
  {
    p: "CAESAR",
    l: "When he doth run his course. Antonius!",
    acl: "1.2.6",
  },
  { p: "ANTONY", l: "Caesar, my lord?", acl: "1.2.7" },
  {
    p: "CAESAR",
    l: "Forget not, in your speed, Antonius,",
    acl: "1.2.8",
  },
  { p: "CAESAR", l: "To touch Calpurnia, for our elders say,", acl: "1.2.9" },
  {
    p: "CAESAR",
    l: "The barren, touched in this holy chase,",
    acl: "1.2.10",
  },
  { p: "CAESAR", l: "Shake off their sterile curse.", acl: "1.2.11" },
  {
    p: "ANTONY",
    l: "I shall remember:",
    acl: "1.2.12",
  },
  {
    p: "ANTONY",
    l: "When Caesar says 'do this,' it is perform'd.",
    acl: "1.2.13",
  },
  {
    p: "CAESAR",
    l: "Set on, and leave no ceremony out.",
    acl: "1.2.14",
  },
  { p: "CAESAR", l: "Flourish", acl: "" },
  { p: "Soothsayer", l: "Caesar!", acl: "1.2.15" },
  {
    p: "CAESAR",
    l: "Ha! who calls?",
    acl: "1.2.16",
  },
  {
    p: "CASCA",
    l: "Bid every noise be still: peace yet again!",
    acl: "1.2.17",
  },
  {
    p: "CAESAR",
    l: "Who is it in the press that calls on me?",
    acl: "1.2.18",
  },
  {
    p: "CAESAR",
    l: "I hear a tongue, shriller than all the music,",
    acl: "1.2.19",
  },
  {
    p: "CAESAR",
    l: "Cry 'Caesar!' Speak, Caesar is turn'd to hear.",
    acl: "1.2.20",
  },
  { p: "Soothsayer", l: "Beware the ides of March.", acl: "1.2.21" },
  {
    p: "CAESAR",
    l: "What man is that?",
    acl: "1.2.22",
  },
  {
    p: "BRUTUS",
    l: "A soothsayer bids you beware the ides of March.",
    acl: "1.2.23",
  },
  {
    p: "CAESAR",
    l: "Set him before me, let me see his face.",
    acl: "1.2.24",
  },
  {
    p: "CASSIUS",
    l: "Fellow, come from the throng, look upon Caesar.",
    acl: "1.2.25",
  },
  {
    p: "CAESAR",
    l: "What say'st thou to me now? speak once again.",
    acl: "1.2.26",
  },
  { p: "Soothsayer", l: "Beware the ides of March.", acl: "1.2.27" },
  {
    p: "CAESAR",
    l: "He is a dreamer, let us leave him: pass.",
    acl: "1.2.28",
  },
  { p: "CAESAR", l: "Sennet. Exeunt all except BRUTUS and CASSIUS", acl: "" },
  {
    p: "CASSIUS",
    l: "Will you go see the order of the course?",
    acl: "1.2.29",
  },
  { p: "BRUTUS", l: "Not I.", acl: "1.2.30" },
  {
    p: "CASSIUS",
    l: "I pray you, do.",
    acl: "1.2.31",
  },
  { p: "BRUTUS", l: "I am not gamesome: I do lack some part", acl: "1.2.32" },
  {
    p: "BRUTUS",
    l: "Of that quick spirit that is in Antony.",
    acl: "1.2.33",
  },
  {
    p: "BRUTUS",
    l: "Let me not hinder, Cassius, your desires,",
    acl: "1.2.34",
  },
  {
    p: "BRUTUS",
    l: "I'll leave you.",
    acl: "1.2.35",
  },
  { p: "CASSIUS", l: "Brutus, I do observe you now of late:", acl: "1.2.36" },
  {
    p: "CASSIUS",
    l: "I have not from your eyes that gentleness",
    acl: "1.2.37",
  },
  { p: "CASSIUS", l: "And show of love as I was wont to have:", acl: "1.2.38" },
  {
    p: "CASSIUS",
    l: "You bear too stubborn and too strange a hand",
    acl: "1.2.39",
  },
  { p: "CASSIUS", l: "Over your friend that loves you.", acl: "1.2.40" },
  {
    p: "BRUTUS",
    l: "Cassius,",
    acl: "1.2.41",
  },
  {
    p: "BRUTUS",
    l: "Be not deceived: if I have veil'd my look,",
    acl: "1.2.42",
  },
  {
    p: "BRUTUS",
    l: "I turn the trouble of my countenance",
    acl: "1.2.43",
  },
  { p: "BRUTUS", l: "Merely upon myself. Vexed I am", acl: "1.2.44" },
  {
    p: "BRUTUS",
    l: "Of late with passions of some difference,",
    acl: "1.2.45",
  },
  { p: "BRUTUS", l: "Conceptions only proper to myself,", acl: "1.2.46" },
  {
    p: "BRUTUS",
    l: "Which give some soil perhaps to my behaviors,",
    acl: "1.2.47",
  },
  {
    p: "BRUTUS",
    l: "But let not therefore my good friends be grieved--",
    acl: "1.2.48",
  },
  {
    p: "BRUTUS",
    l: "Among which number, Cassius, be you one--",
    acl: "1.2.49",
  },
  { p: "BRUTUS", l: "Nor construe any further my neglect,", acl: "1.2.50" },
  {
    p: "BRUTUS",
    l: "Than that poor Brutus, with himself at war,",
    acl: "1.2.51",
  },
  { p: "BRUTUS", l: "Forgets the shows of love to other men.", acl: "1.2.52" },
  {
    p: "CASSIUS",
    l: "Then, Brutus, I have much mistook your passion,",
    acl: "1.2.53",
  },
  {
    p: "CASSIUS",
    l: "By means whereof this breast of mine hath buried",
    acl: "1.2.54",
  },
  {
    p: "CASSIUS",
    l: "Thoughts of great value, worthy cogitations.",
    acl: "1.2.55",
  },
  {
    p: "CASSIUS",
    l: "Tell me, good Brutus, can you see your face?",
    acl: "1.2.56",
  },
  {
    p: "BRUTUS",
    l: "No, Cassius, for the eye sees not itself,",
    acl: "1.2.57",
  },
  { p: "BRUTUS", l: "But by reflection, by some other things.", acl: "1.2.58" },
  {
    p: "CASSIUS",
    l: "'Tis just:",
    acl: "1.2.59",
  },
  { p: "CASSIUS", l: "And it is very much lamented, Brutus,", acl: "1.2.60" },
  {
    p: "CASSIUS",
    l: "That you have no such mirrors as will turn",
    acl: "1.2.61",
  },
  { p: "CASSIUS", l: "Your hidden worthiness into your eye,", acl: "1.2.62" },
  {
    p: "CASSIUS",
    l: "That you might see your shadow. I have heard,",
    acl: "1.2.63",
  },
  { p: "CASSIUS", l: "Where many of the best respect in Rome,", acl: "1.2.64" },
  {
    p: "CASSIUS",
    l: "Except immortal Caesar, speaking of Brutus",
    acl: "1.2.65",
  },
  {
    p: "CASSIUS",
    l: "And groaning underneath this age's yoke,",
    acl: "1.2.66",
  },
  {
    p: "CASSIUS",
    l: "Have wish'd that noble Brutus had his eyes.",
    acl: "1.2.67",
  },
  {
    p: "BRUTUS",
    l: "Into what dangers would you lead me, Cassius,",
    acl: "1.2.68",
  },
  {
    p: "BRUTUS",
    l: "That you would have me seek into myself",
    acl: "1.2.69",
  },
  { p: "BRUTUS", l: "For that which is not in me?", acl: "1.2.70" },
  {
    p: "CASSIUS",
    l: "Therefore, good Brutus, be prepared to hear:",
    acl: "1.2.71",
  },
  {
    p: "CASSIUS",
    l: "And since you know you cannot see yourself",
    acl: "1.2.72",
  },
  {
    p: "CASSIUS",
    l: "So well as by reflection, I, your glass,",
    acl: "1.2.73",
  },
  { p: "CASSIUS", l: "Will modestly discover to yourself", acl: "1.2.74" },
  {
    p: "CASSIUS",
    l: "That of yourself which you yet know not of.",
    acl: "1.2.75",
  },
  {
    p: "CASSIUS",
    l: "And be not jealous on me, gentle Brutus:",
    acl: "1.2.76",
  },
  {
    p: "CASSIUS",
    l: "Were I a common laugher, or did use",
    acl: "1.2.77",
  },
  { p: "CASSIUS", l: "To stale with ordinary oaths my love", acl: "1.2.78" },
  {
    p: "CASSIUS",
    l: "To every new protester, if you know",
    acl: "1.2.79",
  },
  { p: "CASSIUS", l: "That I do fawn on men and hug them hard", acl: "1.2.80" },
  {
    p: "CASSIUS",
    l: "And after scandal them, or if you know",
    acl: "1.2.81",
  },
  { p: "CASSIUS", l: "That I profess myself in banqueting", acl: "1.2.82" },
  {
    p: "CASSIUS",
    l: "To all the rout, then hold me dangerous.",
    acl: "1.2.83",
  },
  { p: "CASSIUS", l: "Flourish, and shout", acl: "" },
  {
    p: "BRUTUS",
    l: "What means this shouting? I do fear, the people",
    acl: "1.2.84",
  },
  { p: "BRUTUS", l: "Choose Caesar for their king.", acl: "1.2.85" },
  {
    p: "CASSIUS",
    l: "Ay, do you fear it?",
    acl: "1.2.86",
  },
  {
    p: "CASSIUS",
    l: "Then must I think you would not have it so.",
    acl: "1.2.87",
  },
  {
    p: "BRUTUS",
    l: "I would not, Cassius, yet I love him well.",
    acl: "1.2.88",
  },
  {
    p: "BRUTUS",
    l: "But wherefore do you hold me here so long?",
    acl: "1.2.89",
  },
  {
    p: "BRUTUS",
    l: "What is it that you would impart to me?",
    acl: "1.2.90",
  },
  { p: "BRUTUS", l: "If it be aught toward the general good,", acl: "1.2.91" },
  {
    p: "BRUTUS",
    l: "Set honour in one eye and death i' the other,",
    acl: "1.2.92",
  },
  { p: "BRUTUS", l: "And I will look on both indifferently,", acl: "1.2.93" },
  {
    p: "BRUTUS",
    l: "For let the gods so speed me as I love",
    acl: "1.2.94",
  },
  {
    p: "BRUTUS",
    l: "The name of honour more than I fear death.",
    acl: "1.2.95",
  },
  {
    p: "CASSIUS",
    l: "I know that virtue to be in you, Brutus,",
    acl: "1.2.96",
  },
  {
    p: "CASSIUS",
    l: "As well as I do know your outward favour.",
    acl: "1.2.97",
  },
  {
    p: "CASSIUS",
    l: "Well, honour is the subject of my story.",
    acl: "1.2.98",
  },
  { p: "CASSIUS", l: "I cannot tell what you and other men", acl: "1.2.99" },
  {
    p: "CASSIUS",
    l: "Think of this life, but, for my single self,",
    acl: "1.2.100",
  },
  { p: "CASSIUS", l: "I had as lief not be as live to be", acl: "1.2.101" },
  {
    p: "CASSIUS",
    l: "In awe of such a thing as I myself.",
    acl: "1.2.102",
  },
  {
    p: "CASSIUS",
    l: "I was born free as Caesar, so were you:",
    acl: "1.2.103",
  },
  {
    p: "CASSIUS",
    l: "We both have fed as well, and we can both",
    acl: "1.2.104",
  },
  {
    p: "CASSIUS",
    l: "Endure the winter's cold as well as he:",
    acl: "1.2.105",
  },
  {
    p: "CASSIUS",
    l: "For once, upon a raw and gusty day,",
    acl: "1.2.106",
  },
  {
    p: "CASSIUS",
    l: "The troubled Tiber chafing with her shores,",
    acl: "1.2.107",
  },
  {
    p: "CASSIUS",
    l: "Caesar said to me 'Darest thou, Cassius, now",
    acl: "1.2.108",
  },
  { p: "CASSIUS", l: "Leap in with me into this angry flood,", acl: "1.2.109" },
  {
    p: "CASSIUS",
    l: "And swim to yonder point?' Upon the word,",
    acl: "1.2.110",
  },
  { p: "CASSIUS", l: "Accoutred as I was, I plunged in", acl: "1.2.111" },
  {
    p: "CASSIUS",
    l: "And bade him follow, so indeed he did.",
    acl: "1.2.112",
  },
  {
    p: "CASSIUS",
    l: "The torrent roar'd, and we did buffet it",
    acl: "1.2.113",
  },
  {
    p: "CASSIUS",
    l: "With lusty sinews, throwing it aside",
    acl: "1.2.114",
  },
  {
    p: "CASSIUS",
    l: "And stemming it with hearts of controversy,",
    acl: "1.2.115",
  },
  {
    p: "CASSIUS",
    l: "But ere we could arrive the point proposed,",
    acl: "1.2.116",
  },
  {
    p: "CASSIUS",
    l: "Caesar cried 'Help me, Cassius, or I sink!'",
    acl: "1.2.117",
  },
  {
    p: "CASSIUS",
    l: "I, as Aeneas, our great ancestor,",
    acl: "1.2.118",
  },
  {
    p: "CASSIUS",
    l: "Did from the flames of Troy upon his shoulder",
    acl: "1.2.119",
  },
  {
    p: "CASSIUS",
    l: "The old Anchises bear, so from the waves of Tiber",
    acl: "1.2.120",
  },
  { p: "CASSIUS", l: "Did I the tired Caesar. And this man", acl: "1.2.121" },
  {
    p: "CASSIUS",
    l: "Is now become a god, and Cassius is",
    acl: "1.2.122",
  },
  {
    p: "CASSIUS",
    l: "A wretched creature and must bend his body,",
    acl: "1.2.123",
  },
  {
    p: "CASSIUS",
    l: "If Caesar carelessly but nod on him.",
    acl: "1.2.124",
  },
  { p: "CASSIUS", l: "He had a fever when he was in Spain,", acl: "1.2.125" },
  {
    p: "CASSIUS",
    l: "And when the fit was on him, I did mark",
    acl: "1.2.126",
  },
  {
    p: "CASSIUS",
    l: "How he did shake: 'tis true, this god did shake,",
    acl: "1.2.127",
  },
  {
    p: "CASSIUS",
    l: "His coward lips did from their colour fly,",
    acl: "1.2.128",
  },
  {
    p: "CASSIUS",
    l: "And that same eye whose bend doth awe the world",
    acl: "1.2.129",
  },
  {
    p: "CASSIUS",
    l: "Did lose his lustre: I did hear him groan:",
    acl: "1.2.130",
  },
  {
    p: "CASSIUS",
    l: "Ay, and that tongue of his that bade the Romans",
    acl: "1.2.131",
  },
  {
    p: "CASSIUS",
    l: "Mark him and write his speeches in their books,",
    acl: "1.2.132",
  },
  {
    p: "CASSIUS",
    l: "Alas, it cried 'Give me some drink, Titinius,'",
    acl: "1.2.133",
  },
  {
    p: "CASSIUS",
    l: "As a sick girl. Ye gods, it doth amaze me",
    acl: "1.2.134",
  },
  { p: "CASSIUS", l: "A man of such a feeble temper should", acl: "1.2.135" },
  {
    p: "CASSIUS",
    l: "So get the start of the majestic world",
    acl: "1.2.136",
  },
  { p: "CASSIUS", l: "And bear the palm alone.", acl: "1.2.137" },
  {
    p: "CASSIUS",
    l: "Shout. Flourish",
    acl: "",
  },
  { p: "BRUTUS", l: "Another general shout!", acl: "1.2.138" },
  {
    p: "BRUTUS",
    l: "I do believe that these applauses are",
    acl: "1.2.139",
  },
  {
    p: "BRUTUS",
    l: "For some new honours that are heap'd on Caesar.",
    acl: "1.2.140",
  },
  {
    p: "CASSIUS",
    l: "Why, man, he doth bestride the narrow world",
    acl: "1.2.141",
  },
  { p: "CASSIUS", l: "Like a Colossus, and we petty men", acl: "1.2.142" },
  {
    p: "CASSIUS",
    l: "Walk under his huge legs and peep about",
    acl: "1.2.143",
  },
  {
    p: "CASSIUS",
    l: "To find ourselves dishonourable graves.",
    acl: "1.2.144",
  },
  {
    p: "CASSIUS",
    l: "Men at some time are masters of their fates:",
    acl: "1.2.145",
  },
  {
    p: "CASSIUS",
    l: "The fault, dear Brutus, is not in our stars,",
    acl: "1.2.146",
  },
  {
    p: "CASSIUS",
    l: "But in ourselves, that we are underlings.",
    acl: "1.2.147",
  },
  {
    p: "CASSIUS",
    l: "Brutus and Caesar: what should be in that 'Caesar'?",
    acl: "1.2.148",
  },
  {
    p: "CASSIUS",
    l: "Why should that name be sounded more than yours?",
    acl: "1.2.149",
  },
  {
    p: "CASSIUS",
    l: "Write them together, yours is as fair a name,",
    acl: "1.2.150",
  },
  {
    p: "CASSIUS",
    l: "Sound them, it doth become the mouth as well,",
    acl: "1.2.151",
  },
  {
    p: "CASSIUS",
    l: "Weigh them, it is as heavy, conjure with 'em,",
    acl: "1.2.152",
  },
  {
    p: "CASSIUS",
    l: "Brutus will start a spirit as soon as Caesar.",
    acl: "1.2.153",
  },
  {
    p: "CASSIUS",
    l: "Now, in the names of all the gods at once,",
    acl: "1.2.154",
  },
  {
    p: "CASSIUS",
    l: "Upon what meat doth this our Caesar feed,",
    acl: "1.2.155",
  },
  {
    p: "CASSIUS",
    l: "That he is grown so great? Age, thou art shamed!",
    acl: "1.2.156",
  },
  {
    p: "CASSIUS",
    l: "Rome, thou hast lost the breed of noble bloods!",
    acl: "1.2.157",
  },
  {
    p: "CASSIUS",
    l: "When went there by an age, since the great flood,",
    acl: "1.2.158",
  },
  {
    p: "CASSIUS",
    l: "But it was famed with more than with one man?",
    acl: "1.2.159",
  },
  {
    p: "CASSIUS",
    l: "When could they say till now, that talk'd of Rome,",
    acl: "1.2.160",
  },
  {
    p: "CASSIUS",
    l: "That her wide walls encompass'd but one man?",
    acl: "1.2.161",
  },
  { p: "CASSIUS", l: "Now is it Rome indeed and room enough,", acl: "1.2.162" },
  {
    p: "CASSIUS",
    l: "When there is in it but one only man.",
    acl: "1.2.163",
  },
  {
    p: "CASSIUS",
    l: "O, you and I have heard our fathers say,",
    acl: "1.2.164",
  },
  {
    p: "CASSIUS",
    l: "There was a Brutus once that would have brook'd",
    acl: "1.2.165",
  },
  {
    p: "CASSIUS",
    l: "The eternal devil to keep his state in Rome",
    acl: "1.2.166",
  },
  {
    p: "CASSIUS",
    l: "As easily as a king.",
    acl: "1.2.167",
  },
  {
    p: "BRUTUS",
    l: "That you do love me, I am nothing jealous,",
    acl: "1.2.168",
  },
  {
    p: "BRUTUS",
    l: "What you would work me to, I have some aim:",
    acl: "1.2.169",
  },
  {
    p: "BRUTUS",
    l: "How I have thought of this and of these times,",
    acl: "1.2.170",
  },
  {
    p: "BRUTUS",
    l: "I shall recount hereafter, for this present,",
    acl: "1.2.171",
  },
  {
    p: "BRUTUS",
    l: "I would not, so with love I might entreat you,",
    acl: "1.2.172",
  },
  {
    p: "BRUTUS",
    l: "Be any further moved. What you have said",
    acl: "1.2.173",
  },
  { p: "BRUTUS", l: "I will consider, what you have to say", acl: "1.2.174" },
  {
    p: "BRUTUS",
    l: "I will with patience hear, and find a time",
    acl: "1.2.175",
  },
  {
    p: "BRUTUS",
    l: "Both meet to hear and answer such high things.",
    acl: "1.2.176",
  },
  {
    p: "BRUTUS",
    l: "Till then, my noble friend, chew upon this:",
    acl: "1.2.177",
  },
  { p: "BRUTUS", l: "Brutus had rather be a villager", acl: "1.2.178" },
  {
    p: "BRUTUS",
    l: "Than to repute himself a son of Rome",
    acl: "1.2.179",
  },
  {
    p: "BRUTUS",
    l: "Under these hard conditions as this time",
    acl: "1.2.180",
  },
  {
    p: "BRUTUS",
    l: "Is like to lay upon us.",
    acl: "1.2.181",
  },
  { p: "CASSIUS", l: "I am glad that my weak words", acl: "1.2.182" },
  {
    p: "CASSIUS",
    l: "Have struck but thus much show of fire from Brutus.",
    acl: "1.2.183",
  },
  {
    p: "BRUTUS",
    l: "The games are done and Caesar is returning.",
    acl: "1.2.184",
  },
  {
    p: "CASSIUS",
    l: "As they pass by, pluck Casca by the sleeve,",
    acl: "1.2.185",
  },
  {
    p: "CASSIUS",
    l: "And he will, after his sour fashion, tell you",
    acl: "1.2.186",
  },
  {
    p: "CASSIUS",
    l: "What hath proceeded worthy note to-day.",
    acl: "1.2.187",
  },
  { p: "CASSIUS", l: "Re-enter CAESAR and his Train", acl: "" },
  {
    p: "BRUTUS",
    l: "I will do so. But, look you, Cassius,",
    acl: "1.2.188",
  },
  {
    p: "BRUTUS",
    l: "The angry spot doth glow on Caesar's brow,",
    acl: "1.2.189",
  },
  {
    p: "BRUTUS",
    l: "And all the rest look like a chidden train:",
    acl: "1.2.190",
  },
  { p: "BRUTUS", l: "Calpurnia's cheek is pale, and Cicero", acl: "1.2.191" },
  {
    p: "BRUTUS",
    l: "Looks with such ferret and such fiery eyes",
    acl: "1.2.192",
  },
  { p: "BRUTUS", l: "As we have seen him in the Capitol,", acl: "1.2.193" },
  {
    p: "BRUTUS",
    l: "Being cross'd in conference by some senators.",
    acl: "1.2.194",
  },
  { p: "CASSIUS", l: "Casca will tell us what the matter is.", acl: "1.2.195" },
  {
    p: "CAESAR",
    l: "Antonius!",
    acl: "1.2.196",
  },
  { p: "ANTONY", l: "Caesar?", acl: "1.2.197" },
  {
    p: "CAESAR",
    l: "Let me have men about me that are fat,",
    acl: "1.2.198",
  },
  {
    p: "CAESAR",
    l: "Sleek-headed men and such as sleep o' nights:",
    acl: "1.2.199",
  },
  {
    p: "CAESAR",
    l: "Yond Cassius has a lean and hungry look,",
    acl: "1.2.200",
  },
  {
    p: "CAESAR",
    l: "He thinks too much: such men are dangerous.",
    acl: "1.2.201",
  },
  {
    p: "ANTONY",
    l: "Fear him not, Caesar, he's not dangerous,",
    acl: "1.2.202",
  },
  { p: "ANTONY", l: "He is a noble Roman and well given.", acl: "1.2.203" },
  {
    p: "CAESAR",
    l: "Would he were fatter! But I fear him not:",
    acl: "1.2.204",
  },
  { p: "CAESAR", l: "Yet if my name were liable to fear,", acl: "1.2.205" },
  {
    p: "CAESAR",
    l: "I do not know the man I should avoid",
    acl: "1.2.206",
  },
  {
    p: "CAESAR",
    l: "So soon as that spare Cassius. He reads much,",
    acl: "1.2.207",
  },
  {
    p: "CAESAR",
    l: "He is a great observer and he looks",
    acl: "1.2.208",
  },
  {
    p: "CAESAR",
    l: "Quite through the deeds of men: he loves no plays,",
    acl: "1.2.209",
  },
  {
    p: "CAESAR",
    l: "As thou dost, Antony, he hears no music,",
    acl: "1.2.210",
  },
  {
    p: "CAESAR",
    l: "Seldom he smiles, and smiles in such a sort",
    acl: "1.2.211",
  },
  {
    p: "CAESAR",
    l: "As if he mock'd himself and scorn'd his spirit",
    acl: "1.2.212",
  },
  {
    p: "CAESAR",
    l: "That could be moved to smile at any thing.",
    acl: "1.2.213",
  },
  {
    p: "CAESAR",
    l: "Such men as he be never at heart's ease",
    acl: "1.2.214",
  },
  {
    p: "CAESAR",
    l: "Whiles they behold a greater than themselves,",
    acl: "1.2.215",
  },
  {
    p: "CAESAR",
    l: "And therefore are they very dangerous.",
    acl: "1.2.216",
  },
  { p: "CAESAR", l: "I rather tell thee what is to be fear'd", acl: "1.2.217" },
  {
    p: "CAESAR",
    l: "Than what I fear, for always I am Caesar.",
    acl: "1.2.218",
  },
  {
    p: "CAESAR",
    l: "Come on my right hand, for this ear is deaf,",
    acl: "1.2.219",
  },
  {
    p: "CAESAR",
    l: "And tell me truly what thou think'st of him.",
    acl: "1.2.220",
  },
  {
    p: "CAESAR",
    l: "Sennet. Exeunt CAESAR and all his Train, but CASCA",
    acl: "",
  },
  {
    p: "CASCA",
    l: "You pull'd me by the cloak, would you speak with me?",
    acl: "1.2.221",
  },
  {
    p: "BRUTUS",
    l: "Ay, Casca, tell us what hath chanced to-day,",
    acl: "1.2.222",
  },
  {
    p: "BRUTUS",
    l: "That Caesar looks so sad.",
    acl: "1.2.223",
  },
  { p: "CASCA", l: "Why, you were with him, were you not?", acl: "1.2.224" },
  {
    p: "BRUTUS",
    l: "I should not then ask Casca what had chanced.",
    acl: "1.2.225",
  },
  {
    p: "CASCA",
    l: "Why, there was a crown offered him: and being",
    acl: "1.2.226",
  },
  {
    p: "CASCA",
    l: "offered him, he put it by with the back of his hand,",
    acl: "1.2.227",
  },
  {
    p: "CASCA",
    l: "thus, and then the people fell a-shouting.",
    acl: "1.2.228",
  },
  {
    p: "BRUTUS",
    l: "What was the second noise for?",
    acl: "1.2.229",
  },
  { p: "CASCA", l: "Why, for that too.", acl: "1.2.230" },
  {
    p: "CASSIUS",
    l: "They shouted thrice: what was the last cry for?",
    acl: "1.2.231",
  },
  { p: "CASCA", l: "Why, for that too.", acl: "1.2.232" },
  {
    p: "BRUTUS",
    l: "Was the crown offered him thrice?",
    acl: "1.2.233",
  },
  {
    p: "CASCA",
    l: "Ay, marry, was't, and he put it by thrice, every",
    acl: "1.2.234",
  },
  {
    p: "CASCA",
    l: "time gentler than other, and at every putting-by",
    acl: "1.2.235",
  },
  { p: "CASCA", l: "mine honest neighbours shouted.", acl: "1.2.236" },
  {
    p: "CASSIUS",
    l: "Who offered him the crown?",
    acl: "1.2.237",
  },
  { p: "CASCA", l: "Why, Antony.", acl: "1.2.238" },
  {
    p: "BRUTUS",
    l: "Tell us the manner of it, gentle Casca.",
    acl: "1.2.239",
  },
  {
    p: "CASCA",
    l: "I can as well be hanged as tell the manner of it:",
    acl: "1.2.240",
  },
  {
    p: "CASCA",
    l: "it was mere foolery, I did not mark it. I saw Mark",
    acl: "1.2.241",
  },
  {
    p: "CASCA",
    l: "Antony offer him a crown,--yet 'twas not a crown",
    acl: "1.2.242",
  },
  {
    p: "CASCA",
    l: "neither, 'twas one of these coronets,--and, as I told",
    acl: "1.2.243",
  },
  {
    p: "CASCA",
    l: "you, he put it by once: but, for all that, to my",
    acl: "1.2.244",
  },
  {
    p: "CASCA",
    l: "thinking, he would fain have had it. Then he",
    acl: "1.2.245",
  },
  {
    p: "CASCA",
    l: "offered it to him again, then he put it by again:",
    acl: "1.2.246",
  },
  {
    p: "CASCA",
    l: "but, to my thinking, he was very loath to lay his",
    acl: "1.2.247",
  },
  {
    p: "CASCA",
    l: "fingers off it. And then he offered it the third",
    acl: "1.2.248",
  },
  {
    p: "CASCA",
    l: "time, he put it the third time by: and still as he",
    acl: "1.2.249",
  },
  {
    p: "CASCA",
    l: "refused it, the rabblement hooted and clapped their",
    acl: "1.2.250",
  },
  {
    p: "CASCA",
    l: "chapped hands and threw up their sweaty night-caps",
    acl: "1.2.251",
  },
  {
    p: "CASCA",
    l: "and uttered such a deal of stinking breath because",
    acl: "1.2.252",
  },
  {
    p: "CASCA",
    l: "Caesar refused the crown that it had almost choked",
    acl: "1.2.253",
  },
  {
    p: "CASCA",
    l: "Caesar, for he swounded and fell down at it: and",
    acl: "1.2.254",
  },
  {
    p: "CASCA",
    l: "for mine own part, I durst not laugh, for fear of",
    acl: "1.2.255",
  },
  {
    p: "CASCA",
    l: "opening my lips and receiving the bad air.",
    acl: "1.2.256",
  },
  {
    p: "CASSIUS",
    l: "But, soft, I pray you: what, did Caesar swound?",
    acl: "1.2.257",
  },
  {
    p: "CASCA",
    l: "He fell down in the market-place, and foamed at",
    acl: "1.2.258",
  },
  {
    p: "CASCA",
    l: "mouth, and was speechless.",
    acl: "1.2.259",
  },
  {
    p: "BRUTUS",
    l: "'Tis very like: he hath the failing sickness.",
    acl: "1.2.260",
  },
  {
    p: "CASSIUS",
    l: "No, Caesar hath it not, but you and I,",
    acl: "1.2.261",
  },
  {
    p: "CASSIUS",
    l: "And honest Casca, we have the falling sickness.",
    acl: "1.2.262",
  },
  {
    p: "CASCA",
    l: "I know not what you mean by that, but, I am sure,",
    acl: "1.2.263",
  },
  {
    p: "CASCA",
    l: "Caesar fell down. If the tag-rag people did not",
    acl: "1.2.264",
  },
  {
    p: "CASCA",
    l: "clap him and hiss him, according as he pleased and",
    acl: "1.2.265",
  },
  {
    p: "CASCA",
    l: "displeased them, as they use to do the players in",
    acl: "1.2.266",
  },
  {
    p: "CASCA",
    l: "the theatre, I am no true man.",
    acl: "1.2.267",
  },
  { p: "BRUTUS", l: "What said he when he came unto himself?", acl: "1.2.268" },
  {
    p: "CASCA",
    l: "Marry, before he fell down, when he perceived the",
    acl: "1.2.269",
  },
  {
    p: "CASCA",
    l: "common herd was glad he refused the crown, he",
    acl: "1.2.270",
  },
  {
    p: "CASCA",
    l: "plucked me ope his doublet and offered them his",
    acl: "1.2.271",
  },
  {
    p: "CASCA",
    l: "throat to cut. An I had been a man of any",
    acl: "1.2.272",
  },
  {
    p: "CASCA",
    l: "occupation, if I would not have taken him at a word,",
    acl: "1.2.273",
  },
  {
    p: "CASCA",
    l: "I would I might go to hell among the rogues. And so",
    acl: "1.2.274",
  },
  {
    p: "CASCA",
    l: "he fell. When he came to himself again, he said,",
    acl: "1.2.275",
  },
  {
    p: "CASCA",
    l: "If he had done or said any thing amiss, he desired",
    acl: "1.2.276",
  },
  {
    p: "CASCA",
    l: "their worships to think it was his infirmity. Three",
    acl: "1.2.277",
  },
  {
    p: "CASCA",
    l: "or four wenches, where I stood, cried 'Alas, good",
    acl: "1.2.278",
  },
  {
    p: "CASCA",
    l: "soul!' and forgave him with all their hearts: but",
    acl: "1.2.279",
  },
  {
    p: "CASCA",
    l: "there's no heed to be taken of them, if Caesar had",
    acl: "1.2.280",
  },
  {
    p: "CASCA",
    l: "stabbed their mothers, they would have done no less.",
    acl: "1.2.281",
  },
  {
    p: "BRUTUS",
    l: "And after that, he came, thus sad, away?",
    acl: "1.2.282",
  },
  {
    p: "CASCA",
    l: "Ay.",
    acl: "1.2.283",
  },
  { p: "CASSIUS", l: "Did Cicero say any thing?", acl: "1.2.284" },
  {
    p: "CASCA",
    l: "Ay, he spoke Greek.",
    acl: "1.2.285",
  },
  { p: "CASSIUS", l: "To what effect?", acl: "1.2.286" },
  {
    p: "CASCA",
    l: "Nay, an I tell you that, Ill ne'er look you i' the",
    acl: "1.2.287",
  },
  {
    p: "CASCA",
    l: "face again: but those that understood him smiled at",
    acl: "1.2.288",
  },
  {
    p: "CASCA",
    l: "one another and shook their heads, but, for mine own",
    acl: "1.2.289",
  },
  {
    p: "CASCA",
    l: "part, it was Greek to me. I could tell you more",
    acl: "1.2.290",
  },
  {
    p: "CASCA",
    l: "news too: Marullus and Flavius, for pulling scarfs",
    acl: "1.2.291",
  },
  {
    p: "CASCA",
    l: "off Caesar's images, are put to silence. Fare you",
    acl: "1.2.292",
  },
  {
    p: "CASCA",
    l: "well. There was more foolery yet, if I could",
    acl: "1.2.293",
  },
  { p: "CASCA", l: "remember it.", acl: "1.2.294" },
  {
    p: "CASSIUS",
    l: "Will you sup with me to-night, Casca?",
    acl: "1.2.295",
  },
  { p: "CASCA", l: "No, I am promised forth.", acl: "1.2.296" },
  {
    p: "CASSIUS",
    l: "Will you dine with me to-morrow?",
    acl: "1.2.297",
  },
  {
    p: "CASCA",
    l: "Ay, if I be alive and your mind hold and your dinner",
    acl: "1.2.298",
  },
  {
    p: "CASCA",
    l: "worth the eating.",
    acl: "1.2.299",
  },
  { p: "CASSIUS", l: "Good: I will expect you.", acl: "1.2.300" },
  {
    p: "CASCA",
    l: "Do so. Farewell, both.",
    acl: "1.2.301",
  },
  { p: "CASCA", l: "Exit", acl: "" },
  {
    p: "BRUTUS",
    l: "What a blunt fellow is this grown to be!",
    acl: "1.2.302",
  },
  {
    p: "BRUTUS",
    l: "He was quick mettle when he went to school.",
    acl: "1.2.303",
  },
  {
    p: "CASSIUS",
    l: "So is he now in execution",
    acl: "1.2.304",
  },
  { p: "CASSIUS", l: "Of any bold or noble enterprise,", acl: "1.2.305" },
  {
    p: "CASSIUS",
    l: "However he puts on this tardy form.",
    acl: "1.2.306",
  },
  {
    p: "CASSIUS",
    l: "This rudeness is a sauce to his good wit,",
    acl: "1.2.307",
  },
  {
    p: "CASSIUS",
    l: "Which gives men stomach to digest his words",
    acl: "1.2.308",
  },
  { p: "CASSIUS", l: "With better appetite.", acl: "1.2.309" },
  {
    p: "BRUTUS",
    l: "And so it is. For this time I will leave you:",
    acl: "1.2.310",
  },
  {
    p: "BRUTUS",
    l: "To-morrow, if you please to speak with me,",
    acl: "1.2.311",
  },
  {
    p: "BRUTUS",
    l: "I will come home to you, or, if you will,",
    acl: "1.2.312",
  },
  {
    p: "BRUTUS",
    l: "Come home to me, and I will wait for you.",
    acl: "1.2.313",
  },
  {
    p: "CASSIUS",
    l: "I will do so: till then, think of the world.",
    acl: "1.2.314",
  },
  { p: "CASSIUS", l: "Exit BRUTUS", acl: "" },
  {
    p: "CASSIUS",
    l: "Well, Brutus, thou art noble, yet, I see,",
    acl: "1.2.315",
  },
  { p: "CASSIUS", l: "Thy honourable metal may be wrought", acl: "1.2.316" },
  {
    p: "CASSIUS",
    l: "From that it is disposed: therefore it is meet",
    acl: "1.2.317",
  },
  {
    p: "CASSIUS",
    l: "That noble minds keep ever with their likes,",
    acl: "1.2.318",
  },
  {
    p: "CASSIUS",
    l: "For who so firm that cannot be seduced?",
    acl: "1.2.319",
  },
  {
    p: "CASSIUS",
    l: "Caesar doth bear me hard, but he loves Brutus:",
    acl: "1.2.320",
  },
  {
    p: "CASSIUS",
    l: "If I were Brutus now and he were Cassius,",
    acl: "1.2.321",
  },
  {
    p: "CASSIUS",
    l: "He should not humour me. I will this night,",
    acl: "1.2.322",
  },
  {
    p: "CASSIUS",
    l: "In several hands, in at his windows throw,",
    acl: "1.2.323",
  },
  { p: "CASSIUS", l: "As if they came from several citizens,", acl: "1.2.324" },
  {
    p: "CASSIUS",
    l: "Writings all tending to the great opinion",
    acl: "1.2.325",
  },
  {
    p: "CASSIUS",
    l: "That Rome holds of his name, wherein obscurely",
    acl: "1.2.326",
  },
  {
    p: "CASSIUS",
    l: "Caesar's ambition shall be glanced at:",
    acl: "1.2.327",
  },
  {
    p: "CASSIUS",
    l: "And after this let Caesar seat him sure,",
    acl: "1.2.328",
  },
  {
    p: "CASSIUS",
    l: "For we will shake him, or worse days endure.",
    acl: "1.2.329",
  },
  { p: "CASSIUS", l: "Exit", acl: "" },
  {
    p: "CASSIUS",
    l: "SCENE III. The same. A street.",
    acl: "",
  },
  {
    p: "CASSIUS",
    l: "Thunder and lightning. Enter from opposite sides, CASCA, with his sword drawn, and CICERO",
    acl: "",
  },
  {
    p: "CICERO",
    l: "Good even, Casca: brought you Caesar home?",
    acl: "1.3.1",
  },
  {
    p: "CICERO",
    l: "Why are you breathless? and why stare you so?",
    acl: "1.3.2",
  },
  {
    p: "CASCA",
    l: "Are not you moved, when all the sway of earth",
    acl: "1.3.3",
  },
  {
    p: "CASCA",
    l: "Shakes like a thing unfirm? O Cicero,",
    acl: "1.3.4",
  },
  {
    p: "CASCA",
    l: "I have seen tempests, when the scolding winds",
    acl: "1.3.5",
  },
  {
    p: "CASCA",
    l: "Have rived the knotty oaks, and I have seen",
    acl: "1.3.6",
  },
  {
    p: "CASCA",
    l: "The ambitious ocean swell and rage and foam,",
    acl: "1.3.7",
  },
  {
    p: "CASCA",
    l: "To be exalted with the threatening clouds:",
    acl: "1.3.8",
  },
  { p: "CASCA", l: "But never till to-night, never till now,", acl: "1.3.9" },
  {
    p: "CASCA",
    l: "Did I go through a tempest dropping fire.",
    acl: "1.3.10",
  },
  { p: "CASCA", l: "Either there is a civil strife in heaven,", acl: "1.3.11" },
  {
    p: "CASCA",
    l: "Or else the world, too saucy with the gods,",
    acl: "1.3.12",
  },
  { p: "CASCA", l: "Incenses them to send destruction.", acl: "1.3.13" },
  {
    p: "CICERO",
    l: "Why, saw you any thing more wonderful?",
    acl: "1.3.14",
  },
  {
    p: "CASCA",
    l: "A common slave--you know him well by sight--",
    acl: "1.3.15",
  },
  {
    p: "CASCA",
    l: "Held up his left hand, which did flame and burn",
    acl: "1.3.16",
  },
  {
    p: "CASCA",
    l: "Like twenty torches join'd, and yet his hand,",
    acl: "1.3.17",
  },
  {
    p: "CASCA",
    l: "Not sensible of fire, remain'd unscorch'd.",
    acl: "1.3.18",
  },
  {
    p: "CASCA",
    l: "Besides--I ha' not since put up my sword--",
    acl: "1.3.19",
  },
  {
    p: "CASCA",
    l: "Against the Capitol I met a lion,",
    acl: "1.3.20",
  },
  { p: "CASCA", l: "Who glared upon me, and went surly by,", acl: "1.3.21" },
  {
    p: "CASCA",
    l: "Without annoying me: and there were drawn",
    acl: "1.3.22",
  },
  { p: "CASCA", l: "Upon a heap a hundred ghastly women,", acl: "1.3.23" },
  {
    p: "CASCA",
    l: "Transformed with their fear, who swore they saw",
    acl: "1.3.24",
  },
  {
    p: "CASCA",
    l: "Men all in fire walk up and down the streets.",
    acl: "1.3.25",
  },
  {
    p: "CASCA",
    l: "And yesterday the bird of night did sit",
    acl: "1.3.26",
  },
  { p: "CASCA", l: "Even at noon-day upon the market-place,", acl: "1.3.27" },
  {
    p: "CASCA",
    l: "Hooting and shrieking. When these prodigies",
    acl: "1.3.28",
  },
  { p: "CASCA", l: "Do so conjointly meet, let not men say", acl: "1.3.29" },
  {
    p: "CASCA",
    l: "'These are their reasons, they are natural,'",
    acl: "1.3.30",
  },
  {
    p: "CASCA",
    l: "For, I believe, they are portentous things",
    acl: "1.3.31",
  },
  {
    p: "CASCA",
    l: "Unto the climate that they point upon.",
    acl: "1.3.32",
  },
  { p: "CICERO", l: "Indeed, it is a strange-disposed time:", acl: "1.3.33" },
  {
    p: "CICERO",
    l: "But men may construe things after their fashion,",
    acl: "1.3.34",
  },
  {
    p: "CICERO",
    l: "Clean from the purpose of the things themselves.",
    acl: "1.3.35",
  },
  {
    p: "CICERO",
    l: "Come Caesar to the Capitol to-morrow?",
    acl: "1.3.36",
  },
  { p: "CASCA", l: "He doth, for he did bid Antonius", acl: "1.3.37" },
  {
    p: "CASCA",
    l: "Send word to you he would be there to-morrow.",
    acl: "1.3.38",
  },
  {
    p: "CICERO",
    l: "Good night then, Casca: this disturbed sky",
    acl: "1.3.39",
  },
  {
    p: "CICERO",
    l: "Is not to walk in.",
    acl: "1.3.40",
  },
  { p: "CASCA", l: "Farewell, Cicero.", acl: "1.3.41" },
  {
    p: "CASCA",
    l: "Exit CICERO",
    acl: "",
  },
  { p: "CASCA", l: "Enter CASSIUS", acl: "" },
  {
    p: "CASSIUS",
    l: "Who's there?",
    acl: "1.3.42",
  },
  { p: "CASCA", l: "A Roman.", acl: "1.3.43" },
  {
    p: "CASSIUS",
    l: "Casca, by your voice.",
    acl: "1.3.44",
  },
  {
    p: "CASCA",
    l: "Your ear is good. Cassius, what night is this!",
    acl: "1.3.45",
  },
  {
    p: "CASSIUS",
    l: "A very pleasing night to honest men.",
    acl: "1.3.46",
  },
  { p: "CASCA", l: "Who ever knew the heavens menace so?", acl: "1.3.47" },
  {
    p: "CASSIUS",
    l: "Those that have known the earth so full of faults.",
    acl: "1.3.48",
  },
  {
    p: "CASSIUS",
    l: "For my part, I have walk'd about the streets,",
    acl: "1.3.49",
  },
  {
    p: "CASSIUS",
    l: "Submitting me unto the perilous night,",
    acl: "1.3.50",
  },
  { p: "CASSIUS", l: "And, thus unbraced, Casca, as you see,", acl: "1.3.51" },
  {
    p: "CASSIUS",
    l: "Have bared my bosom to the thunder-stone,",
    acl: "1.3.52",
  },
  {
    p: "CASSIUS",
    l: "And when the cross blue lightning seem'd to open",
    acl: "1.3.53",
  },
  {
    p: "CASSIUS",
    l: "The breast of heaven, I did present myself",
    acl: "1.3.54",
  },
  { p: "CASSIUS", l: "Even in the aim and very flash of it.", acl: "1.3.55" },
  {
    p: "CASCA",
    l: "But wherefore did you so much tempt the heavens?",
    acl: "1.3.56",
  },
  {
    p: "CASCA",
    l: "It is the part of men to fear and tremble,",
    acl: "1.3.57",
  },
  {
    p: "CASCA",
    l: "When the most mighty gods by tokens send",
    acl: "1.3.58",
  },
  { p: "CASCA", l: "Such dreadful heralds to astonish us.", acl: "1.3.59" },
  {
    p: "CASSIUS",
    l: "You are dull, Casca, and those sparks of life",
    acl: "1.3.60",
  },
  { p: "CASSIUS", l: "That should be in a Roman you do want,", acl: "1.3.61" },
  {
    p: "CASSIUS",
    l: "Or else you use not. You look pale and gaze",
    acl: "1.3.62",
  },
  {
    p: "CASSIUS",
    l: "And put on fear and cast yourself in wonder,",
    acl: "1.3.63",
  },
  {
    p: "CASSIUS",
    l: "To see the strange impatience of the heavens:",
    acl: "1.3.64",
  },
  {
    p: "CASSIUS",
    l: "But if you would consider the true cause",
    acl: "1.3.65",
  },
  {
    p: "CASSIUS",
    l: "Why all these fires, why all these gliding ghosts,",
    acl: "1.3.66",
  },
  {
    p: "CASSIUS",
    l: "Why birds and beasts from quality and kind,",
    acl: "1.3.67",
  },
  {
    p: "CASSIUS",
    l: "Why old men fool and children calculate,",
    acl: "1.3.68",
  },
  {
    p: "CASSIUS",
    l: "Why all these things change from their ordinance",
    acl: "1.3.69",
  },
  {
    p: "CASSIUS",
    l: "Their natures and preformed faculties",
    acl: "1.3.70",
  },
  {
    p: "CASSIUS",
    l: "To monstrous quality,--why, you shall find",
    acl: "1.3.71",
  },
  {
    p: "CASSIUS",
    l: "That heaven hath infused them with these spirits,",
    acl: "1.3.72",
  },
  {
    p: "CASSIUS",
    l: "To make them instruments of fear and warning",
    acl: "1.3.73",
  },
  {
    p: "CASSIUS",
    l: "Unto some monstrous state.",
    acl: "1.3.74",
  },
  { p: "CASSIUS", l: "Now could I, Casca, name to thee a man", acl: "1.3.75" },
  {
    p: "CASSIUS",
    l: "Most like this dreadful night,",
    acl: "1.3.76",
  },
  {
    p: "CASSIUS",
    l: "That thunders, lightens, opens graves, and roars",
    acl: "1.3.77",
  },
  {
    p: "CASSIUS",
    l: "As doth the lion in the Capitol,",
    acl: "1.3.78",
  },
  { p: "CASSIUS", l: "A man no mightier than thyself or me", acl: "1.3.79" },
  {
    p: "CASSIUS",
    l: "In personal action, yet prodigious grown",
    acl: "1.3.80",
  },
  {
    p: "CASSIUS",
    l: "And fearful, as these strange eruptions are.",
    acl: "1.3.81",
  },
  {
    p: "CASCA",
    l: "'Tis Caesar that you mean, is it not, Cassius?",
    acl: "1.3.82",
  },
  { p: "CASSIUS", l: "Let it be who it is: for Romans now", acl: "1.3.83" },
  {
    p: "CASSIUS",
    l: "Have thews and limbs like to their ancestors,",
    acl: "1.3.84",
  },
  {
    p: "CASSIUS",
    l: "But, woe the while! our fathers' minds are dead,",
    acl: "1.3.85",
  },
  {
    p: "CASSIUS",
    l: "And we are govern'd with our mothers' spirits,",
    acl: "1.3.86",
  },
  {
    p: "CASSIUS",
    l: "Our yoke and sufferance show us womanish.",
    acl: "1.3.87",
  },
  {
    p: "CASCA",
    l: "Indeed, they say the senators tomorrow",
    acl: "1.3.88",
  },
  { p: "CASCA", l: "Mean to establish Caesar as a king,", acl: "1.3.89" },
  {
    p: "CASCA",
    l: "And he shall wear his crown by sea and land,",
    acl: "1.3.90",
  },
  { p: "CASCA", l: "In every place, save here in Italy.", acl: "1.3.91" },
  {
    p: "CASSIUS",
    l: "I know where I will wear this dagger then,",
    acl: "1.3.92",
  },
  {
    p: "CASSIUS",
    l: "Cassius from bondage will deliver Cassius:",
    acl: "1.3.93",
  },
  {
    p: "CASSIUS",
    l: "Therein, ye gods, you make the weak most strong,",
    acl: "1.3.94",
  },
  {
    p: "CASSIUS",
    l: "Therein, ye gods, you tyrants do defeat:",
    acl: "1.3.95",
  },
  {
    p: "CASSIUS",
    l: "Nor stony tower, nor walls of beaten brass,",
    acl: "1.3.96",
  },
  {
    p: "CASSIUS",
    l: "Nor airless dungeon, nor strong links of iron,",
    acl: "1.3.97",
  },
  {
    p: "CASSIUS",
    l: "Can be retentive to the strength of spirit,",
    acl: "1.3.98",
  },
  {
    p: "CASSIUS",
    l: "But life, being weary of these worldly bars,",
    acl: "1.3.99",
  },
  {
    p: "CASSIUS",
    l: "Never lacks power to dismiss itself.",
    acl: "1.3.100",
  },
  {
    p: "CASSIUS",
    l: "If I know this, know all the world besides,",
    acl: "1.3.101",
  },
  {
    p: "CASSIUS",
    l: "That part of tyranny that I do bear",
    acl: "1.3.102",
  },
  { p: "CASSIUS", l: "I can shake off at pleasure.", acl: "1.3.103" },
  {
    p: "CASSIUS",
    l: "Thunder still",
    acl: "",
  },
  { p: "CASCA", l: "So can I:", acl: "1.3.104" },
  {
    p: "CASCA",
    l: "So every bondman in his own hand bears",
    acl: "1.3.105",
  },
  { p: "CASCA", l: "The power to cancel his captivity.", acl: "1.3.106" },
  {
    p: "CASSIUS",
    l: "And why should Caesar be a tyrant then?",
    acl: "1.3.107",
  },
  {
    p: "CASSIUS",
    l: "Poor man! I know he would not be a wolf,",
    acl: "1.3.108",
  },
  {
    p: "CASSIUS",
    l: "But that he sees the Romans are but sheep:",
    acl: "1.3.109",
  },
  {
    p: "CASSIUS",
    l: "He were no lion, were not Romans hinds.",
    acl: "1.3.110",
  },
  {
    p: "CASSIUS",
    l: "Those that with haste will make a mighty fire",
    acl: "1.3.111",
  },
  {
    p: "CASSIUS",
    l: "Begin it with weak straws: what trash is Rome,",
    acl: "1.3.112",
  },
  {
    p: "CASSIUS",
    l: "What rubbish and what offal, when it serves",
    acl: "1.3.113",
  },
  { p: "CASSIUS", l: "For the base matter to illuminate", acl: "1.3.114" },
  {
    p: "CASSIUS",
    l: "So vile a thing as Caesar! But, O grief,",
    acl: "1.3.115",
  },
  {
    p: "CASSIUS",
    l: "Where hast thou led me? I perhaps speak this",
    acl: "1.3.116",
  },
  {
    p: "CASSIUS",
    l: "Before a willing bondman, then I know",
    acl: "1.3.117",
  },
  {
    p: "CASSIUS",
    l: "My answer must be made. But I am arm'd,",
    acl: "1.3.118",
  },
  {
    p: "CASSIUS",
    l: "And dangers are to me indifferent.",
    acl: "1.3.119",
  },
  { p: "CASCA", l: "You speak to Casca, and to such a man", acl: "1.3.120" },
  {
    p: "CASCA",
    l: "That is no fleering tell-tale. Hold, my hand:",
    acl: "1.3.121",
  },
  {
    p: "CASCA",
    l: "Be factious for redress of all these griefs,",
    acl: "1.3.122",
  },
  {
    p: "CASCA",
    l: "And I will set this foot of mine as far",
    acl: "1.3.123",
  },
  { p: "CASCA", l: "As who goes farthest.", acl: "1.3.124" },
  {
    p: "CASSIUS",
    l: "There's a bargain made.",
    acl: "1.3.125",
  },
  {
    p: "CASSIUS",
    l: "Now know you, Casca, I have moved already",
    acl: "1.3.126",
  },
  {
    p: "CASSIUS",
    l: "Some certain of the noblest-minded Romans",
    acl: "1.3.127",
  },
  { p: "CASSIUS", l: "To undergo with me an enterprise", acl: "1.3.128" },
  {
    p: "CASSIUS",
    l: "Of honourable-dangerous consequence,",
    acl: "1.3.129",
  },
  {
    p: "CASSIUS",
    l: "And I do know, by this, they stay for me",
    acl: "1.3.130",
  },
  {
    p: "CASSIUS",
    l: "In Pompey's porch: for now, this fearful night,",
    acl: "1.3.131",
  },
  {
    p: "CASSIUS",
    l: "There is no stir or walking in the streets,",
    acl: "1.3.132",
  },
  {
    p: "CASSIUS",
    l: "And the complexion of the element",
    acl: "1.3.133",
  },
  {
    p: "CASSIUS",
    l: "In favour's like the work we have in hand,",
    acl: "1.3.134",
  },
  {
    p: "CASSIUS",
    l: "Most bloody, fiery, and most terrible.",
    acl: "1.3.135",
  },
  {
    p: "CASCA",
    l: "Stand close awhile, for here comes one in haste.",
    acl: "1.3.136",
  },
  {
    p: "CASSIUS",
    l: "'Tis Cinna, I do know him by his gait,",
    acl: "1.3.137",
  },
  { p: "CASSIUS", l: "He is a friend.", acl: "1.3.138" },
  {
    p: "CASSIUS",
    l: "Enter CINNA",
    acl: "",
  },
  { p: "CASSIUS", l: "Cinna, where haste you so?", acl: "1.3.139" },
  {
    p: "CINNA",
    l: "To find out you. Who's that? Metellus Cimber?",
    acl: "1.3.140",
  },
  { p: "CASSIUS", l: "No, it is Casca, one incorporate", acl: "1.3.141" },
  {
    p: "CASSIUS",
    l: "To our attempts. Am I not stay'd for, Cinna?",
    acl: "1.3.142",
  },
  {
    p: "CINNA",
    l: "I am glad on 't. What a fearful night is this!",
    acl: "1.3.143",
  },
  {
    p: "CINNA",
    l: "There's two or three of us have seen strange sights.",
    acl: "1.3.144",
  },
  { p: "CASSIUS", l: "Am I not stay'd for? tell me.", acl: "1.3.145" },
  {
    p: "CINNA",
    l: "Yes, you are.",
    acl: "1.3.146",
  },
  { p: "CINNA", l: "O Cassius, if you could", acl: "1.3.147" },
  {
    p: "CINNA",
    l: "But win the noble Brutus to our party--",
    acl: "1.3.148",
  },
  {
    p: "CASSIUS",
    l: "Be you content: good Cinna, take this paper,",
    acl: "1.3.149",
  },
  {
    p: "CASSIUS",
    l: "And look you lay it in the praetor's chair,",
    acl: "1.3.150",
  },
  {
    p: "CASSIUS",
    l: "Where Brutus may but find it, and throw this",
    acl: "1.3.151",
  },
  {
    p: "CASSIUS",
    l: "In at his window, set this up with wax",
    acl: "1.3.152",
  },
  {
    p: "CASSIUS",
    l: "Upon old Brutus' statue: all this done,",
    acl: "1.3.153",
  },
  {
    p: "CASSIUS",
    l: "Repair to Pompey's porch, where you shall find us.",
    acl: "1.3.154",
  },
  { p: "CASSIUS", l: "Is Decius Brutus and Trebonius there?", acl: "1.3.155" },
  {
    p: "CINNA",
    l: "All but Metellus Cimber, and he's gone",
    acl: "1.3.156",
  },
  {
    p: "CINNA",
    l: "To seek you at your house. Well, I will hie,",
    acl: "1.3.157",
  },
  {
    p: "CINNA",
    l: "And so bestow these papers as you bade me.",
    acl: "1.3.158",
  },
  { p: "CASSIUS", l: "That done, repair to Pompey's theatre.", acl: "1.3.159" },
  {
    p: "CASSIUS",
    l: "Exit CINNA",
    acl: "",
  },
  {
    p: "CASSIUS",
    l: "Come, Casca, you and I will yet ere day",
    acl: "1.3.160",
  },
  {
    p: "CASSIUS",
    l: "See Brutus at his house: three parts of him",
    acl: "1.3.161",
  },
  { p: "CASSIUS", l: "Is ours already, and the man entire", acl: "1.3.162" },
  {
    p: "CASSIUS",
    l: "Upon the next encounter yields him ours.",
    acl: "1.3.163",
  },
  {
    p: "CASCA",
    l: "O, he sits high in all the people's hearts:",
    acl: "1.3.164",
  },
  {
    p: "CASCA",
    l: "And that which would appear offence in us,",
    acl: "1.3.165",
  },
  { p: "CASCA", l: "His countenance, like richest alchemy,", acl: "1.3.166" },
  {
    p: "CASCA",
    l: "Will change to virtue and to worthiness.",
    acl: "1.3.167",
  },
  {
    p: "CASSIUS",
    l: "Him and his worth and our great need of him",
    acl: "1.3.168",
  },
  {
    p: "CASSIUS",
    l: "You have right well conceited. Let us go,",
    acl: "1.3.169",
  },
  { p: "CASSIUS", l: "For it is after midnight, and ere day", acl: "1.3.170" },
  {
    p: "CASSIUS",
    l: "We will awake him and be sure of him.",
    acl: "1.3.171",
  },
  { p: "CASSIUS", l: "Exeunt", acl: "" },
  { p: "CASSIUS", l: "ACT II", acl: "" },
  {
    p: "CASSIUS",
    l: "SCENE I. Rome. BRUTUS's orchard.",
    acl: "",
  },
  { p: "CASSIUS", l: "Enter BRUTUS", acl: "" },
  {
    p: "BRUTUS",
    l: "What, Lucius, ho!",
    acl: "2.1.1",
  },
  { p: "BRUTUS", l: "I cannot, by the progress of the stars,", acl: "2.1.2" },
  {
    p: "BRUTUS",
    l: "Give guess how near to day. Lucius, I say!",
    acl: "2.1.3",
  },
  {
    p: "BRUTUS",
    l: "I would it were my fault to sleep so soundly.",
    acl: "2.1.4",
  },
  {
    p: "BRUTUS",
    l: "When, Lucius, when? awake, I say! what, Lucius!",
    acl: "2.1.5",
  },
  { p: "BRUTUS", l: "Enter LUCIUS", acl: "" },
  {
    p: "LUCIUS",
    l: "Call'd you, my lord?",
    acl: "2.1.6",
  },
  { p: "BRUTUS", l: "Get me a taper in my study, Lucius:", acl: "2.1.7" },
  {
    p: "BRUTUS",
    l: "When it is lighted, come and call me here.",
    acl: "2.1.8",
  },
  { p: "LUCIUS", l: "I will, my lord.", acl: "2.1.9" },
  { p: "LUCIUS", l: "Exit", acl: "" },
  {
    p: "BRUTUS",
    l: "It must be by his death: and for my part,",
    acl: "2.1.10",
  },
  {
    p: "BRUTUS",
    l: "I know no personal cause to spurn at him,",
    acl: "2.1.11",
  },
  {
    p: "BRUTUS",
    l: "But for the general. He would be crown'd:",
    acl: "2.1.12",
  },
  {
    p: "BRUTUS",
    l: "How that might change his nature, there's the question.",
    acl: "2.1.13",
  },
  {
    p: "BRUTUS",
    l: "It is the bright day that brings forth the adder,",
    acl: "2.1.14",
  },
  {
    p: "BRUTUS",
    l: "And that craves wary walking. Crown him?--that,--",
    acl: "2.1.15",
  },
  {
    p: "BRUTUS",
    l: "And then, I grant, we put a sting in him,",
    acl: "2.1.16",
  },
  { p: "BRUTUS", l: "That at his will he may do danger with.", acl: "2.1.17" },
  {
    p: "BRUTUS",
    l: "The abuse of greatness is, when it disjoins",
    acl: "2.1.18",
  },
  {
    p: "BRUTUS",
    l: "Remorse from power: and, to speak truth of Caesar,",
    acl: "2.1.19",
  },
  {
    p: "BRUTUS",
    l: "I have not known when his affections sway'd",
    acl: "2.1.20",
  },
  {
    p: "BRUTUS",
    l: "More than his reason. But 'tis a common proof,",
    acl: "2.1.21",
  },
  {
    p: "BRUTUS",
    l: "That lowliness is young ambition's ladder,",
    acl: "2.1.22",
  },
  {
    p: "BRUTUS",
    l: "Whereto the climber-upward turns his face,",
    acl: "2.1.23",
  },
  {
    p: "BRUTUS",
    l: "But when he once attains the upmost round.",
    acl: "2.1.24",
  },
  { p: "BRUTUS", l: "He then unto the ladder turns his back,", acl: "2.1.25" },
  {
    p: "BRUTUS",
    l: "Looks in the clouds, scorning the base degrees",
    acl: "2.1.26",
  },
  { p: "BRUTUS", l: "By which he did ascend. So Caesar may.", acl: "2.1.27" },
  {
    p: "BRUTUS",
    l: "Then, lest he may, prevent. And, since the quarrel",
    acl: "2.1.28",
  },
  { p: "BRUTUS", l: "Will bear no colour for the thing he is,", acl: "2.1.29" },
  {
    p: "BRUTUS",
    l: "Fashion it thus, that what he is, augmented,",
    acl: "2.1.30",
  },
  {
    p: "BRUTUS",
    l: "Would run to these and these extremities:",
    acl: "2.1.31",
  },
  {
    p: "BRUTUS",
    l: "And therefore think him as a serpent's egg",
    acl: "2.1.32",
  },
  {
    p: "BRUTUS",
    l: "Which, hatch'd, would, as his kind, grow mischievous,",
    acl: "2.1.33",
  },
  {
    p: "BRUTUS",
    l: "And kill him in the shell.",
    acl: "2.1.34",
  },
  { p: "BRUTUS", l: "Re-enter LUCIUS", acl: "" },
  {
    p: "LUCIUS",
    l: "The taper burneth in your closet, sir.",
    acl: "2.1.35",
  },
  {
    p: "LUCIUS",
    l: "Searching the window for a flint, I found",
    acl: "2.1.36",
  },
  {
    p: "LUCIUS",
    l: "This paper, thus seal'd up, and, I am sure,",
    acl: "2.1.37",
  },
  { p: "LUCIUS", l: "It did not lie there when I went to bed.", acl: "2.1.38" },
  {
    p: "LUCIUS",
    l: "Gives him the letter",
    acl: "",
  },
  { p: "BRUTUS", l: "Get you to bed again, it is not day.", acl: "2.1.39" },
  {
    p: "BRUTUS",
    l: "Is not to-morrow, boy, the ides of March?",
    acl: "2.1.40",
  },
  { p: "LUCIUS", l: "I know not, sir.", acl: "2.1.41" },
  {
    p: "BRUTUS",
    l: "Look in the calendar, and bring me word.",
    acl: "2.1.42",
  },
  { p: "LUCIUS", l: "I will, sir.", acl: "2.1.43" },
  { p: "LUCIUS", l: "Exit", acl: "" },
  {
    p: "BRUTUS",
    l: "The exhalations whizzing in the air",
    acl: "2.1.44",
  },
  {
    p: "BRUTUS",
    l: "Give so much light that I may read by them.",
    acl: "2.1.45",
  },
  {
    p: "BRUTUS",
    l: "Opens the letter and reads",
    acl: "",
  },
  {
    p: "BRUTUS",
    l: "'Brutus, thou sleep'st: awake, and see thyself.",
    acl: "2.1.46",
  },
  {
    p: "BRUTUS",
    l: "Shall Rome,  and c. Speak, strike, redress!",
    acl: "2.1.47",
  },
  { p: "BRUTUS", l: "Brutus, thou sleep'st: awake!'", acl: "2.1.48" },
  {
    p: "BRUTUS",
    l: "Such instigations have been often dropp'd",
    acl: "2.1.49",
  },
  { p: "BRUTUS", l: "Where I have took them up.", acl: "2.1.50" },
  {
    p: "BRUTUS",
    l: "'Shall Rome,  and c.' Thus must I piece it out:",
    acl: "2.1.51",
  },
  {
    p: "BRUTUS",
    l: "Shall Rome stand under one man's awe? What, Rome?",
    acl: "2.1.52",
  },
  {
    p: "BRUTUS",
    l: "My ancestors did from the streets of Rome",
    acl: "2.1.53",
  },
  {
    p: "BRUTUS",
    l: "The Tarquin drive, when he was call'd a king.",
    acl: "2.1.54",
  },
  {
    p: "BRUTUS",
    l: "'Speak, strike, redress!' Am I entreated",
    acl: "2.1.55",
  },
  {
    p: "BRUTUS",
    l: "To speak and strike? O Rome, I make thee promise:",
    acl: "2.1.56",
  },
  {
    p: "BRUTUS",
    l: "If the redress will follow, thou receivest",
    acl: "2.1.57",
  },
  { p: "BRUTUS", l: "Thy full petition at the hand of Brutus!", acl: "2.1.58" },
  {
    p: "BRUTUS",
    l: "Re-enter LUCIUS",
    acl: "",
  },
  { p: "LUCIUS", l: "Sir, March is wasted fourteen days.", acl: "2.1.59" },
  {
    p: "LUCIUS",
    l: "Knocking within",
    acl: "",
  },
  {
    p: "BRUTUS",
    l: "'Tis good. Go to the gate, somebody knocks.",
    acl: "2.1.60",
  },
  {
    p: "BRUTUS",
    l: "Exit LUCIUS",
    acl: "",
  },
  {
    p: "BRUTUS",
    l: "Since Cassius first did whet me against Caesar,",
    acl: "2.1.61",
  },
  {
    p: "BRUTUS",
    l: "I have not slept.",
    acl: "2.1.62",
  },
  { p: "BRUTUS", l: "Between the acting of a dreadful thing", acl: "2.1.63" },
  {
    p: "BRUTUS",
    l: "And the first motion, all the interim is",
    acl: "2.1.64",
  },
  { p: "BRUTUS", l: "Like a phantasma, or a hideous dream:", acl: "2.1.65" },
  {
    p: "BRUTUS",
    l: "The Genius and the mortal instruments",
    acl: "2.1.66",
  },
  {
    p: "BRUTUS",
    l: "Are then in council, and the state of man,",
    acl: "2.1.67",
  },
  {
    p: "BRUTUS",
    l: "Like to a little kingdom, suffers then",
    acl: "2.1.68",
  },
  { p: "BRUTUS", l: "The nature of an insurrection.", acl: "2.1.69" },
  {
    p: "BRUTUS",
    l: "Re-enter LUCIUS",
    acl: "",
  },
  {
    p: "LUCIUS",
    l: "Sir, 'tis your brother Cassius at the door,",
    acl: "2.1.70",
  },
  {
    p: "LUCIUS",
    l: "Who doth desire to see you.",
    acl: "2.1.71",
  },
  { p: "BRUTUS", l: "Is he alone?", acl: "2.1.72" },
  {
    p: "LUCIUS",
    l: "No, sir, there are moe with him.",
    acl: "2.1.73",
  },
  { p: "BRUTUS", l: "Do you know them?", acl: "2.1.74" },
  {
    p: "LUCIUS",
    l: "No, sir, their hats are pluck'd about their ears,",
    acl: "2.1.75",
  },
  {
    p: "LUCIUS",
    l: "And half their faces buried in their cloaks,",
    acl: "2.1.76",
  },
  {
    p: "LUCIUS",
    l: "That by no means I may discover them",
    acl: "2.1.77",
  },
  { p: "LUCIUS", l: "By any mark of favour.", acl: "2.1.78" },
  {
    p: "BRUTUS",
    l: "Let 'em enter.",
    acl: "2.1.79",
  },
  { p: "BRUTUS", l: "Exit LUCIUS", acl: "" },
  {
    p: "BRUTUS",
    l: "They are the faction. O conspiracy,",
    acl: "2.1.80",
  },
  {
    p: "BRUTUS",
    l: "Shamest thou to show thy dangerous brow by night,",
    acl: "2.1.81",
  },
  {
    p: "BRUTUS",
    l: "When evils are most free? O, then by day",
    acl: "2.1.82",
  },
  {
    p: "BRUTUS",
    l: "Where wilt thou find a cavern dark enough",
    acl: "2.1.83",
  },
  {
    p: "BRUTUS",
    l: "To mask thy monstrous visage? Seek none, conspiracy,",
    acl: "2.1.84",
  },
  { p: "BRUTUS", l: "Hide it in smiles and affability:", acl: "2.1.85" },
  {
    p: "BRUTUS",
    l: "For if thou path, thy native semblance on,",
    acl: "2.1.86",
  },
  { p: "BRUTUS", l: "Not Erebus itself were dim enough", acl: "2.1.87" },
  {
    p: "BRUTUS",
    l: "To hide thee from prevention.",
    acl: "2.1.88",
  },
  {
    p: "BRUTUS",
    l: "Enter the conspirators, CASSIUS, CASCA, DECIUS BRUTUS, CINNA, METELLUS CIMBER, and TREBONIUS",
    acl: "",
  },
  { p: "CASSIUS", l: "I think we are too bold upon your rest:", acl: "2.1.89" },
  {
    p: "CASSIUS",
    l: "Good morrow, Brutus, do we trouble you?",
    acl: "2.1.90",
  },
  {
    p: "BRUTUS",
    l: "I have been up this hour, awake all night.",
    acl: "2.1.91",
  },
  {
    p: "BRUTUS",
    l: "Know I these men that come along with you?",
    acl: "2.1.92",
  },
  { p: "CASSIUS", l: "Yes, every man of them, and no man here", acl: "2.1.93" },
  {
    p: "CASSIUS",
    l: "But honours you, and every one doth wish",
    acl: "2.1.94",
  },
  { p: "CASSIUS", l: "You had but that opinion of yourself", acl: "2.1.95" },
  {
    p: "CASSIUS",
    l: "Which every noble Roman bears of you.",
    acl: "2.1.96",
  },
  { p: "CASSIUS", l: "This is Trebonius.", acl: "2.1.97" },
  {
    p: "BRUTUS",
    l: "He is welcome hither.",
    acl: "2.1.98",
  },
  { p: "CASSIUS", l: "This, Decius Brutus.", acl: "2.1.99" },
  {
    p: "BRUTUS",
    l: "He is welcome too.",
    acl: "2.1.100",
  },
  {
    p: "CASSIUS",
    l: "This, Casca, this, Cinna, and this, Metellus Cimber.",
    acl: "2.1.101",
  },
  {
    p: "BRUTUS",
    l: "They are all welcome.",
    acl: "2.1.102",
  },
  {
    p: "BRUTUS",
    l: "What watchful cares do interpose themselves",
    acl: "2.1.103",
  },
  {
    p: "BRUTUS",
    l: "Betwixt your eyes and night?",
    acl: "2.1.104",
  },
  { p: "CASSIUS", l: "Shall I entreat a word?", acl: "2.1.105" },
  {
    p: "CASSIUS",
    l: "BRUTUS and CASSIUS whisper",
    acl: "",
  },
  {
    p: "DECIUS BRUTUS",
    l: "Here lies the east: doth not the day break here?",
    acl: "2.1.106",
  },
  {
    p: "CASCA",
    l: "No.",
    acl: "2.1.107",
  },
  {
    p: "CINNA",
    l: "O, pardon, sir, it doth, and yon gray lines",
    acl: "2.1.108",
  },
  {
    p: "CINNA",
    l: "That fret the clouds are messengers of day.",
    acl: "2.1.109",
  },
  {
    p: "CASCA",
    l: "You shall confess that you are both deceived.",
    acl: "2.1.110",
  },
  {
    p: "CASCA",
    l: "Here, as I point my sword, the sun arises,",
    acl: "2.1.111",
  },
  {
    p: "CASCA",
    l: "Which is a great way growing on the south,",
    acl: "2.1.112",
  },
  {
    p: "CASCA",
    l: "Weighing the youthful season of the year.",
    acl: "2.1.113",
  },
  {
    p: "CASCA",
    l: "Some two months hence up higher toward the north",
    acl: "2.1.114",
  },
  {
    p: "CASCA",
    l: "He first presents his fire, and the high east",
    acl: "2.1.115",
  },
  { p: "CASCA", l: "Stands, as the Capitol, directly here.", acl: "2.1.116" },
  {
    p: "BRUTUS",
    l: "Give me your hands all over, one by one.",
    acl: "2.1.117",
  },
  { p: "CASSIUS", l: "And let us swear our resolution.", acl: "2.1.118" },
  {
    p: "BRUTUS",
    l: "No, not an oath: if not the face of men,",
    acl: "2.1.119",
  },
  {
    p: "BRUTUS",
    l: "The sufferance of our souls, the time's abuse,--",
    acl: "2.1.120",
  },
  {
    p: "BRUTUS",
    l: "If these be motives weak, break off betimes,",
    acl: "2.1.121",
  },
  { p: "BRUTUS", l: "And every man hence to his idle bed,", acl: "2.1.122" },
  {
    p: "BRUTUS",
    l: "So let high-sighted tyranny range on,",
    acl: "2.1.123",
  },
  {
    p: "BRUTUS",
    l: "Till each man drop by lottery. But if these,",
    acl: "2.1.124",
  },
  {
    p: "BRUTUS",
    l: "As I am sure they do, bear fire enough",
    acl: "2.1.125",
  },
  {
    p: "BRUTUS",
    l: "To kindle cowards and to steel with valour",
    acl: "2.1.126",
  },
  {
    p: "BRUTUS",
    l: "The melting spirits of women, then, countrymen,",
    acl: "2.1.127",
  },
  {
    p: "BRUTUS",
    l: "What need we any spur but our own cause,",
    acl: "2.1.128",
  },
  {
    p: "BRUTUS",
    l: "To prick us to redress? what other bond",
    acl: "2.1.129",
  },
  {
    p: "BRUTUS",
    l: "Than secret Romans, that have spoke the word,",
    acl: "2.1.130",
  },
  {
    p: "BRUTUS",
    l: "And will not palter? and what other oath",
    acl: "2.1.131",
  },
  { p: "BRUTUS", l: "Than honesty to honesty engaged,", acl: "2.1.132" },
  {
    p: "BRUTUS",
    l: "That this shall be, or we will fall for it?",
    acl: "2.1.133",
  },
  {
    p: "BRUTUS",
    l: "Swear priests and cowards and men cautelous,",
    acl: "2.1.134",
  },
  {
    p: "BRUTUS",
    l: "Old feeble carrions and such suffering souls",
    acl: "2.1.135",
  },
  {
    p: "BRUTUS",
    l: "That welcome wrongs, unto bad causes swear",
    acl: "2.1.136",
  },
  {
    p: "BRUTUS",
    l: "Such creatures as men doubt, but do not stain",
    acl: "2.1.137",
  },
  { p: "BRUTUS", l: "The even virtue of our enterprise,", acl: "2.1.138" },
  {
    p: "BRUTUS",
    l: "Nor the insuppressive mettle of our spirits,",
    acl: "2.1.139",
  },
  {
    p: "BRUTUS",
    l: "To think that or our cause or our performance",
    acl: "2.1.140",
  },
  {
    p: "BRUTUS",
    l: "Did need an oath, when every drop of blood",
    acl: "2.1.141",
  },
  {
    p: "BRUTUS",
    l: "That every Roman bears, and nobly bears,",
    acl: "2.1.142",
  },
  {
    p: "BRUTUS",
    l: "Is guilty of a several bastardy,",
    acl: "2.1.143",
  },
  { p: "BRUTUS", l: "If he do break the smallest particle", acl: "2.1.144" },
  {
    p: "BRUTUS",
    l: "Of any promise that hath pass'd from him.",
    acl: "2.1.145",
  },
  {
    p: "CASSIUS",
    l: "But what of Cicero? shall we sound him?",
    acl: "2.1.146",
  },
  {
    p: "CASSIUS",
    l: "I think he will stand very strong with us.",
    acl: "2.1.147",
  },
  { p: "CASCA", l: "Let us not leave him out.", acl: "2.1.148" },
  {
    p: "CINNA",
    l: "No, by no means.",
    acl: "2.1.149",
  },
  {
    p: "METELLUS CIMBER",
    l: "O, let us have him, for his silver hairs",
    acl: "2.1.150",
  },
  {
    p: "METELLUS CIMBER",
    l: "Will purchase us a good opinion",
    acl: "2.1.151",
  },
  {
    p: "METELLUS CIMBER",
    l: "And buy men's voices to commend our deeds:",
    acl: "2.1.152",
  },
  {
    p: "METELLUS CIMBER",
    l: "It shall be said, his judgment ruled our hands,",
    acl: "2.1.153",
  },
  {
    p: "METELLUS CIMBER",
    l: "Our youths and wildness shall no whit appear,",
    acl: "2.1.154",
  },
  {
    p: "METELLUS CIMBER",
    l: "But all be buried in his gravity.",
    acl: "2.1.155",
  },
  {
    p: "BRUTUS",
    l: "O, name him not: let us not break with him,",
    acl: "2.1.156",
  },
  { p: "BRUTUS", l: "For he will never follow any thing", acl: "2.1.157" },
  {
    p: "BRUTUS",
    l: "That other men begin.",
    acl: "2.1.158",
  },
  { p: "CASSIUS", l: "Then leave him out.", acl: "2.1.159" },
  {
    p: "CASCA",
    l: "Indeed he is not fit.",
    acl: "2.1.160",
  },
  {
    p: "DECIUS BRUTUS",
    l: "Shall no man else be touch'd but only Caesar?",
    acl: "2.1.161",
  },
  {
    p: "CASSIUS",
    l: "Decius, well urged: I think it is not meet,",
    acl: "2.1.162",
  },
  {
    p: "CASSIUS",
    l: "Mark Antony, so well beloved of Caesar,",
    acl: "2.1.163",
  },
  {
    p: "CASSIUS",
    l: "Should outlive Caesar: we shall find of him",
    acl: "2.1.164",
  },
  {
    p: "CASSIUS",
    l: "A shrewd contriver, and, you know, his means,",
    acl: "2.1.165",
  },
  {
    p: "CASSIUS",
    l: "If he improve them, may well stretch so far",
    acl: "2.1.166",
  },
  { p: "CASSIUS", l: "As to annoy us all: which to prevent,", acl: "2.1.167" },
  {
    p: "CASSIUS",
    l: "Let Antony and Caesar fall together.",
    acl: "2.1.168",
  },
  {
    p: "BRUTUS",
    l: "Our course will seem too bloody, Caius Cassius,",
    acl: "2.1.169",
  },
  {
    p: "BRUTUS",
    l: "To cut the head off and then hack the limbs,",
    acl: "2.1.170",
  },
  {
    p: "BRUTUS",
    l: "Like wrath in death and envy afterwards,",
    acl: "2.1.171",
  },
  {
    p: "BRUTUS",
    l: "For Antony is but a limb of Caesar:",
    acl: "2.1.172",
  },
  {
    p: "BRUTUS",
    l: "Let us be sacrificers, but not butchers, Caius.",
    acl: "2.1.173",
  },
  {
    p: "BRUTUS",
    l: "We all stand up against the spirit of Caesar,",
    acl: "2.1.174",
  },
  {
    p: "BRUTUS",
    l: "And in the spirit of men there is no blood:",
    acl: "2.1.175",
  },
  {
    p: "BRUTUS",
    l: "O, that we then could come by Caesar's spirit,",
    acl: "2.1.176",
  },
  { p: "BRUTUS", l: "And not dismember Caesar! But, alas,", acl: "2.1.177" },
  {
    p: "BRUTUS",
    l: "Caesar must bleed for it! And, gentle friends,",
    acl: "2.1.178",
  },
  {
    p: "BRUTUS",
    l: "Let's kill him boldly, but not wrathfully,",
    acl: "2.1.179",
  },
  {
    p: "BRUTUS",
    l: "Let's carve him as a dish fit for the gods,",
    acl: "2.1.180",
  },
  {
    p: "BRUTUS",
    l: "Not hew him as a carcass fit for hounds:",
    acl: "2.1.181",
  },
  {
    p: "BRUTUS",
    l: "And let our hearts, as subtle masters do,",
    acl: "2.1.182",
  },
  {
    p: "BRUTUS",
    l: "Stir up their servants to an act of rage,",
    acl: "2.1.183",
  },
  {
    p: "BRUTUS",
    l: "And after seem to chide 'em. This shall make",
    acl: "2.1.184",
  },
  { p: "BRUTUS", l: "Our purpose necessary and not envious:", acl: "2.1.185" },
  {
    p: "BRUTUS",
    l: "Which so appearing to the common eyes,",
    acl: "2.1.186",
  },
  {
    p: "BRUTUS",
    l: "We shall be call'd purgers, not murderers.",
    acl: "2.1.187",
  },
  {
    p: "BRUTUS",
    l: "And for Mark Antony, think not of him,",
    acl: "2.1.188",
  },
  { p: "BRUTUS", l: "For he can do no more than Caesar's arm", acl: "2.1.189" },
  {
    p: "BRUTUS",
    l: "When Caesar's head is off.",
    acl: "2.1.190",
  },
  { p: "CASSIUS", l: "Yet I fear him,", acl: "2.1.191" },
  {
    p: "CASSIUS",
    l: "For in the ingrafted love he bears to Caesar--",
    acl: "2.1.192",
  },
  {
    p: "BRUTUS",
    l: "Alas, good Cassius, do not think of him:",
    acl: "2.1.193",
  },
  {
    p: "BRUTUS",
    l: "If he love Caesar, all that he can do",
    acl: "2.1.194",
  },
  {
    p: "BRUTUS",
    l: "Is to himself, take thought and die for Caesar:",
    acl: "2.1.195",
  },
  {
    p: "BRUTUS",
    l: "And that were much he should, for he is given",
    acl: "2.1.196",
  },
  {
    p: "BRUTUS",
    l: "To sports, to wildness and much company.",
    acl: "2.1.197",
  },
  {
    p: "TREBONIUS",
    l: "There is no fear in him, let him not die,",
    acl: "2.1.198",
  },
  {
    p: "TREBONIUS",
    l: "For he will live, and laugh at this hereafter.",
    acl: "2.1.199",
  },
  {
    p: "TREBONIUS",
    l: "Clock strikes",
    acl: "",
  },
  { p: "BRUTUS", l: "Peace! count the clock.", acl: "2.1.200" },
  {
    p: "CASSIUS",
    l: "The clock hath stricken three.",
    acl: "2.1.201",
  },
  { p: "TREBONIUS", l: "'Tis time to part.", acl: "2.1.202" },
  {
    p: "CASSIUS",
    l: "But it is doubtful yet,",
    acl: "2.1.203",
  },
  {
    p: "CASSIUS",
    l: "Whether Caesar will come forth to-day, or no,",
    acl: "2.1.204",
  },
  {
    p: "CASSIUS",
    l: "For he is superstitious grown of late,",
    acl: "2.1.205",
  },
  {
    p: "CASSIUS",
    l: "Quite from the main opinion he held once",
    acl: "2.1.206",
  },
  {
    p: "CASSIUS",
    l: "Of fantasy, of dreams and ceremonies:",
    acl: "2.1.207",
  },
  { p: "CASSIUS", l: "It may be, these apparent prodigies,", acl: "2.1.208" },
  {
    p: "CASSIUS",
    l: "The unaccustom'd terror of this night,",
    acl: "2.1.209",
  },
  { p: "CASSIUS", l: "And the persuasion of his augurers,", acl: "2.1.210" },
  {
    p: "CASSIUS",
    l: "May hold him from the Capitol to-day.",
    acl: "2.1.211",
  },
  {
    p: "DECIUS BRUTUS",
    l: "Never fear that: if he be so resolved,",
    acl: "2.1.212",
  },
  {
    p: "DECIUS BRUTUS",
    l: "I can o'ersway him, for he loves to hear",
    acl: "2.1.213",
  },
  {
    p: "DECIUS BRUTUS",
    l: "That unicorns may be betray'd with trees,",
    acl: "2.1.214",
  },
  {
    p: "DECIUS BRUTUS",
    l: "And bears with glasses, elephants with holes,",
    acl: "2.1.215",
  },
  {
    p: "DECIUS BRUTUS",
    l: "Lions with toils and men with flatterers,",
    acl: "2.1.216",
  },
  {
    p: "DECIUS BRUTUS",
    l: "But when I tell him he hates flatterers,",
    acl: "2.1.217",
  },
  {
    p: "DECIUS BRUTUS",
    l: "He says he does, being then most flattered.",
    acl: "2.1.218",
  },
  {
    p: "DECIUS BRUTUS",
    l: "Let me work,",
    acl: "2.1.219",
  },
  {
    p: "DECIUS BRUTUS",
    l: "For I can give his humour the true bent,",
    acl: "2.1.220",
  },
  {
    p: "DECIUS BRUTUS",
    l: "And I will bring him to the Capitol.",
    acl: "2.1.221",
  },
  {
    p: "CASSIUS",
    l: "Nay, we will all of us be there to fetch him.",
    acl: "2.1.222",
  },
  {
    p: "BRUTUS",
    l: "By the eighth hour: is that the uttermost?",
    acl: "2.1.223",
  },
  {
    p: "CINNA",
    l: "Be that the uttermost, and fail not then.",
    acl: "2.1.224",
  },
  {
    p: "METELLUS CIMBER",
    l: "Caius Ligarius doth bear Caesar hard,",
    acl: "2.1.225",
  },
  {
    p: "METELLUS CIMBER",
    l: "Who rated him for speaking well of Pompey:",
    acl: "2.1.226",
  },
  {
    p: "METELLUS CIMBER",
    l: "I wonder none of you have thought of him.",
    acl: "2.1.227",
  },
  {
    p: "BRUTUS",
    l: "Now, good Metellus, go along by him:",
    acl: "2.1.228",
  },
  {
    p: "BRUTUS",
    l: "He loves me well, and I have given him reasons,",
    acl: "2.1.229",
  },
  {
    p: "BRUTUS",
    l: "Send him but hither, and I'll fashion him.",
    acl: "2.1.230",
  },
  {
    p: "CASSIUS",
    l: "The morning comes upon 's: we'll leave you, Brutus.",
    acl: "2.1.231",
  },
  {
    p: "CASSIUS",
    l: "And, friends, disperse yourselves, but all remember",
    acl: "2.1.232",
  },
  {
    p: "CASSIUS",
    l: "What you have said, and show yourselves true Romans.",
    acl: "2.1.233",
  },
  {
    p: "BRUTUS",
    l: "Good gentlemen, look fresh and merrily,",
    acl: "2.1.234",
  },
  { p: "BRUTUS", l: "Let not our looks put on our purposes,", acl: "2.1.235" },
  {
    p: "BRUTUS",
    l: "But bear it as our Roman actors do,",
    acl: "2.1.236",
  },
  {
    p: "BRUTUS",
    l: "With untired spirits and formal constancy:",
    acl: "2.1.237",
  },
  {
    p: "BRUTUS",
    l: "And so good morrow to you every one.",
    acl: "2.1.238",
  },
  { p: "BRUTUS", l: "Exeunt all but BRUTUS", acl: "" },
  {
    p: "BRUTUS",
    l: "Boy! Lucius! Fast asleep? It is no matter,",
    acl: "2.1.239",
  },
  { p: "BRUTUS", l: "Enjoy the honey-heavy dew of slumber:", acl: "2.1.240" },
  {
    p: "BRUTUS",
    l: "Thou hast no figures nor no fantasies,",
    acl: "2.1.241",
  },
  {
    p: "BRUTUS",
    l: "Which busy care draws in the brains of men,",
    acl: "2.1.242",
  },
  {
    p: "BRUTUS",
    l: "Therefore thou sleep'st so sound.",
    acl: "2.1.243",
  },
  { p: "BRUTUS", l: "Enter PORTIA", acl: "" },
  {
    p: "PORTIA",
    l: "Brutus, my lord!",
    acl: "2.1.244",
  },
  {
    p: "BRUTUS",
    l: "Portia, what mean you? wherefore rise you now?",
    acl: "2.1.245",
  },
  {
    p: "BRUTUS",
    l: "It is not for your health thus to commit",
    acl: "2.1.246",
  },
  {
    p: "BRUTUS",
    l: "Your weak condition to the raw cold morning.",
    acl: "2.1.247",
  },
  {
    p: "PORTIA",
    l: "Nor for yours neither. You've ungently, Brutus,",
    acl: "2.1.248",
  },
  {
    p: "PORTIA",
    l: "Stole from my bed: and yesternight, at supper,",
    acl: "2.1.249",
  },
  {
    p: "PORTIA",
    l: "You suddenly arose, and walk'd about,",
    acl: "2.1.250",
  },
  {
    p: "PORTIA",
    l: "Musing and sighing, with your arms across,",
    acl: "2.1.251",
  },
  {
    p: "PORTIA",
    l: "And when I ask'd you what the matter was,",
    acl: "2.1.252",
  },
  { p: "PORTIA", l: "You stared upon me with ungentle looks,", acl: "2.1.253" },
  {
    p: "PORTIA",
    l: "I urged you further, then you scratch'd your head,",
    acl: "2.1.254",
  },
  {
    p: "PORTIA",
    l: "And too impatiently stamp'd with your foot,",
    acl: "2.1.255",
  },
  {
    p: "PORTIA",
    l: "Yet I insisted, yet you answer'd not,",
    acl: "2.1.256",
  },
  {
    p: "PORTIA",
    l: "But, with an angry wafture of your hand,",
    acl: "2.1.257",
  },
  {
    p: "PORTIA",
    l: "Gave sign for me to leave you: so I did,",
    acl: "2.1.258",
  },
  { p: "PORTIA", l: "Fearing to strengthen that impatience", acl: "2.1.259" },
  {
    p: "PORTIA",
    l: "Which seem'd too much enkindled, and withal",
    acl: "2.1.260",
  },
  { p: "PORTIA", l: "Hoping it was but an effect of humour,", acl: "2.1.261" },
  {
    p: "PORTIA",
    l: "Which sometime hath his hour with every man.",
    acl: "2.1.262",
  },
  {
    p: "PORTIA",
    l: "It will not let you eat, nor talk, nor sleep,",
    acl: "2.1.263",
  },
  {
    p: "PORTIA",
    l: "And could it work so much upon your shape",
    acl: "2.1.264",
  },
  {
    p: "PORTIA",
    l: "As it hath much prevail'd on your condition,",
    acl: "2.1.265",
  },
  {
    p: "PORTIA",
    l: "I should not know you, Brutus. Dear my lord,",
    acl: "2.1.266",
  },
  {
    p: "PORTIA",
    l: "Make me acquainted with your cause of grief.",
    acl: "2.1.267",
  },
  {
    p: "BRUTUS",
    l: "I am not well in health, and that is all.",
    acl: "2.1.268",
  },
  {
    p: "PORTIA",
    l: "Brutus is wise, and, were he not in health,",
    acl: "2.1.269",
  },
  {
    p: "PORTIA",
    l: "He would embrace the means to come by it.",
    acl: "2.1.270",
  },
  { p: "BRUTUS", l: "Why, so I do. Good Portia, go to bed.", acl: "2.1.271" },
  {
    p: "PORTIA",
    l: "Is Brutus sick? and is it physical",
    acl: "2.1.272",
  },
  {
    p: "PORTIA",
    l: "To walk unbraced and suck up the humours",
    acl: "2.1.273",
  },
  {
    p: "PORTIA",
    l: "Of the dank morning? What, is Brutus sick,",
    acl: "2.1.274",
  },
  {
    p: "PORTIA",
    l: "And will he steal out of his wholesome bed,",
    acl: "2.1.275",
  },
  {
    p: "PORTIA",
    l: "To dare the vile contagion of the night",
    acl: "2.1.276",
  },
  { p: "PORTIA", l: "And tempt the rheumy and unpurged air", acl: "2.1.277" },
  {
    p: "PORTIA",
    l: "To add unto his sickness? No, my Brutus,",
    acl: "2.1.278",
  },
  {
    p: "PORTIA",
    l: "You have some sick offence within your mind,",
    acl: "2.1.279",
  },
  {
    p: "PORTIA",
    l: "Which, by the right and virtue of my place,",
    acl: "2.1.280",
  },
  { p: "PORTIA", l: "I ought to know of: and, upon my knees,", acl: "2.1.281" },
  {
    p: "PORTIA",
    l: "I charm you, by my once-commended beauty,",
    acl: "2.1.282",
  },
  {
    p: "PORTIA",
    l: "By all your vows of love and that great vow",
    acl: "2.1.283",
  },
  {
    p: "PORTIA",
    l: "Which did incorporate and make us one,",
    acl: "2.1.284",
  },
  {
    p: "PORTIA",
    l: "That you unfold to me, yourself, your half,",
    acl: "2.1.285",
  },
  {
    p: "PORTIA",
    l: "Why you are heavy, and what men to-night",
    acl: "2.1.286",
  },
  {
    p: "PORTIA",
    l: "Have had to resort to you: for here have been",
    acl: "2.1.287",
  },
  {
    p: "PORTIA",
    l: "Some six or seven, who did hide their faces",
    acl: "2.1.288",
  },
  { p: "PORTIA", l: "Even from darkness.", acl: "2.1.289" },
  {
    p: "BRUTUS",
    l: "Kneel not, gentle Portia.",
    acl: "2.1.290",
  },
  {
    p: "PORTIA",
    l: "I should not need, if you were gentle Brutus.",
    acl: "2.1.291",
  },
  {
    p: "PORTIA",
    l: "Within the bond of marriage, tell me, Brutus,",
    acl: "2.1.292",
  },
  { p: "PORTIA", l: "Is it excepted I should know no secrets", acl: "2.1.293" },
  {
    p: "PORTIA",
    l: "That appertain to you? Am I yourself",
    acl: "2.1.294",
  },
  { p: "PORTIA", l: "But, as it were, in sort or limitation,", acl: "2.1.295" },
  {
    p: "PORTIA",
    l: "To keep with you at meals, comfort your bed,",
    acl: "2.1.296",
  },
  {
    p: "PORTIA",
    l: "And talk to you sometimes? Dwell I but in the suburbs",
    acl: "2.1.297",
  },
  {
    p: "PORTIA",
    l: "Of your good pleasure? If it be no more,",
    acl: "2.1.298",
  },
  { p: "PORTIA", l: "Portia is Brutus' harlot, not his wife.", acl: "2.1.299" },
  {
    p: "BRUTUS",
    l: "You are my true and honourable wife,",
    acl: "2.1.300",
  },
  { p: "BRUTUS", l: "As dear to me as are the ruddy drops", acl: "2.1.301" },
  {
    p: "BRUTUS",
    l: "That visit my sad heart",
    acl: "2.1.302",
  },
  {
    p: "PORTIA",
    l: "If this were true, then should I know this secret.",
    acl: "2.1.303",
  },
  {
    p: "PORTIA",
    l: "I grant I am a woman, but withal",
    acl: "2.1.304",
  },
  { p: "PORTIA", l: "A woman that Lord Brutus took to wife:", acl: "2.1.305" },
  {
    p: "PORTIA",
    l: "I grant I am a woman, but withal",
    acl: "2.1.306",
  },
  { p: "PORTIA", l: "A woman well-reputed, Cato's daughter.", acl: "2.1.307" },
  {
    p: "PORTIA",
    l: "Think you I am no stronger than my sex,",
    acl: "2.1.308",
  },
  { p: "PORTIA", l: "Being so father'd and so husbanded?", acl: "2.1.309" },
  {
    p: "PORTIA",
    l: "Tell me your counsels, I will not disclose 'em:",
    acl: "2.1.310",
  },
  {
    p: "PORTIA",
    l: "I have made strong proof of my constancy,",
    acl: "2.1.311",
  },
  {
    p: "PORTIA",
    l: "Giving myself a voluntary wound",
    acl: "2.1.312",
  },
  {
    p: "PORTIA",
    l: "Here, in the thigh: can I bear that with patience.",
    acl: "2.1.313",
  },
  {
    p: "PORTIA",
    l: "And not my husband's secrets?",
    acl: "2.1.314",
  },
  { p: "BRUTUS", l: "O ye gods,", acl: "2.1.315" },
  {
    p: "BRUTUS",
    l: "Render me worthy of this noble wife!",
    acl: "2.1.316",
  },
  { p: "BRUTUS", l: "Knocking within", acl: "" },
  {
    p: "BRUTUS",
    l: "Hark, hark! one knocks: Portia, go in awhile,",
    acl: "2.1.317",
  },
  { p: "BRUTUS", l: "And by and by thy bosom shall partake", acl: "2.1.318" },
  {
    p: "BRUTUS",
    l: "The secrets of my heart.",
    acl: "2.1.319",
  },
  {
    p: "BRUTUS",
    l: "All my engagements I will construe to thee,",
    acl: "2.1.320",
  },
  {
    p: "BRUTUS",
    l: "All the charactery of my sad brows:",
    acl: "2.1.321",
  },
  { p: "BRUTUS", l: "Leave me with haste.", acl: "2.1.322" },
  {
    p: "BRUTUS",
    l: "Exit PORTIA",
    acl: "",
  },
  { p: "BRUTUS", l: "Lucius, who's that knocks?", acl: "2.1.323" },
  {
    p: "BRUTUS",
    l: "Re-enter LUCIUS with LIGARIUS",
    acl: "",
  },
  {
    p: "LUCIUS",
    l: "He is a sick man that would speak with you.",
    acl: "2.1.324",
  },
  {
    p: "BRUTUS",
    l: "Caius Ligarius, that Metellus spake of.",
    acl: "2.1.325",
  },
  { p: "BRUTUS", l: "Boy, stand aside. Caius Ligarius! how?", acl: "2.1.326" },
  {
    p: "LIGARIUS",
    l: "Vouchsafe good morrow from a feeble tongue.",
    acl: "2.1.327",
  },
  {
    p: "BRUTUS",
    l: "O, what a time have you chose out, brave Caius,",
    acl: "2.1.328",
  },
  {
    p: "BRUTUS",
    l: "To wear a kerchief! Would you were not sick!",
    acl: "2.1.329",
  },
  { p: "LIGARIUS", l: "I am not sick, if Brutus have in hand", acl: "2.1.330" },
  {
    p: "LIGARIUS",
    l: "Any exploit worthy the name of honour.",
    acl: "2.1.331",
  },
  {
    p: "BRUTUS",
    l: "Such an exploit have I in hand, Ligarius,",
    acl: "2.1.332",
  },
  {
    p: "BRUTUS",
    l: "Had you a healthful ear to hear of it.",
    acl: "2.1.333",
  },
  {
    p: "LIGARIUS",
    l: "By all the gods that Romans bow before,",
    acl: "2.1.334",
  },
  {
    p: "LIGARIUS",
    l: "I here discard my sickness! Soul of Rome!",
    acl: "2.1.335",
  },
  {
    p: "LIGARIUS",
    l: "Brave son, derived from honourable loins!",
    acl: "2.1.336",
  },
  {
    p: "LIGARIUS",
    l: "Thou, like an exorcist, hast conjured up",
    acl: "2.1.337",
  },
  { p: "LIGARIUS", l: "My mortified spirit. Now bid me run,", acl: "2.1.338" },
  {
    p: "LIGARIUS",
    l: "And I will strive with things impossible,",
    acl: "2.1.339",
  },
  {
    p: "LIGARIUS",
    l: "Yea, get the better of them. What's to do?",
    acl: "2.1.340",
  },
  {
    p: "BRUTUS",
    l: "A piece of work that will make sick men whole.",
    acl: "2.1.341",
  },
  {
    p: "LIGARIUS",
    l: "But are not some whole that we must make sick?",
    acl: "2.1.342",
  },
  {
    p: "BRUTUS",
    l: "That must we also. What it is, my Caius,",
    acl: "2.1.343",
  },
  { p: "BRUTUS", l: "I shall unfold to thee, as we are going", acl: "2.1.344" },
  {
    p: "BRUTUS",
    l: "To whom it must be done.",
    acl: "2.1.345",
  },
  { p: "LIGARIUS", l: "Set on your foot,", acl: "2.1.346" },
  {
    p: "LIGARIUS",
    l: "And with a heart new-fired I follow you,",
    acl: "2.1.347",
  },
  {
    p: "LIGARIUS",
    l: "To do I know not what: but it sufficeth",
    acl: "2.1.348",
  },
  {
    p: "LIGARIUS",
    l: "That Brutus leads me on.",
    acl: "2.1.349",
  },
  { p: "BRUTUS", l: "Follow me, then.", acl: "2.1.350" },
  {
    p: "BRUTUS",
    l: "Exeunt",
    acl: "",
  },
  { p: "BRUTUS", l: "SCENE II. CAESAR's house.", acl: "" },
  {
    p: "BRUTUS",
    l: "Thunder and lightning. Enter CAESAR, in his night-gown",
    acl: "",
  },
  {
    p: "CAESAR",
    l: "Nor heaven nor earth have been at peace to-night:",
    acl: "2.2.1",
  },
  {
    p: "CAESAR",
    l: "Thrice hath Calpurnia in her sleep cried out,",
    acl: "2.2.2",
  },
  {
    p: "CAESAR",
    l: "'Help, ho! they murder Caesar!' Who's within?",
    acl: "2.2.3",
  },
  {
    p: "CAESAR",
    l: "Enter a Servant",
    acl: "",
  },
  { p: "Servant", l: "My lord?", acl: "2.2.4" },
  {
    p: "CAESAR",
    l: "Go bid the priests do present sacrifice",
    acl: "2.2.5",
  },
  { p: "CAESAR", l: "And bring me their opinions of success.", acl: "2.2.6" },
  {
    p: "Servant",
    l: "I will, my lord.",
    acl: "2.2.7",
  },
  { p: "Servant", l: "Exit", acl: "" },
  { p: "Servant", l: "Enter CALPURNIA", acl: "" },
  {
    p: "CALPURNIA",
    l: "What mean you, Caesar? think you to walk forth?",
    acl: "2.2.8",
  },
  {
    p: "CALPURNIA",
    l: "You shall not stir out of your house to-day.",
    acl: "2.2.9",
  },
  {
    p: "CAESAR",
    l: "Caesar shall forth: the things that threaten'd me",
    acl: "2.2.10",
  },
  {
    p: "CAESAR",
    l: "Ne'er look'd but on my back, when they shall see",
    acl: "2.2.11",
  },
  {
    p: "CAESAR",
    l: "The face of Caesar, they are vanished.",
    acl: "2.2.12",
  },
  { p: "CALPURNIA", l: "Caesar, I never stood on ceremonies,", acl: "2.2.13" },
  {
    p: "CALPURNIA",
    l: "Yet now they fright me. There is one within,",
    acl: "2.2.14",
  },
  {
    p: "CALPURNIA",
    l: "Besides the things that we have heard and seen,",
    acl: "2.2.15",
  },
  {
    p: "CALPURNIA",
    l: "Recounts most horrid sights seen by the watch.",
    acl: "2.2.16",
  },
  {
    p: "CALPURNIA",
    l: "A lioness hath whelped in the streets,",
    acl: "2.2.17",
  },
  {
    p: "CALPURNIA",
    l: "And graves have yawn'd, and yielded up their dead,",
    acl: "2.2.18",
  },
  {
    p: "CALPURNIA",
    l: "Fierce fiery warriors fought upon the clouds,",
    acl: "2.2.19",
  },
  {
    p: "CALPURNIA",
    l: "In ranks and squadrons and right form of war,",
    acl: "2.2.20",
  },
  {
    p: "CALPURNIA",
    l: "Which drizzled blood upon the Capitol,",
    acl: "2.2.21",
  },
  {
    p: "CALPURNIA",
    l: "The noise of battle hurtled in the air,",
    acl: "2.2.22",
  },
  {
    p: "CALPURNIA",
    l: "Horses did neigh, and dying men did groan,",
    acl: "2.2.23",
  },
  {
    p: "CALPURNIA",
    l: "And ghosts did shriek and squeal about the streets.",
    acl: "2.2.24",
  },
  {
    p: "CALPURNIA",
    l: "O Caesar! these things are beyond all use,",
    acl: "2.2.25",
  },
  {
    p: "CALPURNIA",
    l: "And I do fear them.",
    acl: "2.2.26",
  },
  { p: "CAESAR", l: "What can be avoided", acl: "2.2.27" },
  {
    p: "CAESAR",
    l: "Whose end is purposed by the mighty gods?",
    acl: "2.2.28",
  },
  {
    p: "CAESAR",
    l: "Yet Caesar shall go forth, for these predictions",
    acl: "2.2.29",
  },
  {
    p: "CAESAR",
    l: "Are to the world in general as to Caesar.",
    acl: "2.2.30",
  },
  {
    p: "CALPURNIA",
    l: "When beggars die, there are no comets seen,",
    acl: "2.2.31",
  },
  {
    p: "CALPURNIA",
    l: "The heavens themselves blaze forth the death of princes.",
    acl: "2.2.32",
  },
  {
    p: "CAESAR",
    l: "Cowards die many times before their deaths,",
    acl: "2.2.33",
  },
  {
    p: "CAESAR",
    l: "The valiant never taste of death but once.",
    acl: "2.2.34",
  },
  {
    p: "CAESAR",
    l: "Of all the wonders that I yet have heard.",
    acl: "2.2.35",
  },
  {
    p: "CAESAR",
    l: "It seems to me most strange that men should fear,",
    acl: "2.2.36",
  },
  { p: "CAESAR", l: "Seeing that death, a necessary end,", acl: "2.2.37" },
  {
    p: "CAESAR",
    l: "Will come when it will come.",
    acl: "2.2.38",
  },
  { p: "CAESAR", l: "Re-enter Servant", acl: "" },
  {
    p: "CAESAR",
    l: "What say the augurers?",
    acl: "2.2.39",
  },
  {
    p: "Servant",
    l: "They would not have you to stir forth to-day.",
    acl: "2.2.40",
  },
  {
    p: "Servant",
    l: "Plucking the entrails of an offering forth,",
    acl: "2.2.41",
  },
  {
    p: "Servant",
    l: "They could not find a heart within the beast.",
    acl: "2.2.42",
  },
  {
    p: "CAESAR",
    l: "The gods do this in shame of cowardice:",
    acl: "2.2.43",
  },
  {
    p: "CAESAR",
    l: "Caesar should be a beast without a heart,",
    acl: "2.2.44",
  },
  {
    p: "CAESAR",
    l: "If he should stay at home to-day for fear.",
    acl: "2.2.45",
  },
  {
    p: "CAESAR",
    l: "No, Caesar shall not: danger knows full well",
    acl: "2.2.46",
  },
  {
    p: "CAESAR",
    l: "That Caesar is more dangerous than he:",
    acl: "2.2.47",
  },
  { p: "CAESAR", l: "We are two lions litter'd in one day,", acl: "2.2.48" },
  {
    p: "CAESAR",
    l: "And I the elder and more terrible:",
    acl: "2.2.49",
  },
  { p: "CAESAR", l: "And Caesar shall go forth.", acl: "2.2.50" },
  {
    p: "CALPURNIA",
    l: "Alas, my lord,",
    acl: "2.2.51",
  },
  {
    p: "CALPURNIA",
    l: "Your wisdom is consumed in confidence.",
    acl: "2.2.52",
  },
  {
    p: "CALPURNIA",
    l: "Do not go forth to-day: call it my fear",
    acl: "2.2.53",
  },
  {
    p: "CALPURNIA",
    l: "That keeps you in the house, and not your own.",
    acl: "2.2.54",
  },
  {
    p: "CALPURNIA",
    l: "We'll send Mark Antony to the senate-house:",
    acl: "2.2.55",
  },
  {
    p: "CALPURNIA",
    l: "And he shall say you are not well to-day:",
    acl: "2.2.56",
  },
  {
    p: "CALPURNIA",
    l: "Let me, upon my knee, prevail in this.",
    acl: "2.2.57",
  },
  { p: "CAESAR", l: "Mark Antony shall say I am not well,", acl: "2.2.58" },
  {
    p: "CAESAR",
    l: "And, for thy humour, I will stay at home.",
    acl: "2.2.59",
  },
  { p: "CAESAR", l: "Enter DECIUS BRUTUS", acl: "" },
  {
    p: "CAESAR",
    l: "Here's Decius Brutus, he shall tell them so.",
    acl: "2.2.60",
  },
  {
    p: "DECIUS BRUTUS",
    l: "Caesar, all hail! good morrow, worthy Caesar:",
    acl: "2.2.61",
  },
  {
    p: "DECIUS BRUTUS",
    l: "I come to fetch you to the senate-house.",
    acl: "2.2.62",
  },
  { p: "CAESAR", l: "And you are come in very happy time,", acl: "2.2.63" },
  {
    p: "CAESAR",
    l: "To bear my greeting to the senators",
    acl: "2.2.64",
  },
  {
    p: "CAESAR",
    l: "And tell them that I will not come to-day:",
    acl: "2.2.65",
  },
  {
    p: "CAESAR",
    l: "Cannot, is false, and that I dare not, falser:",
    acl: "2.2.66",
  },
  {
    p: "CAESAR",
    l: "I will not come to-day: tell them so, Decius.",
    acl: "2.2.67",
  },
  {
    p: "CALPURNIA",
    l: "Say he is sick.",
    acl: "2.2.68",
  },
  { p: "CAESAR", l: "Shall Caesar send a lie?", acl: "2.2.69" },
  {
    p: "CAESAR",
    l: "Have I in conquest stretch'd mine arm so far,",
    acl: "2.2.70",
  },
  {
    p: "CAESAR",
    l: "To be afraid to tell graybeards the truth?",
    acl: "2.2.71",
  },
  {
    p: "CAESAR",
    l: "Decius, go tell them Caesar will not come.",
    acl: "2.2.72",
  },
  {
    p: "DECIUS BRUTUS",
    l: "Most mighty Caesar, let me know some cause,",
    acl: "2.2.73",
  },
  {
    p: "DECIUS BRUTUS",
    l: "Lest I be laugh'd at when I tell them so.",
    acl: "2.2.74",
  },
  {
    p: "CAESAR",
    l: "The cause is in my will: I will not come,",
    acl: "2.2.75",
  },
  {
    p: "CAESAR",
    l: "That is enough to satisfy the senate.",
    acl: "2.2.76",
  },
  { p: "CAESAR", l: "But for your private satisfaction,", acl: "2.2.77" },
  {
    p: "CAESAR",
    l: "Because I love you, I will let you know:",
    acl: "2.2.78",
  },
  {
    p: "CAESAR",
    l: "Calpurnia here, my wife, stays me at home:",
    acl: "2.2.79",
  },
  {
    p: "CAESAR",
    l: "She dreamt to-night she saw my statua,",
    acl: "2.2.80",
  },
  {
    p: "CAESAR",
    l: "Which, like a fountain with an hundred spouts,",
    acl: "2.2.81",
  },
  {
    p: "CAESAR",
    l: "Did run pure blood: and many lusty Romans",
    acl: "2.2.82",
  },
  {
    p: "CAESAR",
    l: "Came smiling, and did bathe their hands in it:",
    acl: "2.2.83",
  },
  {
    p: "CAESAR",
    l: "And these does she apply for warnings, and portents,",
    acl: "2.2.84",
  },
  { p: "CAESAR", l: "And evils imminent, and on her knee", acl: "2.2.85" },
  {
    p: "CAESAR",
    l: "Hath begg'd that I will stay at home to-day.",
    acl: "2.2.86",
  },
  {
    p: "DECIUS BRUTUS",
    l: "This dream is all amiss interpreted,",
    acl: "2.2.87",
  },
  {
    p: "DECIUS BRUTUS",
    l: "It was a vision fair and fortunate:",
    acl: "2.2.88",
  },
  {
    p: "DECIUS BRUTUS",
    l: "Your statue spouting blood in many pipes,",
    acl: "2.2.89",
  },
  {
    p: "DECIUS BRUTUS",
    l: "In which so many smiling Romans bathed,",
    acl: "2.2.90",
  },
  {
    p: "DECIUS BRUTUS",
    l: "Signifies that from you great Rome shall suck",
    acl: "2.2.91",
  },
  {
    p: "DECIUS BRUTUS",
    l: "Reviving blood, and that great men shall press",
    acl: "2.2.92",
  },
  {
    p: "DECIUS BRUTUS",
    l: "For tinctures, stains, relics and cognizance.",
    acl: "2.2.93",
  },
  {
    p: "DECIUS BRUTUS",
    l: "This by Calpurnia's dream is signified.",
    acl: "2.2.94",
  },
  { p: "CAESAR", l: "And this way have you well expounded it.", acl: "2.2.95" },
  {
    p: "DECIUS BRUTUS",
    l: "I have, when you have heard what I can say:",
    acl: "2.2.96",
  },
  {
    p: "DECIUS BRUTUS",
    l: "And know it now: the senate have concluded",
    acl: "2.2.97",
  },
  {
    p: "DECIUS BRUTUS",
    l: "To give this day a crown to mighty Caesar.",
    acl: "2.2.98",
  },
  {
    p: "DECIUS BRUTUS",
    l: "If you shall send them word you will not come,",
    acl: "2.2.99",
  },
  {
    p: "DECIUS BRUTUS",
    l: "Their minds may change. Besides, it were a mock",
    acl: "2.2.100",
  },
  {
    p: "DECIUS BRUTUS",
    l: "Apt to be render'd, for some one to say",
    acl: "2.2.101",
  },
  {
    p: "DECIUS BRUTUS",
    l: "'Break up the senate till another time,",
    acl: "2.2.102",
  },
  {
    p: "DECIUS BRUTUS",
    l: "When Caesar's wife shall meet with better dreams.'",
    acl: "2.2.103",
  },
  {
    p: "DECIUS BRUTUS",
    l: "If Caesar hide himself, shall they not whisper",
    acl: "2.2.104",
  },
  { p: "DECIUS BRUTUS", l: "'Lo, Caesar is afraid'?", acl: "2.2.105" },
  {
    p: "DECIUS BRUTUS",
    l: "Pardon me, Caesar, for my dear dear love",
    acl: "2.2.106",
  },
  {
    p: "DECIUS BRUTUS",
    l: "To our proceeding bids me tell you this,",
    acl: "2.2.107",
  },
  {
    p: "DECIUS BRUTUS",
    l: "And reason to my love is liable.",
    acl: "2.2.108",
  },
  {
    p: "CAESAR",
    l: "How foolish do your fears seem now, Calpurnia!",
    acl: "2.2.109",
  },
  {
    p: "CAESAR",
    l: "I am ashamed I did yield to them.",
    acl: "2.2.110",
  },
  { p: "CAESAR", l: "Give me my robe, for I will go.", acl: "2.2.111" },
  {
    p: "CAESAR",
    l: "Enter PUBLIUS, BRUTUS, LIGARIUS, METELLUS, CASCA, TREBONIUS, and CINNA",
    acl: "",
  },
  {
    p: "CAESAR",
    l: "And look where Publius is come to fetch me.",
    acl: "2.2.112",
  },
  {
    p: "PUBLIUS",
    l: "Good morrow, Caesar.",
    acl: "2.2.113",
  },
  { p: "CAESAR", l: "Welcome, Publius.", acl: "2.2.114" },
  {
    p: "CAESAR",
    l: "What, Brutus, are you stirr'd so early too?",
    acl: "2.2.115",
  },
  { p: "CAESAR", l: "Good morrow, Casca. Caius Ligarius,", acl: "2.2.116" },
  {
    p: "CAESAR",
    l: "Caesar was ne'er so much your enemy",
    acl: "2.2.117",
  },
  {
    p: "CAESAR",
    l: "As that same ague which hath made you lean.",
    acl: "2.2.118",
  },
  {
    p: "CAESAR",
    l: "What is 't o'clock?",
    acl: "2.2.119",
  },
  { p: "BRUTUS", l: "Caesar, 'tis strucken eight.", acl: "2.2.120" },
  {
    p: "CAESAR",
    l: "I thank you for your pains and courtesy.",
    acl: "2.2.121",
  },
  { p: "CAESAR", l: "Enter ANTONY", acl: "" },
  {
    p: "CAESAR",
    l: "See! Antony, that revels long o' nights,",
    acl: "2.2.122",
  },
  {
    p: "CAESAR",
    l: "Is notwithstanding up. Good morrow, Antony.",
    acl: "2.2.123",
  },
  {
    p: "ANTONY",
    l: "So to most noble Caesar.",
    acl: "2.2.124",
  },
  { p: "CAESAR", l: "Bid them prepare within:", acl: "2.2.125" },
  {
    p: "CAESAR",
    l: "I am to blame to be thus waited for.",
    acl: "2.2.126",
  },
  {
    p: "CAESAR",
    l: "Now, Cinna: now, Metellus: what, Trebonius!",
    acl: "2.2.127",
  },
  {
    p: "CAESAR",
    l: "I have an hour's talk in store for you,",
    acl: "2.2.128",
  },
  { p: "CAESAR", l: "Remember that you call on me to-day:", acl: "2.2.129" },
  {
    p: "CAESAR",
    l: "Be near me, that I may remember you.",
    acl: "2.2.130",
  },
  { p: "TREBONIUS", l: "Caesar, I will:", acl: "2.2.131" },
  {
    p: "TREBONIUS",
    l: "Aside",
    acl: "",
  },
  { p: "TREBONIUS", l: "and so near will I be,", acl: "2.2.132" },
  {
    p: "TREBONIUS",
    l: "That your best friends shall wish I had been further.",
    acl: "2.2.133",
  },
  {
    p: "CAESAR",
    l: "Good friends, go in, and taste some wine with me,",
    acl: "2.2.134",
  },
  {
    p: "CAESAR",
    l: "And we, like friends, will straightway go together.",
    acl: "2.2.135",
  },
  {
    p: "BRUTUS",
    l: "[Aside]  That every like is not the same, O Caesar,",
    acl: "2.2.136",
  },
  {
    p: "BRUTUS",
    l: "The heart of Brutus yearns to think upon!",
    acl: "2.2.137",
  },
  { p: "BRUTUS", l: "Exeunt", acl: "" },
  {
    p: "BRUTUS",
    l: "SCENE III. A street near the Capitol.",
    acl: "",
  },
  { p: "BRUTUS", l: "Enter ARTEMIDORUS, reading a paper", acl: "" },
  {
    p: "ARTEMIDORUS",
    l: "'Caesar, beware of Brutus, take heed of Cassius,",
    acl: "2.3.1",
  },
  {
    p: "ARTEMIDORUS",
    l: "come not near Casca, have an eye to Cinna, trust not",
    acl: "2.3.2",
  },
  {
    p: "ARTEMIDORUS",
    l: "Trebonius: mark well Metellus Cimber: Decius Brutus",
    acl: "2.3.3",
  },
  {
    p: "ARTEMIDORUS",
    l: "loves thee not: thou hast wronged Caius Ligarius.",
    acl: "2.3.4",
  },
  {
    p: "ARTEMIDORUS",
    l: "There is but one mind in all these men, and it is",
    acl: "2.3.5",
  },
  {
    p: "ARTEMIDORUS",
    l: "bent against Caesar. If thou beest not immortal,",
    acl: "2.3.6",
  },
  {
    p: "ARTEMIDORUS",
    l: "look about you: security gives way to conspiracy.",
    acl: "2.3.7",
  },
  {
    p: "ARTEMIDORUS",
    l: "The mighty gods defend thee! Thy lover,",
    acl: "2.3.8",
  },
  {
    p: "ARTEMIDORUS",
    l: "'ARTEMIDORUS.'",
    acl: "2.3.9",
  },
  {
    p: "ARTEMIDORUS",
    l: "Here will I stand till Caesar pass along,",
    acl: "2.3.10",
  },
  {
    p: "ARTEMIDORUS",
    l: "And as a suitor will I give him this.",
    acl: "2.3.11",
  },
  {
    p: "ARTEMIDORUS",
    l: "My heart laments that virtue cannot live",
    acl: "2.3.12",
  },
  {
    p: "ARTEMIDORUS",
    l: "Out of the teeth of emulation.",
    acl: "2.3.13",
  },
  {
    p: "ARTEMIDORUS",
    l: "If thou read this, O Caesar, thou mayst live,",
    acl: "2.3.14",
  },
  {
    p: "ARTEMIDORUS",
    l: "If not, the Fates with traitors do contrive.",
    acl: "2.3.15",
  },
  { p: "ARTEMIDORUS", l: "Exit", acl: "" },
  {
    p: "ARTEMIDORUS",
    l: "SCENE IV. Another part of the same street, before the house of BRUTUS.",
    acl: "",
  },
  { p: "ARTEMIDORUS", l: "Enter PORTIA and LUCIUS", acl: "" },
  {
    p: "PORTIA",
    l: "I prithee, boy, run to the senate-house,",
    acl: "2.4.1",
  },
  { p: "PORTIA", l: "Stay not to answer me, but get thee gone:", acl: "2.4.2" },
  {
    p: "PORTIA",
    l: "Why dost thou stay?",
    acl: "2.4.3",
  },
  { p: "LUCIUS", l: "To know my errand, madam.", acl: "2.4.4" },
  {
    p: "PORTIA",
    l: "I would have had thee there, and here again,",
    acl: "2.4.5",
  },
  {
    p: "PORTIA",
    l: "Ere I can tell thee what thou shouldst do there.",
    acl: "2.4.6",
  },
  {
    p: "PORTIA",
    l: "O constancy, be strong upon my side,",
    acl: "2.4.7",
  },
  {
    p: "PORTIA",
    l: "Set a huge mountain 'tween my heart and tongue!",
    acl: "2.4.8",
  },
  {
    p: "PORTIA",
    l: "I have a man's mind, but a woman's might.",
    acl: "2.4.9",
  },
  {
    p: "PORTIA",
    l: "How hard it is for women to keep counsel!",
    acl: "2.4.10",
  },
  {
    p: "PORTIA",
    l: "Art thou here yet?",
    acl: "2.4.11",
  },
  { p: "LUCIUS", l: "Madam, what should I do?", acl: "2.4.12" },
  {
    p: "LUCIUS",
    l: "Run to the Capitol, and nothing else?",
    acl: "2.4.13",
  },
  { p: "LUCIUS", l: "And so return to you, and nothing else?", acl: "2.4.14" },
  {
    p: "PORTIA",
    l: "Yes, bring me word, boy, if thy lord look well,",
    acl: "2.4.15",
  },
  {
    p: "PORTIA",
    l: "For he went sickly forth: and take good note",
    acl: "2.4.16",
  },
  {
    p: "PORTIA",
    l: "What Caesar doth, what suitors press to him.",
    acl: "2.4.17",
  },
  { p: "PORTIA", l: "Hark, boy! what noise is that?", acl: "2.4.18" },
  {
    p: "LUCIUS",
    l: "I hear none, madam.",
    acl: "2.4.19",
  },
  { p: "PORTIA", l: "Prithee, listen well,", acl: "2.4.20" },
  {
    p: "PORTIA",
    l: "I heard a bustling rumour, like a fray,",
    acl: "2.4.21",
  },
  { p: "PORTIA", l: "And the wind brings it from the Capitol.", acl: "2.4.22" },
  {
    p: "LUCIUS",
    l: "Sooth, madam, I hear nothing.",
    acl: "2.4.23",
  },
  { p: "LUCIUS", l: "Enter the Soothsayer", acl: "" },
  {
    p: "PORTIA",
    l: "Come hither, fellow: which way hast thou been?",
    acl: "2.4.24",
  },
  { p: "Soothsayer", l: "At mine own house, good lady.", acl: "2.4.25" },
  {
    p: "PORTIA",
    l: "What is't o'clock?",
    acl: "2.4.26",
  },
  { p: "Soothsayer", l: "About the ninth hour, lady.", acl: "2.4.27" },
  {
    p: "PORTIA",
    l: "Is Caesar yet gone to the Capitol?",
    acl: "2.4.28",
  },
  {
    p: "Soothsayer",
    l: "Madam, not yet: I go to take my stand,",
    acl: "2.4.29",
  },
  {
    p: "Soothsayer",
    l: "To see him pass on to the Capitol.",
    acl: "2.4.30",
  },
  {
    p: "PORTIA",
    l: "Thou hast some suit to Caesar, hast thou not?",
    acl: "2.4.31",
  },
  {
    p: "Soothsayer",
    l: "That I have, lady: if it will please Caesar",
    acl: "2.4.32",
  },
  {
    p: "Soothsayer",
    l: "To be so good to Caesar as to hear me,",
    acl: "2.4.33",
  },
  {
    p: "Soothsayer",
    l: "I shall beseech him to befriend himself.",
    acl: "2.4.34",
  },
  {
    p: "PORTIA",
    l: "Why, know'st thou any harm's intended towards him?",
    acl: "2.4.35",
  },
  {
    p: "Soothsayer",
    l: "None that I know will be, much that I fear may chance.",
    acl: "2.4.36",
  },
  {
    p: "Soothsayer",
    l: "Good morrow to you. Here the street is narrow:",
    acl: "2.4.37",
  },
  {
    p: "Soothsayer",
    l: "The throng that follows Caesar at the heels,",
    acl: "2.4.38",
  },
  {
    p: "Soothsayer",
    l: "Of senators, of praetors, common suitors,",
    acl: "2.4.39",
  },
  {
    p: "Soothsayer",
    l: "Will crowd a feeble man almost to death:",
    acl: "2.4.40",
  },
  {
    p: "Soothsayer",
    l: "I'll get me to a place more void, and there",
    acl: "2.4.41",
  },
  {
    p: "Soothsayer",
    l: "Speak to great Caesar as he comes along.",
    acl: "2.4.42",
  },
  { p: "Soothsayer", l: "Exit", acl: "" },
  {
    p: "PORTIA",
    l: "I must go in. Ay me, how weak a thing",
    acl: "2.4.43",
  },
  { p: "PORTIA", l: "The heart of woman is! O Brutus,", acl: "2.4.44" },
  {
    p: "PORTIA",
    l: "The heavens speed thee in thine enterprise!",
    acl: "2.4.45",
  },
  {
    p: "PORTIA",
    l: "Sure, the boy heard me: Brutus hath a suit",
    acl: "2.4.46",
  },
  {
    p: "PORTIA",
    l: "That Caesar will not grant. O, I grow faint.",
    acl: "2.4.47",
  },
  { p: "PORTIA", l: "Run, Lucius, and commend me to my lord,", acl: "2.4.48" },
  {
    p: "PORTIA",
    l: "Say I am merry: come to me again,",
    acl: "2.4.49",
  },
  {
    p: "PORTIA",
    l: "And bring me word what he doth say to thee.",
    acl: "2.4.50",
  },
  {
    p: "PORTIA",
    l: "Exeunt severally",
    acl: "",
  },
  { p: "PORTIA", l: "ACT III", acl: "" },
  {
    p: "PORTIA",
    l: "SCENE I. Rome. Before the Capitol, the Senate sitting above.",
    acl: "",
  },
  {
    p: "PORTIA",
    l: "A crowd of people, among them ARTEMIDORUS and the  Soothsayer. Flourish. Enter CAESAR, BRUTUS,  CASSIUS, CASCA, DECIUS BRUTUS, METELLUS CIMBER,  TREBONIUS, CINNA, ANTONY, LEPIDUS, POPILIUS, PUBLIUS, and others",
    acl: "",
  },
  {
    p: "CAESAR",
    l: "[To the Soothsayer]  The ides of March are come.",
    acl: "3.1.1",
  },
  {
    p: "Soothsayer",
    l: "Ay, Caesar, but not gone.",
    acl: "3.1.2",
  },
  { p: "ARTEMIDORUS", l: "Hail, Caesar! read this schedule.", acl: "3.1.3" },
  {
    p: "DECIUS BRUTUS",
    l: "Trebonius doth desire you to o'erread,",
    acl: "3.1.4",
  },
  {
    p: "DECIUS BRUTUS",
    l: "At your best leisure, this his humble suit.",
    acl: "3.1.5",
  },
  {
    p: "ARTEMIDORUS",
    l: "O Caesar, read mine first, for mine's a suit",
    acl: "3.1.6",
  },
  {
    p: "ARTEMIDORUS",
    l: "That touches Caesar nearer: read it, great Caesar.",
    acl: "3.1.7",
  },
  {
    p: "CAESAR",
    l: "What touches us ourself shall be last served.",
    acl: "3.1.8",
  },
  {
    p: "ARTEMIDORUS",
    l: "Delay not, Caesar, read it instantly.",
    acl: "3.1.9",
  },
  {
    p: "CAESAR",
    l: "What, is the fellow mad?",
    acl: "3.1.10",
  },
  { p: "PUBLIUS", l: "Sirrah, give place.", acl: "3.1.11" },
  {
    p: "CASSIUS",
    l: "What, urge you your petitions in the street?",
    acl: "3.1.12",
  },
  { p: "CASSIUS", l: "Come to the Capitol.", acl: "3.1.13" },
  {
    p: "CASSIUS",
    l: "CAESAR goes up to the Senate-House, the rest following",
    acl: "",
  },
  {
    p: "POPILIUS",
    l: "I wish your enterprise to-day may thrive.",
    acl: "3.1.14",
  },
  {
    p: "CASSIUS",
    l: "What enterprise, Popilius?",
    acl: "3.1.15",
  },
  { p: "POPILIUS", l: "Fare you well.", acl: "3.1.16" },
  {
    p: "POPILIUS",
    l: "Advances to CAESAR",
    acl: "",
  },
  { p: "BRUTUS", l: "What said Popilius Lena?", acl: "3.1.17" },
  {
    p: "CASSIUS",
    l: "He wish'd to-day our enterprise might thrive.",
    acl: "3.1.18",
  },
  { p: "CASSIUS", l: "I fear our purpose is discovered.", acl: "3.1.19" },
  {
    p: "BRUTUS",
    l: "Look, how he makes to Caesar, mark him.",
    acl: "3.1.20",
  },
  {
    p: "CASSIUS",
    l: "Casca, be sudden, for we fear prevention.",
    acl: "3.1.21",
  },
  {
    p: "CASSIUS",
    l: "Brutus, what shall be done? If this be known,",
    acl: "3.1.22",
  },
  {
    p: "CASSIUS",
    l: "Cassius or Caesar never shall turn back,",
    acl: "3.1.23",
  },
  {
    p: "CASSIUS",
    l: "For I will slay myself.",
    acl: "3.1.24",
  },
  { p: "BRUTUS", l: "Cassius, be constant:", acl: "3.1.25" },
  {
    p: "BRUTUS",
    l: "Popilius Lena speaks not of our purposes,",
    acl: "3.1.26",
  },
  {
    p: "BRUTUS",
    l: "For, look, he smiles, and Caesar doth not change.",
    acl: "3.1.27",
  },
  {
    p: "CASSIUS",
    l: "Trebonius knows his time, for, look you, Brutus.",
    acl: "3.1.28",
  },
  { p: "CASSIUS", l: "He draws Mark Antony out of the way.", acl: "3.1.29" },
  {
    p: "CASSIUS",
    l: "Exeunt ANTONY and TREBONIUS",
    acl: "",
  },
  {
    p: "DECIUS BRUTUS",
    l: "Where is Metellus Cimber? Let him go,",
    acl: "3.1.30",
  },
  {
    p: "DECIUS BRUTUS",
    l: "And presently prefer his suit to Caesar.",
    acl: "3.1.31",
  },
  {
    p: "BRUTUS",
    l: "He is address'd: press near and second him.",
    acl: "3.1.32",
  },
  {
    p: "CINNA",
    l: "Casca, you are the first that rears your hand.",
    acl: "3.1.33",
  },
  { p: "CAESAR", l: "Are we all ready? What is now amiss", acl: "3.1.34" },
  {
    p: "CAESAR",
    l: "That Caesar and his senate must redress?",
    acl: "3.1.35",
  },
  {
    p: "METELLUS CIMBER",
    l: "Most high, most mighty, and most puissant Caesar,",
    acl: "3.1.36",
  },
  {
    p: "METELLUS CIMBER",
    l: "Metellus Cimber throws before thy seat",
    acl: "3.1.37",
  },
  {
    p: "METELLUS CIMBER",
    l: "An humble heart,--",
    acl: "3.1.38",
  },
  { p: "METELLUS CIMBER", l: "Kneeling", acl: "" },
  {
    p: "CAESAR",
    l: "I must prevent thee, Cimber.",
    acl: "3.1.39",
  },
  {
    p: "CAESAR",
    l: "These couchings and these lowly courtesies",
    acl: "3.1.40",
  },
  {
    p: "CAESAR",
    l: "Might fire the blood of ordinary men,",
    acl: "3.1.41",
  },
  { p: "CAESAR", l: "And turn pre-ordinance and first decree", acl: "3.1.42" },
  {
    p: "CAESAR",
    l: "Into the law of children. Be not fond,",
    acl: "3.1.43",
  },
  {
    p: "CAESAR",
    l: "To think that Caesar bears such rebel blood",
    acl: "3.1.44",
  },
  {
    p: "CAESAR",
    l: "That will be thaw'd from the true quality",
    acl: "3.1.45",
  },
  {
    p: "CAESAR",
    l: "With that which melteth fools, I mean, sweet words,",
    acl: "3.1.46",
  },
  {
    p: "CAESAR",
    l: "Low-crooked court'sies and base spaniel-fawning.",
    acl: "3.1.47",
  },
  { p: "CAESAR", l: "Thy brother by decree is banished:", acl: "3.1.48" },
  {
    p: "CAESAR",
    l: "If thou dost bend and pray and fawn for him,",
    acl: "3.1.49",
  },
  { p: "CAESAR", l: "I spurn thee like a cur out of my way.", acl: "3.1.50" },
  {
    p: "CAESAR",
    l: "Know, Caesar doth not wrong, nor without cause",
    acl: "3.1.51",
  },
  { p: "CAESAR", l: "Will he be satisfied.", acl: "3.1.52" },
  {
    p: "METELLUS CIMBER",
    l: "Is there no voice more worthy than my own",
    acl: "3.1.53",
  },
  {
    p: "METELLUS CIMBER",
    l: "To sound more sweetly in great Caesar's ear",
    acl: "3.1.54",
  },
  {
    p: "METELLUS CIMBER",
    l: "For the repealing of my banish'd brother?",
    acl: "3.1.55",
  },
  {
    p: "BRUTUS",
    l: "I kiss thy hand, but not in flattery, Caesar,",
    acl: "3.1.56",
  },
  { p: "BRUTUS", l: "Desiring thee that Publius Cimber may", acl: "3.1.57" },
  {
    p: "BRUTUS",
    l: "Have an immediate freedom of repeal.",
    acl: "3.1.58",
  },
  { p: "CAESAR", l: "What, Brutus!", acl: "3.1.59" },
  {
    p: "CASSIUS",
    l: "Pardon, Caesar, Caesar, pardon:",
    acl: "3.1.60",
  },
  {
    p: "CASSIUS",
    l: "As low as to thy foot doth Cassius fall,",
    acl: "3.1.61",
  },
  {
    p: "CASSIUS",
    l: "To beg enfranchisement for Publius Cimber.",
    acl: "3.1.62",
  },
  {
    p: "CASSIUS",
    l: "I could be well moved, if I were as you:",
    acl: "3.1.63",
  },
  {
    p: "CASSIUS",
    l: "If I could pray to move, prayers would move me:",
    acl: "3.1.64",
  },
  { p: "CASSIUS", l: "But I am constant as the northern star,", acl: "3.1.65" },
  {
    p: "CASSIUS",
    l: "Of whose true-fix'd and resting quality",
    acl: "3.1.66",
  },
  { p: "CASSIUS", l: "There is no fellow in the firmament.", acl: "3.1.67" },
  {
    p: "CASSIUS",
    l: "The skies are painted with unnumber'd sparks,",
    acl: "3.1.68",
  },
  {
    p: "CASSIUS",
    l: "They are all fire and every one doth shine,",
    acl: "3.1.69",
  },
  {
    p: "CASSIUS",
    l: "But there's but one in all doth hold his place:",
    acl: "3.1.70",
  },
  {
    p: "CASSIUS",
    l: "So in the world, 'tis furnish'd well with men,",
    acl: "3.1.71",
  },
  {
    p: "CASSIUS",
    l: "And men are flesh and blood, and apprehensive,",
    acl: "3.1.72",
  },
  { p: "CASSIUS", l: "Yet in the number I do know but one", acl: "3.1.73" },
  {
    p: "CASSIUS",
    l: "That unassailable holds on his rank,",
    acl: "3.1.74",
  },
  { p: "CASSIUS", l: "Unshaked of motion: and that I am he,", acl: "3.1.75" },
  {
    p: "CASSIUS",
    l: "Let me a little show it, even in this,",
    acl: "3.1.76",
  },
  {
    p: "CASSIUS",
    l: "That I was constant Cimber should be banish'd,",
    acl: "3.1.77",
  },
  {
    p: "CASSIUS",
    l: "And constant do remain to keep him so.",
    acl: "3.1.78",
  },
  { p: "CINNA", l: "O Caesar,--", acl: "3.1.79" },
  {
    p: "CAESAR",
    l: "Hence! wilt thou lift up Olympus?",
    acl: "3.1.80",
  },
  { p: "DECIUS BRUTUS", l: "Great Caesar,--", acl: "3.1.81" },
  {
    p: "CAESAR",
    l: "Doth not Brutus bootless kneel?",
    acl: "3.1.82",
  },
  { p: "CASCA", l: "Speak, hands for me!", acl: "3.1.83" },
  {
    p: "CASCA",
    l: "CASCA first, then the other Conspirators and BRUTUS stab CAESAR",
    acl: "",
  },
  { p: "CAESAR", l: "Et tu, Brute! Then fall, Caesar.", acl: "3.1.84" },
  {
    p: "CAESAR",
    l: "Dies",
    acl: "",
  },
  { p: "CINNA", l: "Liberty! Freedom! Tyranny is dead!", acl: "3.1.85" },
  {
    p: "CINNA",
    l: "Run hence, proclaim, cry it about the streets.",
    acl: "3.1.86",
  },
  { p: "CASSIUS", l: "Some to the common pulpits, and cry out", acl: "3.1.87" },
  {
    p: "CASSIUS",
    l: "'Liberty, freedom, and enfranchisement!'",
    acl: "3.1.88",
  },
  { p: "BRUTUS", l: "People and senators, be not affrighted,", acl: "3.1.89" },
  {
    p: "BRUTUS",
    l: "Fly not, stand stiff: ambition's debt is paid.",
    acl: "3.1.90",
  },
  { p: "CASCA", l: "Go to the pulpit, Brutus.", acl: "3.1.91" },
  {
    p: "DECIUS BRUTUS",
    l: "And Cassius too.",
    acl: "3.1.92",
  },
  { p: "BRUTUS", l: "Where's Publius?", acl: "3.1.93" },
  {
    p: "CINNA",
    l: "Here, quite confounded with this mutiny.",
    acl: "3.1.94",
  },
  {
    p: "METELLUS CIMBER",
    l: "Stand fast together, lest some friend of Caesar's",
    acl: "3.1.95",
  },
  { p: "METELLUS CIMBER", l: "Should chance--", acl: "3.1.96" },
  {
    p: "BRUTUS",
    l: "Talk not of standing. Publius, good cheer,",
    acl: "3.1.97",
  },
  {
    p: "BRUTUS",
    l: "There is no harm intended to your person,",
    acl: "3.1.98",
  },
  {
    p: "BRUTUS",
    l: "Nor to no Roman else: so tell them, Publius.",
    acl: "3.1.99",
  },
  {
    p: "CASSIUS",
    l: "And leave us, Publius, lest that the people,",
    acl: "3.1.100",
  },
  {
    p: "CASSIUS",
    l: "Rushing on us, should do your age some mischief.",
    acl: "3.1.101",
  },
  { p: "BRUTUS", l: "Do so: and let no man abide this deed,", acl: "3.1.102" },
  {
    p: "BRUTUS",
    l: "But we the doers.",
    acl: "3.1.103",
  },
  { p: "BRUTUS", l: "Re-enter TREBONIUS", acl: "" },
  {
    p: "CASSIUS",
    l: "Where is Antony?",
    acl: "3.1.104",
  },
  { p: "TREBONIUS", l: "Fled to his house amazed:", acl: "3.1.105" },
  {
    p: "TREBONIUS",
    l: "Men, wives and children stare, cry out and run",
    acl: "3.1.106",
  },
  { p: "TREBONIUS", l: "As it were doomsday.", acl: "3.1.107" },
  {
    p: "BRUTUS",
    l: "Fates, we will know your pleasures:",
    acl: "3.1.108",
  },
  {
    p: "BRUTUS",
    l: "That we shall die, we know, 'tis but the time",
    acl: "3.1.109",
  },
  {
    p: "BRUTUS",
    l: "And drawing days out, that men stand upon.",
    acl: "3.1.110",
  },
  {
    p: "CASSIUS",
    l: "Why, he that cuts off twenty years of life",
    acl: "3.1.111",
  },
  {
    p: "CASSIUS",
    l: "Cuts off so many years of fearing death.",
    acl: "3.1.112",
  },
  {
    p: "BRUTUS",
    l: "Grant that, and then is death a benefit:",
    acl: "3.1.113",
  },
  {
    p: "BRUTUS",
    l: "So are we Caesar's friends, that have abridged",
    acl: "3.1.114",
  },
  {
    p: "BRUTUS",
    l: "His time of fearing death. Stoop, Romans, stoop,",
    acl: "3.1.115",
  },
  {
    p: "BRUTUS",
    l: "And let us bathe our hands in Caesar's blood",
    acl: "3.1.116",
  },
  {
    p: "BRUTUS",
    l: "Up to the elbows, and besmear our swords:",
    acl: "3.1.117",
  },
  {
    p: "BRUTUS",
    l: "Then walk we forth, even to the market-place,",
    acl: "3.1.118",
  },
  {
    p: "BRUTUS",
    l: "And, waving our red weapons o'er our heads,",
    acl: "3.1.119",
  },
  {
    p: "BRUTUS",
    l: "Let's all cry 'Peace, freedom and liberty!'",
    acl: "3.1.120",
  },
  {
    p: "CASSIUS",
    l: "Stoop, then, and wash. How many ages hence",
    acl: "3.1.121",
  },
  {
    p: "CASSIUS",
    l: "Shall this our lofty scene be acted over",
    acl: "3.1.122",
  },
  {
    p: "CASSIUS",
    l: "In states unborn and accents yet unknown!",
    acl: "3.1.123",
  },
  {
    p: "BRUTUS",
    l: "How many times shall Caesar bleed in sport,",
    acl: "3.1.124",
  },
  { p: "BRUTUS", l: "That now on Pompey's basis lies along", acl: "3.1.125" },
  {
    p: "BRUTUS",
    l: "No worthier than the dust!",
    acl: "3.1.126",
  },
  { p: "CASSIUS", l: "So oft as that shall be,", acl: "3.1.127" },
  {
    p: "CASSIUS",
    l: "So often shall the knot of us be call'd",
    acl: "3.1.128",
  },
  {
    p: "CASSIUS",
    l: "The men that gave their country liberty.",
    acl: "3.1.129",
  },
  {
    p: "DECIUS BRUTUS",
    l: "What, shall we forth?",
    acl: "3.1.130",
  },
  { p: "CASSIUS", l: "Ay, every man away:", acl: "3.1.131" },
  {
    p: "CASSIUS",
    l: "Brutus shall lead, and we will grace his heels",
    acl: "3.1.132",
  },
  {
    p: "CASSIUS",
    l: "With the most boldest and best hearts of Rome.",
    acl: "3.1.133",
  },
  {
    p: "CASSIUS",
    l: "Enter a Servant",
    acl: "",
  },
  {
    p: "BRUTUS",
    l: "Soft! who comes here? A friend of Antony's.",
    acl: "3.1.134",
  },
  {
    p: "Servant",
    l: "Thus, Brutus, did my master bid me kneel:",
    acl: "3.1.135",
  },
  { p: "Servant", l: "Thus did Mark Antony bid me fall down,", acl: "3.1.136" },
  {
    p: "Servant",
    l: "And, being prostrate, thus he bade me say:",
    acl: "3.1.137",
  },
  {
    p: "Servant",
    l: "Brutus is noble, wise, valiant, and honest,",
    acl: "3.1.138",
  },
  {
    p: "Servant",
    l: "Caesar was mighty, bold, royal, and loving:",
    acl: "3.1.139",
  },
  { p: "Servant", l: "Say I love Brutus, and I honour him,", acl: "3.1.140" },
  {
    p: "Servant",
    l: "Say I fear'd Caesar, honour'd him and loved him.",
    acl: "3.1.141",
  },
  { p: "Servant", l: "If Brutus will vouchsafe that Antony", acl: "3.1.142" },
  {
    p: "Servant",
    l: "May safely come to him, and be resolved",
    acl: "3.1.143",
  },
  {
    p: "Servant",
    l: "How Caesar hath deserved to lie in death,",
    acl: "3.1.144",
  },
  {
    p: "Servant",
    l: "Mark Antony shall not love Caesar dead",
    acl: "3.1.145",
  },
  {
    p: "Servant",
    l: "So well as Brutus living, but will follow",
    acl: "3.1.146",
  },
  {
    p: "Servant",
    l: "The fortunes and affairs of noble Brutus",
    acl: "3.1.147",
  },
  {
    p: "Servant",
    l: "Thorough the hazards of this untrod state",
    acl: "3.1.148",
  },
  {
    p: "Servant",
    l: "With all true faith. So says my master Antony.",
    acl: "3.1.149",
  },
  { p: "BRUTUS", l: "Thy master is a wise and valiant Roman,", acl: "3.1.150" },
  {
    p: "BRUTUS",
    l: "I never thought him worse.",
    acl: "3.1.151",
  },
  {
    p: "BRUTUS",
    l: "Tell him, so please him come unto this place,",
    acl: "3.1.152",
  },
  {
    p: "BRUTUS",
    l: "He shall be satisfied, and, by my honour,",
    acl: "3.1.153",
  },
  { p: "BRUTUS", l: "Depart untouch'd.", acl: "3.1.154" },
  {
    p: "Servant",
    l: "I'll fetch him presently.",
    acl: "3.1.155",
  },
  { p: "Servant", l: "Exit", acl: "" },
  {
    p: "BRUTUS",
    l: "I know that we shall have him well to friend.",
    acl: "3.1.156",
  },
  { p: "CASSIUS", l: "I wish we may: but yet have I a mind", acl: "3.1.157" },
  {
    p: "CASSIUS",
    l: "That fears him much, and my misgiving still",
    acl: "3.1.158",
  },
  { p: "CASSIUS", l: "Falls shrewdly to the purpose.", acl: "3.1.159" },
  {
    p: "BRUTUS",
    l: "But here comes Antony.",
    acl: "3.1.160",
  },
  { p: "BRUTUS", l: "Re-enter ANTONY", acl: "" },
  {
    p: "BRUTUS",
    l: "Welcome, Mark Antony.",
    acl: "3.1.161",
  },
  { p: "ANTONY", l: "O mighty Caesar! dost thou lie so low?", acl: "3.1.162" },
  {
    p: "ANTONY",
    l: "Are all thy conquests, glories, triumphs, spoils,",
    acl: "3.1.163",
  },
  {
    p: "ANTONY",
    l: "Shrunk to this little measure? Fare thee well.",
    acl: "3.1.164",
  },
  {
    p: "ANTONY",
    l: "I know not, gentlemen, what you intend,",
    acl: "3.1.165",
  },
  {
    p: "ANTONY",
    l: "Who else must be let blood, who else is rank:",
    acl: "3.1.166",
  },
  {
    p: "ANTONY",
    l: "If I myself, there is no hour so fit",
    acl: "3.1.167",
  },
  {
    p: "ANTONY",
    l: "As Caesar's death hour, nor no instrument",
    acl: "3.1.168",
  },
  {
    p: "ANTONY",
    l: "Of half that worth as those your swords, made rich",
    acl: "3.1.169",
  },
  {
    p: "ANTONY",
    l: "With the most noble blood of all this world.",
    acl: "3.1.170",
  },
  {
    p: "ANTONY",
    l: "I do beseech ye, if you bear me hard,",
    acl: "3.1.171",
  },
  {
    p: "ANTONY",
    l: "Now, whilst your purpled hands do reek and smoke,",
    acl: "3.1.172",
  },
  {
    p: "ANTONY",
    l: "Fulfil your pleasure. Live a thousand years,",
    acl: "3.1.173",
  },
  { p: "ANTONY", l: "I shall not find myself so apt to die:", acl: "3.1.174" },
  {
    p: "ANTONY",
    l: "No place will please me so, no mean of death,",
    acl: "3.1.175",
  },
  { p: "ANTONY", l: "As here by Caesar, and by you cut off,", acl: "3.1.176" },
  {
    p: "ANTONY",
    l: "The choice and master spirits of this age.",
    acl: "3.1.177",
  },
  { p: "BRUTUS", l: "O Antony, beg not your death of us.", acl: "3.1.178" },
  {
    p: "BRUTUS",
    l: "Though now we must appear bloody and cruel,",
    acl: "3.1.179",
  },
  {
    p: "BRUTUS",
    l: "As, by our hands and this our present act,",
    acl: "3.1.180",
  },
  {
    p: "BRUTUS",
    l: "You see we do, yet see you but our hands",
    acl: "3.1.181",
  },
  {
    p: "BRUTUS",
    l: "And this the bleeding business they have done:",
    acl: "3.1.182",
  },
  {
    p: "BRUTUS",
    l: "Our hearts you see not, they are pitiful,",
    acl: "3.1.183",
  },
  { p: "BRUTUS", l: "And pity to the general wrong of Rome--", acl: "3.1.184" },
  {
    p: "BRUTUS",
    l: "As fire drives out fire, so pity pity--",
    acl: "3.1.185",
  },
  {
    p: "BRUTUS",
    l: "Hath done this deed on Caesar. For your part,",
    acl: "3.1.186",
  },
  {
    p: "BRUTUS",
    l: "To you our swords have leaden points, Mark Antony:",
    acl: "3.1.187",
  },
  {
    p: "BRUTUS",
    l: "Our arms, in strength of malice, and our hearts",
    acl: "3.1.188",
  },
  {
    p: "BRUTUS",
    l: "Of brothers' temper, do receive you in",
    acl: "3.1.189",
  },
  {
    p: "BRUTUS",
    l: "With all kind love, good thoughts, and reverence.",
    acl: "3.1.190",
  },
  {
    p: "CASSIUS",
    l: "Your voice shall be as strong as any man's",
    acl: "3.1.191",
  },
  { p: "CASSIUS", l: "In the disposing of new dignities.", acl: "3.1.192" },
  {
    p: "BRUTUS",
    l: "Only be patient till we have appeased",
    acl: "3.1.193",
  },
  {
    p: "BRUTUS",
    l: "The multitude, beside themselves with fear,",
    acl: "3.1.194",
  },
  {
    p: "BRUTUS",
    l: "And then we will deliver you the cause,",
    acl: "3.1.195",
  },
  {
    p: "BRUTUS",
    l: "Why I, that did love Caesar when I struck him,",
    acl: "3.1.196",
  },
  {
    p: "BRUTUS",
    l: "Have thus proceeded.",
    acl: "3.1.197",
  },
  { p: "ANTONY", l: "I doubt not of your wisdom.", acl: "3.1.198" },
  {
    p: "ANTONY",
    l: "Let each man render me his bloody hand:",
    acl: "3.1.199",
  },
  {
    p: "ANTONY",
    l: "First, Marcus Brutus, will I shake with you,",
    acl: "3.1.200",
  },
  {
    p: "ANTONY",
    l: "Next, Caius Cassius, do I take your hand,",
    acl: "3.1.201",
  },
  {
    p: "ANTONY",
    l: "Now, Decius Brutus, yours: now yours, Metellus,",
    acl: "3.1.202",
  },
  {
    p: "ANTONY",
    l: "Yours, Cinna, and, my valiant Casca, yours,",
    acl: "3.1.203",
  },
  {
    p: "ANTONY",
    l: "Though last, not last in love, yours, good Trebonius.",
    acl: "3.1.204",
  },
  {
    p: "ANTONY",
    l: "Gentlemen all,--alas, what shall I say?",
    acl: "3.1.205",
  },
  {
    p: "ANTONY",
    l: "My credit now stands on such slippery ground,",
    acl: "3.1.206",
  },
  {
    p: "ANTONY",
    l: "That one of two bad ways you must conceit me,",
    acl: "3.1.207",
  },
  { p: "ANTONY", l: "Either a coward or a flatterer.", acl: "3.1.208" },
  {
    p: "ANTONY",
    l: "That I did love thee, Caesar, O, 'tis true:",
    acl: "3.1.209",
  },
  { p: "ANTONY", l: "If then thy spirit look upon us now,", acl: "3.1.210" },
  {
    p: "ANTONY",
    l: "Shall it not grieve thee dearer than thy death,",
    acl: "3.1.211",
  },
  {
    p: "ANTONY",
    l: "To see thy thy Anthony making his peace,",
    acl: "3.1.212",
  },
  {
    p: "ANTONY",
    l: "Shaking the bloody fingers of thy foes,",
    acl: "3.1.213",
  },
  {
    p: "ANTONY",
    l: "Most noble! in the presence of thy corse?",
    acl: "3.1.214",
  },
  {
    p: "ANTONY",
    l: "Had I as many eyes as thou hast wounds,",
    acl: "3.1.215",
  },
  {
    p: "ANTONY",
    l: "Weeping as fast as they stream forth thy blood,",
    acl: "3.1.216",
  },
  {
    p: "ANTONY",
    l: "It would become me better than to close",
    acl: "3.1.217",
  },
  {
    p: "ANTONY",
    l: "In terms of friendship with thine enemies.",
    acl: "3.1.218",
  },
  {
    p: "ANTONY",
    l: "Pardon me, Julius! Here wast thou bay'd, brave hart,",
    acl: "3.1.219",
  },
  {
    p: "ANTONY",
    l: "Here didst thou fall, and here thy hunters stand,",
    acl: "3.1.220",
  },
  {
    p: "ANTONY",
    l: "Sign'd in thy spoil, and crimson'd in thy lethe.",
    acl: "3.1.221",
  },
  {
    p: "ANTONY",
    l: "O world, thou wast the forest to this hart,",
    acl: "3.1.222",
  },
  {
    p: "ANTONY",
    l: "And this, indeed, O world, the heart of thee.",
    acl: "3.1.223",
  },
  {
    p: "ANTONY",
    l: "How like a deer, strucken by many princes,",
    acl: "3.1.224",
  },
  {
    p: "ANTONY",
    l: "Dost thou here lie!",
    acl: "3.1.225",
  },
  { p: "CASSIUS", l: "Mark Antony,--", acl: "3.1.226" },
  {
    p: "ANTONY",
    l: "Pardon me, Caius Cassius:",
    acl: "3.1.227",
  },
  { p: "ANTONY", l: "The enemies of Caesar shall say this,", acl: "3.1.228" },
  {
    p: "ANTONY",
    l: "Then, in a friend, it is cold modesty.",
    acl: "3.1.229",
  },
  {
    p: "CASSIUS",
    l: "I blame you not for praising Caesar so,",
    acl: "3.1.230",
  },
  {
    p: "CASSIUS",
    l: "But what compact mean you to have with us?",
    acl: "3.1.231",
  },
  {
    p: "CASSIUS",
    l: "Will you be prick'd in number of our friends,",
    acl: "3.1.232",
  },
  {
    p: "CASSIUS",
    l: "Or shall we on, and not depend on you?",
    acl: "3.1.233",
  },
  {
    p: "ANTONY",
    l: "Therefore I took your hands, but was, indeed,",
    acl: "3.1.234",
  },
  {
    p: "ANTONY",
    l: "Sway'd from the point, by looking down on Caesar.",
    acl: "3.1.235",
  },
  {
    p: "ANTONY",
    l: "Friends am I with you all and love you all,",
    acl: "3.1.236",
  },
  {
    p: "ANTONY",
    l: "Upon this hope, that you shall give me reasons",
    acl: "3.1.237",
  },
  { p: "ANTONY", l: "Why and wherein Caesar was dangerous.", acl: "3.1.238" },
  {
    p: "BRUTUS",
    l: "Or else were this a savage spectacle:",
    acl: "3.1.239",
  },
  { p: "BRUTUS", l: "Our reasons are so full of good regard", acl: "3.1.240" },
  {
    p: "BRUTUS",
    l: "That were you, Antony, the son of Caesar,",
    acl: "3.1.241",
  },
  { p: "BRUTUS", l: "You should be satisfied.", acl: "3.1.242" },
  {
    p: "ANTONY",
    l: "That's all I seek:",
    acl: "3.1.243",
  },
  { p: "ANTONY", l: "And am moreover suitor that I may", acl: "3.1.244" },
  {
    p: "ANTONY",
    l: "Produce his body to the market-place,",
    acl: "3.1.245",
  },
  { p: "ANTONY", l: "And in the pulpit, as becomes a friend,", acl: "3.1.246" },
  {
    p: "ANTONY",
    l: "Speak in the order of his funeral.",
    acl: "3.1.247",
  },
  { p: "BRUTUS", l: "You shall, Mark Antony.", acl: "3.1.248" },
  {
    p: "CASSIUS",
    l: "Brutus, a word with you.",
    acl: "3.1.249",
  },
  { p: "CASSIUS", l: "Aside to BRUTUS", acl: "" },
  {
    p: "CASSIUS",
    l: "You know not what you do: do not consent",
    acl: "3.1.250",
  },
  { p: "CASSIUS", l: "That Antony speak in his funeral:", acl: "3.1.251" },
  {
    p: "CASSIUS",
    l: "Know you how much the people may be moved",
    acl: "3.1.252",
  },
  { p: "CASSIUS", l: "By that which he will utter?", acl: "3.1.253" },
  {
    p: "BRUTUS",
    l: "By your pardon,",
    acl: "3.1.254",
  },
  { p: "BRUTUS", l: "I will myself into the pulpit first,", acl: "3.1.255" },
  {
    p: "BRUTUS",
    l: "And show the reason of our Caesar's death:",
    acl: "3.1.256",
  },
  { p: "BRUTUS", l: "What Antony shall speak, I will protest", acl: "3.1.257" },
  {
    p: "BRUTUS",
    l: "He speaks by leave and by permission,",
    acl: "3.1.258",
  },
  { p: "BRUTUS", l: "And that we are contented Caesar shall", acl: "3.1.259" },
  {
    p: "BRUTUS",
    l: "Have all true rites and lawful ceremonies.",
    acl: "3.1.260",
  },
  {
    p: "BRUTUS",
    l: "It shall advantage more than do us wrong.",
    acl: "3.1.261",
  },
  {
    p: "CASSIUS",
    l: "I know not what may fall, I like it not.",
    acl: "3.1.262",
  },
  {
    p: "BRUTUS",
    l: "Mark Antony, here, take you Caesar's body.",
    acl: "3.1.263",
  },
  {
    p: "BRUTUS",
    l: "You shall not in your funeral speech blame us,",
    acl: "3.1.264",
  },
  {
    p: "BRUTUS",
    l: "But speak all good you can devise of Caesar,",
    acl: "3.1.265",
  },
  {
    p: "BRUTUS",
    l: "And say you do't by our permission,",
    acl: "3.1.266",
  },
  { p: "BRUTUS", l: "Else shall you not have any hand at all", acl: "3.1.267" },
  {
    p: "BRUTUS",
    l: "About his funeral: and you shall speak",
    acl: "3.1.268",
  },
  { p: "BRUTUS", l: "In the same pulpit whereto I am going,", acl: "3.1.269" },
  {
    p: "BRUTUS",
    l: "After my speech is ended.",
    acl: "3.1.270",
  },
  { p: "ANTONY", l: "Be it so.", acl: "3.1.271" },
  {
    p: "ANTONY",
    l: "I do desire no more.",
    acl: "3.1.272",
  },
  { p: "BRUTUS", l: "Prepare the body then, and follow us.", acl: "3.1.273" },
  {
    p: "BRUTUS",
    l: "Exeunt all but ANTONY",
    acl: "",
  },
  {
    p: "ANTONY",
    l: "O, pardon me, thou bleeding piece of earth,",
    acl: "3.1.274",
  },
  {
    p: "ANTONY",
    l: "That I am meek and gentle with these butchers!",
    acl: "3.1.275",
  },
  { p: "ANTONY", l: "Thou art the ruins of the noblest man", acl: "3.1.276" },
  {
    p: "ANTONY",
    l: "That ever lived in the tide of times.",
    acl: "3.1.277",
  },
  {
    p: "ANTONY",
    l: "Woe to the hand that shed this costly blood!",
    acl: "3.1.278",
  },
  {
    p: "ANTONY",
    l: "Over thy wounds now do I prophesy,--",
    acl: "3.1.279",
  },
  {
    p: "ANTONY",
    l: "Which, like dumb mouths, do ope their ruby lips,",
    acl: "3.1.280",
  },
  {
    p: "ANTONY",
    l: "To beg the voice and utterance of my tongue--",
    acl: "3.1.281",
  },
  {
    p: "ANTONY",
    l: "A curse shall light upon the limbs of men,",
    acl: "3.1.282",
  },
  {
    p: "ANTONY",
    l: "Domestic fury and fierce civil strife",
    acl: "3.1.283",
  },
  { p: "ANTONY", l: "Shall cumber all the parts of Italy,", acl: "3.1.284" },
  {
    p: "ANTONY",
    l: "Blood and destruction shall be so in use",
    acl: "3.1.285",
  },
  { p: "ANTONY", l: "And dreadful objects so familiar", acl: "3.1.286" },
  {
    p: "ANTONY",
    l: "That mothers shall but smile when they behold",
    acl: "3.1.287",
  },
  {
    p: "ANTONY",
    l: "Their infants quarter'd with the hands of war,",
    acl: "3.1.288",
  },
  {
    p: "ANTONY",
    l: "All pity choked with custom of fell deeds:",
    acl: "3.1.289",
  },
  {
    p: "ANTONY",
    l: "And Caesar's spirit, ranging for revenge,",
    acl: "3.1.290",
  },
  {
    p: "ANTONY",
    l: "With Ate by his side come hot from hell,",
    acl: "3.1.291",
  },
  {
    p: "ANTONY",
    l: "Shall in these confines with a monarch's voice",
    acl: "3.1.292",
  },
  {
    p: "ANTONY",
    l: "Cry  'Havoc,' and let slip the dogs of war,",
    acl: "3.1.293",
  },
  {
    p: "ANTONY",
    l: "That this foul deed shall smell above the earth",
    acl: "3.1.294",
  },
  {
    p: "ANTONY",
    l: "With carrion men, groaning for burial.",
    acl: "3.1.295",
  },
  { p: "ANTONY", l: "Enter a Servant", acl: "" },
  {
    p: "ANTONY",
    l: "You serve Octavius Caesar, do you not?",
    acl: "3.1.296",
  },
  { p: "Servant", l: "I do, Mark Antony.", acl: "3.1.297" },
  {
    p: "ANTONY",
    l: "Caesar did write for him to come to Rome.",
    acl: "3.1.298",
  },
  {
    p: "Servant",
    l: "He did receive his letters, and is coming,",
    acl: "3.1.299",
  },
  {
    p: "Servant",
    l: "And bid me say to you by word of mouth--",
    acl: "3.1.300",
  },
  { p: "Servant", l: "O Caesar!--", acl: "3.1.301" },
  {
    p: "Servant",
    l: "Seeing the body",
    acl: "",
  },
  {
    p: "ANTONY",
    l: "Thy heart is big, get thee apart and weep.",
    acl: "3.1.302",
  },
  {
    p: "ANTONY",
    l: "Passion, I see, is catching, for mine eyes,",
    acl: "3.1.303",
  },
  {
    p: "ANTONY",
    l: "Seeing those beads of sorrow stand in thine,",
    acl: "3.1.304",
  },
  {
    p: "ANTONY",
    l: "Began to water. Is thy master coming?",
    acl: "3.1.305",
  },
  {
    p: "Servant",
    l: "He lies to-night within seven leagues of Rome.",
    acl: "3.1.306",
  },
  {
    p: "ANTONY",
    l: "Post back with speed, and tell him what hath chanced:",
    acl: "3.1.307",
  },
  {
    p: "ANTONY",
    l: "Here is a mourning Rome, a dangerous Rome,",
    acl: "3.1.308",
  },
  {
    p: "ANTONY",
    l: "No Rome of safety for Octavius yet,",
    acl: "3.1.309",
  },
  {
    p: "ANTONY",
    l: "Hie hence, and tell him so. Yet, stay awhile,",
    acl: "3.1.310",
  },
  {
    p: "ANTONY",
    l: "Thou shalt not back till I have borne this corse",
    acl: "3.1.311",
  },
  {
    p: "ANTONY",
    l: "Into the market-place: there shall I try",
    acl: "3.1.312",
  },
  {
    p: "ANTONY",
    l: "In my oration, how the people take",
    acl: "3.1.313",
  },
  { p: "ANTONY", l: "The cruel issue of these bloody men,", acl: "3.1.314" },
  {
    p: "ANTONY",
    l: "According to the which, thou shalt discourse",
    acl: "3.1.315",
  },
  {
    p: "ANTONY",
    l: "To young Octavius of the state of things.",
    acl: "3.1.316",
  },
  {
    p: "ANTONY",
    l: "Lend me your hand.",
    acl: "3.1.317",
  },
  { p: "ANTONY", l: "Exeunt with CAESAR's body", acl: "" },
  {
    p: "ANTONY",
    l: "SCENE II. The Forum.",
    acl: "",
  },
  {
    p: "ANTONY",
    l: "Enter BRUTUS and CASSIUS, and a throng of Citizens",
    acl: "",
  },
  {
    p: "Citizens",
    l: "We will be satisfied, let us be satisfied.",
    acl: "3.2.1",
  },
  {
    p: "BRUTUS",
    l: "Then follow me, and give me audience, friends.",
    acl: "3.2.2",
  },
  {
    p: "BRUTUS",
    l: "Cassius, go you into the other street,",
    acl: "3.2.3",
  },
  { p: "BRUTUS", l: "And part the numbers.", acl: "3.2.4" },
  {
    p: "BRUTUS",
    l: "Those that will hear me speak, let 'em stay here,",
    acl: "3.2.5",
  },
  {
    p: "BRUTUS",
    l: "Those that will follow Cassius, go with him,",
    acl: "3.2.6",
  },
  {
    p: "BRUTUS",
    l: "And public reasons shall be rendered",
    acl: "3.2.7",
  },
  { p: "BRUTUS", l: "Of Caesar's death.", acl: "3.2.8" },
  {
    p: "First Citizen",
    l: "I will hear Brutus speak.",
    acl: "3.2.9",
  },
  {
    p: "Second Citizen",
    l: "I will hear Cassius, and compare their reasons,",
    acl: "3.2.10",
  },
  {
    p: "Second Citizen",
    l: "When severally we hear them rendered.",
    acl: "3.2.11",
  },
  {
    p: "Second Citizen",
    l: "Exit CASSIUS, with some of the Citizens. BRUTUS goes into the pulpit",
    acl: "",
  },
  {
    p: "Third Citizen",
    l: "The noble Brutus is ascended: silence!",
    acl: "3.2.12",
  },
  {
    p: "BRUTUS",
    l: "Be patient till the last.",
    acl: "3.2.13",
  },
  {
    p: "BRUTUS",
    l: "Romans, countrymen, and lovers! hear me for my",
    acl: "3.2.14",
  },
  {
    p: "BRUTUS",
    l: "cause, and be silent, that you may hear: believe me",
    acl: "3.2.15",
  },
  {
    p: "BRUTUS",
    l: "for mine honour, and have respect to mine honour, that",
    acl: "3.2.16",
  },
  {
    p: "BRUTUS",
    l: "you may believe: censure me in your wisdom, and",
    acl: "3.2.17",
  },
  {
    p: "BRUTUS",
    l: "awake your senses, that you may the better judge.",
    acl: "3.2.18",
  },
  {
    p: "BRUTUS",
    l: "If there be any in this assembly, any dear friend of",
    acl: "3.2.19",
  },
  {
    p: "BRUTUS",
    l: "Caesar's, to him I say, that Brutus' love to Caesar",
    acl: "3.2.20",
  },
  {
    p: "BRUTUS",
    l: "was no less than his. If then that friend demand",
    acl: "3.2.21",
  },
  {
    p: "BRUTUS",
    l: "why Brutus rose against Caesar, this is my answer:",
    acl: "3.2.22",
  },
  {
    p: "BRUTUS",
    l: "--Not that I loved Caesar less, but that I loved",
    acl: "3.2.23",
  },
  {
    p: "BRUTUS",
    l: "Rome more. Had you rather Caesar were living and",
    acl: "3.2.24",
  },
  {
    p: "BRUTUS",
    l: "die all slaves, than that Caesar were dead, to live",
    acl: "3.2.25",
  },
  {
    p: "BRUTUS",
    l: "all free men? As Caesar loved me, I weep for him,",
    acl: "3.2.26",
  },
  {
    p: "BRUTUS",
    l: "as he was fortunate, I rejoice at it, as he was",
    acl: "3.2.27",
  },
  {
    p: "BRUTUS",
    l: "valiant, I honour him: but, as he was ambitious, I",
    acl: "3.2.28",
  },
  {
    p: "BRUTUS",
    l: "slew him. There is tears for his love, joy for his",
    acl: "3.2.29",
  },
  {
    p: "BRUTUS",
    l: "fortune, honour for his valour, and death for his",
    acl: "3.2.30",
  },
  {
    p: "BRUTUS",
    l: "ambition. Who is here so base that would be a",
    acl: "3.2.31",
  },
  {
    p: "BRUTUS",
    l: "bondman? If any, speak, for him have I offended.",
    acl: "3.2.32",
  },
  {
    p: "BRUTUS",
    l: "Who is here so rude that would not be a Roman? If",
    acl: "3.2.33",
  },
  {
    p: "BRUTUS",
    l: "any, speak, for him have I offended. Who is here so",
    acl: "3.2.34",
  },
  {
    p: "BRUTUS",
    l: "vile that will not love his country? If any, speak,",
    acl: "3.2.35",
  },
  {
    p: "BRUTUS",
    l: "for him have I offended. I pause for a reply.",
    acl: "3.2.36",
  },
  {
    p: "All",
    l: "None, Brutus, none.",
    acl: "3.2.37",
  },
  {
    p: "BRUTUS",
    l: "Then none have I offended. I have done no more to",
    acl: "3.2.38",
  },
  {
    p: "BRUTUS",
    l: "Caesar than you shall do to Brutus. The question of",
    acl: "3.2.39",
  },
  {
    p: "BRUTUS",
    l: "his death is enrolled in the Capitol, his glory not",
    acl: "3.2.40",
  },
  {
    p: "BRUTUS",
    l: "extenuated, wherein he was worthy, nor his offences",
    acl: "3.2.41",
  },
  { p: "BRUTUS", l: "enforced, for which he suffered death.", acl: "3.2.42" },
  {
    p: "BRUTUS",
    l: "Enter ANTONY and others, with CAESAR's body",
    acl: "",
  },
  {
    p: "BRUTUS",
    l: "Here comes his body, mourned by Mark Antony: who,",
    acl: "3.2.43",
  },
  {
    p: "BRUTUS",
    l: "though he had no hand in his death, shall receive",
    acl: "3.2.44",
  },
  { p: "BRUTUS", l: "the benefit of his dying, a place in the", acl: "3.2.45" },
  {
    p: "BRUTUS",
    l: "commonwealth, as which of you shall not? With this",
    acl: "3.2.46",
  },
  {
    p: "BRUTUS",
    l: "I depart,--that, as I slew my best lover for the",
    acl: "3.2.47",
  },
  {
    p: "BRUTUS",
    l: "good of Rome, I have the same dagger for myself,",
    acl: "3.2.48",
  },
  {
    p: "BRUTUS",
    l: "when it shall please my country to need my death.",
    acl: "3.2.49",
  },
  {
    p: "All",
    l: "Live, Brutus! live, live!",
    acl: "3.2.50",
  },
  {
    p: "First Citizen",
    l: "Bring him with triumph home unto his house.",
    acl: "3.2.51",
  },
  {
    p: "Second Citizen",
    l: "Give him a statue with his ancestors.",
    acl: "3.2.52",
  },
  { p: "Third Citizen", l: "Let him be Caesar.", acl: "3.2.53" },
  {
    p: "Fourth Citizen",
    l: "Caesar's better parts",
    acl: "3.2.54",
  },
  { p: "Fourth Citizen", l: "Shall be crown'd in Brutus.", acl: "3.2.55" },
  {
    p: "First Citizen",
    l: "We'll bring him to his house",
    acl: "3.2.56",
  },
  { p: "First Citizen", l: "With shouts and clamours.", acl: "3.2.57" },
  {
    p: "BRUTUS",
    l: "My countrymen,--",
    acl: "3.2.58",
  },
  { p: "Second Citizen", l: "Peace, silence! Brutus speaks.", acl: "3.2.59" },
  {
    p: "First Citizen",
    l: "Peace, ho!",
    acl: "3.2.60",
  },
  { p: "BRUTUS", l: "Good countrymen, let me depart alone,", acl: "3.2.61" },
  {
    p: "BRUTUS",
    l: "And, for my sake, stay here with Antony:",
    acl: "3.2.62",
  },
  {
    p: "BRUTUS",
    l: "Do grace to Caesar's corpse, and grace his speech",
    acl: "3.2.63",
  },
  {
    p: "BRUTUS",
    l: "Tending to Caesar's glories, which Mark Antony,",
    acl: "3.2.64",
  },
  { p: "BRUTUS", l: "By our permission, is allow'd to make.", acl: "3.2.65" },
  {
    p: "BRUTUS",
    l: "I do entreat you, not a man depart,",
    acl: "3.2.66",
  },
  { p: "BRUTUS", l: "Save I alone, till Antony have spoke.", acl: "3.2.67" },
  {
    p: "BRUTUS",
    l: "Exit",
    acl: "",
  },
  {
    p: "First Citizen",
    l: "Stay, ho! and let us hear Mark Antony.",
    acl: "3.2.68",
  },
  {
    p: "Third Citizen",
    l: "Let him go up into the public chair,",
    acl: "3.2.69",
  },
  {
    p: "Third Citizen",
    l: "We'll hear him. Noble Antony, go up.",
    acl: "3.2.70",
  },
  {
    p: "ANTONY",
    l: "For Brutus' sake, I am beholding to you.",
    acl: "3.2.71",
  },
  { p: "ANTONY", l: "Goes into the pulpit", acl: "" },
  {
    p: "Fourth Citizen",
    l: "What does he say of Brutus?",
    acl: "3.2.72",
  },
  { p: "Third Citizen", l: "He says, for Brutus' sake,", acl: "3.2.73" },
  {
    p: "Third Citizen",
    l: "He finds himself beholding to us all.",
    acl: "3.2.74",
  },
  {
    p: "Fourth Citizen",
    l: "'Twere best he speak no harm of Brutus here.",
    acl: "3.2.75",
  },
  {
    p: "First Citizen",
    l: "This Caesar was a tyrant.",
    acl: "3.2.76",
  },
  { p: "Third Citizen", l: "Nay, that's certain:", acl: "3.2.77" },
  {
    p: "Third Citizen",
    l: "We are blest that Rome is rid of him.",
    acl: "3.2.78",
  },
  {
    p: "Second Citizen",
    l: "Peace! let us hear what Antony can say.",
    acl: "3.2.79",
  },
  {
    p: "ANTONY",
    l: "You gentle Romans,--",
    acl: "3.2.80",
  },
  { p: "Citizens", l: "Peace, ho! let us hear him.", acl: "3.2.81" },
  {
    p: "ANTONY",
    l: "Friends, Romans, countrymen, lend me your ears,",
    acl: "3.2.82",
  },
  {
    p: "ANTONY",
    l: "I come to bury Caesar, not to praise him.",
    acl: "3.2.83",
  },
  {
    p: "ANTONY",
    l: "The evil that men do lives after them,",
    acl: "3.2.84",
  },
  {
    p: "ANTONY",
    l: "The good is oft interred with their bones,",
    acl: "3.2.85",
  },
  {
    p: "ANTONY",
    l: "So let it be with Caesar. The noble Brutus",
    acl: "3.2.86",
  },
  { p: "ANTONY", l: "Hath told you Caesar was ambitious:", acl: "3.2.87" },
  {
    p: "ANTONY",
    l: "If it were so, it was a grievous fault,",
    acl: "3.2.88",
  },
  { p: "ANTONY", l: "And grievously hath Caesar answer'd it.", acl: "3.2.89" },
  {
    p: "ANTONY",
    l: "Here, under leave of Brutus and the rest--",
    acl: "3.2.90",
  },
  { p: "ANTONY", l: "For Brutus is an honourable man,", acl: "3.2.91" },
  {
    p: "ANTONY",
    l: "So are they all, all honourable men--",
    acl: "3.2.92",
  },
  { p: "ANTONY", l: "Come I to speak in Caesar's funeral.", acl: "3.2.93" },
  {
    p: "ANTONY",
    l: "He was my friend, faithful and just to me:",
    acl: "3.2.94",
  },
  { p: "ANTONY", l: "But Brutus says he was ambitious,", acl: "3.2.95" },
  {
    p: "ANTONY",
    l: "And Brutus is an honourable man.",
    acl: "3.2.96",
  },
  {
    p: "ANTONY",
    l: "He hath brought many captives home to Rome",
    acl: "3.2.97",
  },
  {
    p: "ANTONY",
    l: "Whose ransoms did the general coffers fill:",
    acl: "3.2.98",
  },
  { p: "ANTONY", l: "Did this in Caesar seem ambitious?", acl: "3.2.99" },
  {
    p: "ANTONY",
    l: "When that the poor have cried, Caesar hath wept:",
    acl: "3.2.100",
  },
  {
    p: "ANTONY",
    l: "Ambition should be made of sterner stuff:",
    acl: "3.2.101",
  },
  {
    p: "ANTONY",
    l: "Yet Brutus says he was ambitious,",
    acl: "3.2.102",
  },
  { p: "ANTONY", l: "And Brutus is an honourable man.", acl: "3.2.103" },
  {
    p: "ANTONY",
    l: "You all did see that on the Lupercal",
    acl: "3.2.104",
  },
  { p: "ANTONY", l: "I thrice presented him a kingly crown,", acl: "3.2.105" },
  {
    p: "ANTONY",
    l: "Which he did thrice refuse: was this ambition?",
    acl: "3.2.106",
  },
  { p: "ANTONY", l: "Yet Brutus says he was ambitious,", acl: "3.2.107" },
  {
    p: "ANTONY",
    l: "And, sure, he is an honourable man.",
    acl: "3.2.108",
  },
  {
    p: "ANTONY",
    l: "I speak not to disprove what Brutus spoke,",
    acl: "3.2.109",
  },
  {
    p: "ANTONY",
    l: "But here I am to speak what I do know.",
    acl: "3.2.110",
  },
  {
    p: "ANTONY",
    l: "You all did love him once, not without cause:",
    acl: "3.2.111",
  },
  {
    p: "ANTONY",
    l: "What cause withholds you then, to mourn for him?",
    acl: "3.2.112",
  },
  {
    p: "ANTONY",
    l: "O judgment! thou art fled to brutish beasts,",
    acl: "3.2.113",
  },
  {
    p: "ANTONY",
    l: "And men have lost their reason. Bear with me,",
    acl: "3.2.114",
  },
  {
    p: "ANTONY",
    l: "My heart is in the coffin there with Caesar,",
    acl: "3.2.115",
  },
  {
    p: "ANTONY",
    l: "And I must pause till it come back to me.",
    acl: "3.2.116",
  },
  {
    p: "First Citizen",
    l: "Methinks there is much reason in his sayings.",
    acl: "3.2.117",
  },
  {
    p: "Second Citizen",
    l: "If thou consider rightly of the matter,",
    acl: "3.2.118",
  },
  {
    p: "Second Citizen",
    l: "Caesar has had great wrong.",
    acl: "3.2.119",
  },
  { p: "Third Citizen", l: "Has he, masters?", acl: "3.2.120" },
  {
    p: "Third Citizen",
    l: "I fear there will a worse come in his place.",
    acl: "3.2.121",
  },
  {
    p: "Fourth Citizen",
    l: "Mark'd ye his words? He would not take the crown,",
    acl: "3.2.122",
  },
  {
    p: "Fourth Citizen",
    l: "Therefore 'tis certain he was not ambitious.",
    acl: "3.2.123",
  },
  {
    p: "First Citizen",
    l: "If it be found so, some will dear abide it.",
    acl: "3.2.124",
  },
  {
    p: "Second Citizen",
    l: "Poor soul! his eyes are red as fire with weeping.",
    acl: "3.2.125",
  },
  {
    p: "Third Citizen",
    l: "There's not a nobler man in Rome than Antony.",
    acl: "3.2.126",
  },
  {
    p: "Fourth Citizen",
    l: "Now mark him, he begins again to speak.",
    acl: "3.2.127",
  },
  {
    p: "ANTONY",
    l: "But yesterday the word of Caesar might",
    acl: "3.2.128",
  },
  {
    p: "ANTONY",
    l: "Have stood against the world, now lies he there.",
    acl: "3.2.129",
  },
  {
    p: "ANTONY",
    l: "And none so poor to do him reverence.",
    acl: "3.2.130",
  },
  { p: "ANTONY", l: "O masters, if I were disposed to stir", acl: "3.2.131" },
  {
    p: "ANTONY",
    l: "Your hearts and minds to mutiny and rage,",
    acl: "3.2.132",
  },
  {
    p: "ANTONY",
    l: "I should do Brutus wrong, and Cassius wrong,",
    acl: "3.2.133",
  },
  {
    p: "ANTONY",
    l: "Who, you all know, are honourable men:",
    acl: "3.2.134",
  },
  {
    p: "ANTONY",
    l: "I will not do them wrong, I rather choose",
    acl: "3.2.135",
  },
  {
    p: "ANTONY",
    l: "To wrong the dead, to wrong myself and you,",
    acl: "3.2.136",
  },
  { p: "ANTONY", l: "Than I will wrong such honourable men.", acl: "3.2.137" },
  {
    p: "ANTONY",
    l: "But here's a parchment with the seal of Caesar,",
    acl: "3.2.138",
  },
  {
    p: "ANTONY",
    l: "I found it in his closet, 'tis his will:",
    acl: "3.2.139",
  },
  {
    p: "ANTONY",
    l: "Let but the commons hear this testament--",
    acl: "3.2.140",
  },
  {
    p: "ANTONY",
    l: "Which, pardon me, I do not mean to read--",
    acl: "3.2.141",
  },
  {
    p: "ANTONY",
    l: "And they would go and kiss dead Caesar's wounds",
    acl: "3.2.142",
  },
  {
    p: "ANTONY",
    l: "And dip their napkins in his sacred blood,",
    acl: "3.2.143",
  },
  {
    p: "ANTONY",
    l: "Yea, beg a hair of him for memory,",
    acl: "3.2.144",
  },
  {
    p: "ANTONY",
    l: "And, dying, mention it within their wills,",
    acl: "3.2.145",
  },
  {
    p: "ANTONY",
    l: "Bequeathing it as a rich legacy",
    acl: "3.2.146",
  },
  { p: "ANTONY", l: "Unto their issue.", acl: "3.2.147" },
  {
    p: "Fourth Citizen",
    l: "We'll hear the will: read it, Mark Antony.",
    acl: "3.2.148",
  },
  {
    p: "All",
    l: "The will, the will! we will hear Caesar's will.",
    acl: "3.2.149",
  },
  {
    p: "ANTONY",
    l: "Have patience, gentle friends, I must not read it,",
    acl: "3.2.150",
  },
  {
    p: "ANTONY",
    l: "It is not meet you know how Caesar loved you.",
    acl: "3.2.151",
  },
  {
    p: "ANTONY",
    l: "You are not wood, you are not stones, but men,",
    acl: "3.2.152",
  },
  {
    p: "ANTONY",
    l: "And, being men, bearing the will of Caesar,",
    acl: "3.2.153",
  },
  {
    p: "ANTONY",
    l: "It will inflame you, it will make you mad:",
    acl: "3.2.154",
  },
  {
    p: "ANTONY",
    l: "'Tis good you know not that you are his heirs,",
    acl: "3.2.155",
  },
  {
    p: "ANTONY",
    l: "For, if you should, O, what would come of it!",
    acl: "3.2.156",
  },
  {
    p: "Fourth Citizen",
    l: "Read the will, we'll hear it, Antony,",
    acl: "3.2.157",
  },
  {
    p: "Fourth Citizen",
    l: "You shall read us the will, Caesar's will.",
    acl: "3.2.158",
  },
  {
    p: "ANTONY",
    l: "Will you be patient? will you stay awhile?",
    acl: "3.2.159",
  },
  {
    p: "ANTONY",
    l: "I have o'ershot myself to tell you of it:",
    acl: "3.2.160",
  },
  { p: "ANTONY", l: "I fear I wrong the honourable men", acl: "3.2.161" },
  {
    p: "ANTONY",
    l: "Whose daggers have stabb'd Caesar, I do fear it.",
    acl: "3.2.162",
  },
  {
    p: "Fourth Citizen",
    l: "They were traitors: honourable men!",
    acl: "3.2.163",
  },
  {
    p: "All",
    l: "The will! the testament!",
    acl: "3.2.164",
  },
  {
    p: "Second Citizen",
    l: "They were villains, murderers: the will! read the will.",
    acl: "3.2.165",
  },
  {
    p: "ANTONY",
    l: "You will compel me, then, to read the will?",
    acl: "3.2.166",
  },
  {
    p: "ANTONY",
    l: "Then make a ring about the corpse of Caesar,",
    acl: "3.2.167",
  },
  {
    p: "ANTONY",
    l: "And let me show you him that made the will.",
    acl: "3.2.168",
  },
  {
    p: "ANTONY",
    l: "Shall I descend? and will you give me leave?",
    acl: "3.2.169",
  },
  { p: "Several Citizens", l: "Come down.", acl: "3.2.170" },
  {
    p: "Second Citizen",
    l: "Descend.",
    acl: "3.2.171",
  },
  { p: "Third Citizen", l: "You shall have leave.", acl: "3.2.172" },
  {
    p: "Third Citizen",
    l: "ANTONY comes down",
    acl: "",
  },
  { p: "Fourth Citizen", l: "A ring, stand round.", acl: "3.2.173" },
  {
    p: "First Citizen",
    l: "Stand from the hearse, stand from the body.",
    acl: "3.2.174",
  },
  {
    p: "Second Citizen",
    l: "Room for Antony, most noble Antony.",
    acl: "3.2.175",
  },
  {
    p: "ANTONY",
    l: "Nay, press not so upon me, stand far off.",
    acl: "3.2.176",
  },
  { p: "Several Citizens", l: "Stand back, room, bear back.", acl: "3.2.177" },
  {
    p: "ANTONY",
    l: "If you have tears, prepare to shed them now.",
    acl: "3.2.178",
  },
  { p: "ANTONY", l: "You all do know this mantle: I remember", acl: "3.2.179" },
  {
    p: "ANTONY",
    l: "The first time ever Caesar put it on,",
    acl: "3.2.180",
  },
  {
    p: "ANTONY",
    l: "'Twas on a summer's evening, in his tent,",
    acl: "3.2.181",
  },
  {
    p: "ANTONY",
    l: "That day he overcame the Nervii:",
    acl: "3.2.182",
  },
  {
    p: "ANTONY",
    l: "Look, in this place ran Cassius' dagger through:",
    acl: "3.2.183",
  },
  {
    p: "ANTONY",
    l: "See what a rent the envious Casca made:",
    acl: "3.2.184",
  },
  {
    p: "ANTONY",
    l: "Through this the well-beloved Brutus stabb'd,",
    acl: "3.2.185",
  },
  {
    p: "ANTONY",
    l: "And as he pluck'd his cursed steel away,",
    acl: "3.2.186",
  },
  {
    p: "ANTONY",
    l: "Mark how the blood of Caesar follow'd it,",
    acl: "3.2.187",
  },
  {
    p: "ANTONY",
    l: "As rushing out of doors, to be resolved",
    acl: "3.2.188",
  },
  { p: "ANTONY", l: "If Brutus so unkindly knock'd, or no,", acl: "3.2.189" },
  {
    p: "ANTONY",
    l: "For Brutus, as you know, was Caesar's angel:",
    acl: "3.2.190",
  },
  {
    p: "ANTONY",
    l: "Judge, O you gods, how dearly Caesar loved him!",
    acl: "3.2.191",
  },
  {
    p: "ANTONY",
    l: "This was the most unkindest cut of all,",
    acl: "3.2.192",
  },
  { p: "ANTONY", l: "For when the noble Caesar saw him stab,", acl: "3.2.193" },
  {
    p: "ANTONY",
    l: "Ingratitude, more strong than traitors' arms,",
    acl: "3.2.194",
  },
  {
    p: "ANTONY",
    l: "Quite vanquish'd him: then burst his mighty heart,",
    acl: "3.2.195",
  },
  {
    p: "ANTONY",
    l: "And, in his mantle muffling up his face,",
    acl: "3.2.196",
  },
  { p: "ANTONY", l: "Even at the base of Pompey's statua,", acl: "3.2.197" },
  {
    p: "ANTONY",
    l: "Which all the while ran blood, great Caesar fell.",
    acl: "3.2.198",
  },
  {
    p: "ANTONY",
    l: "O, what a fall was there, my countrymen!",
    acl: "3.2.199",
  },
  {
    p: "ANTONY",
    l: "Then I, and you, and all of us fell down,",
    acl: "3.2.200",
  },
  {
    p: "ANTONY",
    l: "Whilst bloody treason flourish'd over us.",
    acl: "3.2.201",
  },
  {
    p: "ANTONY",
    l: "O, now you weep, and, I perceive, you feel",
    acl: "3.2.202",
  },
  {
    p: "ANTONY",
    l: "The dint of pity: these are gracious drops.",
    acl: "3.2.203",
  },
  {
    p: "ANTONY",
    l: "Kind souls, what, weep you when you but behold",
    acl: "3.2.204",
  },
  {
    p: "ANTONY",
    l: "Our Caesar's vesture wounded? Look you here,",
    acl: "3.2.205",
  },
  {
    p: "ANTONY",
    l: "Here is himself, marr'd, as you see, with traitors.",
    acl: "3.2.206",
  },
  { p: "First Citizen", l: "O piteous spectacle!", acl: "3.2.207" },
  {
    p: "Second Citizen",
    l: "O noble Caesar!",
    acl: "3.2.208",
  },
  { p: "Third Citizen", l: "O woful day!", acl: "3.2.209" },
  {
    p: "Fourth Citizen",
    l: "O traitors, villains!",
    acl: "3.2.210",
  },
  { p: "First Citizen", l: "O most bloody sight!", acl: "3.2.211" },
  {
    p: "Second Citizen",
    l: "We will be revenged.",
    acl: "3.2.212",
  },
  {
    p: "All",
    l: "Revenge! About! Seek! Burn! Fire! Kill! Slay!",
    acl: "3.2.213",
  },
  {
    p: "All",
    l: "Let not a traitor live!",
    acl: "3.2.214",
  },
  { p: "ANTONY", l: "Stay, countrymen.", acl: "3.2.215" },
  {
    p: "First Citizen",
    l: "Peace there! hear the noble Antony.",
    acl: "3.2.216",
  },
  {
    p: "Second Citizen",
    l: "We'll hear him, we'll follow him, we'll die with him.",
    acl: "3.2.217",
  },
  {
    p: "ANTONY",
    l: "Good friends, sweet friends, let me not stir you up",
    acl: "3.2.218",
  },
  {
    p: "ANTONY",
    l: "To such a sudden flood of mutiny.",
    acl: "3.2.219",
  },
  {
    p: "ANTONY",
    l: "They that have done this deed are honourable:",
    acl: "3.2.220",
  },
  {
    p: "ANTONY",
    l: "What private griefs they have, alas, I know not,",
    acl: "3.2.221",
  },
  {
    p: "ANTONY",
    l: "That made them do it: they are wise and honourable,",
    acl: "3.2.222",
  },
  {
    p: "ANTONY",
    l: "And will, no doubt, with reasons answer you.",
    acl: "3.2.223",
  },
  {
    p: "ANTONY",
    l: "I come not, friends, to steal away your hearts:",
    acl: "3.2.224",
  },
  {
    p: "ANTONY",
    l: "I am no orator, as Brutus is,",
    acl: "3.2.225",
  },
  {
    p: "ANTONY",
    l: "But, as you know me all, a plain blunt man,",
    acl: "3.2.226",
  },
  {
    p: "ANTONY",
    l: "That love my friend, and that they know full well",
    acl: "3.2.227",
  },
  {
    p: "ANTONY",
    l: "That gave me public leave to speak of him:",
    acl: "3.2.228",
  },
  {
    p: "ANTONY",
    l: "For I have neither wit, nor words, nor worth,",
    acl: "3.2.229",
  },
  {
    p: "ANTONY",
    l: "Action, nor utterance, nor the power of speech,",
    acl: "3.2.230",
  },
  {
    p: "ANTONY",
    l: "To stir men's blood: I only speak right on,",
    acl: "3.2.231",
  },
  {
    p: "ANTONY",
    l: "I tell you that which you yourselves do know,",
    acl: "3.2.232",
  },
  {
    p: "ANTONY",
    l: "Show you sweet Caesar's wounds, poor poor dumb mouths,",
    acl: "3.2.233",
  },
  {
    p: "ANTONY",
    l: "And bid them speak for me: but were I Brutus,",
    acl: "3.2.234",
  },
  {
    p: "ANTONY",
    l: "And Brutus Antony, there were an Antony",
    acl: "3.2.235",
  },
  {
    p: "ANTONY",
    l: "Would ruffle up your spirits and put a tongue",
    acl: "3.2.236",
  },
  {
    p: "ANTONY",
    l: "In every wound of Caesar that should move",
    acl: "3.2.237",
  },
  { p: "ANTONY", l: "The stones of Rome to rise and mutiny.", acl: "3.2.238" },
  {
    p: "All",
    l: "We'll mutiny.",
    acl: "3.2.239",
  },
  { p: "First Citizen", l: "We'll burn the house of Brutus.", acl: "3.2.240" },
  {
    p: "Third Citizen",
    l: "Away, then! come, seek the conspirators.",
    acl: "3.2.241",
  },
  {
    p: "ANTONY",
    l: "Yet hear me, countrymen, yet hear me speak.",
    acl: "3.2.242",
  },
  {
    p: "All",
    l: "Peace, ho! Hear Antony. Most noble Antony!",
    acl: "3.2.243",
  },
  {
    p: "ANTONY",
    l: "Why, friends, you go to do you know not what:",
    acl: "3.2.244",
  },
  {
    p: "ANTONY",
    l: "Wherein hath Caesar thus deserved your loves?",
    acl: "3.2.245",
  },
  {
    p: "ANTONY",
    l: "Alas, you know not: I must tell you then:",
    acl: "3.2.246",
  },
  {
    p: "ANTONY",
    l: "You have forgot the will I told you of.",
    acl: "3.2.247",
  },
  {
    p: "All",
    l: "Most true. The will! Let's stay and hear the will.",
    acl: "3.2.248",
  },
  {
    p: "ANTONY",
    l: "Here is the will, and under Caesar's seal.",
    acl: "3.2.249",
  },
  { p: "ANTONY", l: "To every Roman citizen he gives,", acl: "3.2.250" },
  {
    p: "ANTONY",
    l: "To every several man, seventy-five drachmas.",
    acl: "3.2.251",
  },
  {
    p: "Second Citizen",
    l: "Most noble Caesar! We'll revenge his death.",
    acl: "3.2.252",
  },
  {
    p: "Third Citizen",
    l: "O royal Caesar!",
    acl: "3.2.253",
  },
  { p: "ANTONY", l: "Hear me with patience.", acl: "3.2.254" },
  {
    p: "All",
    l: "Peace, ho!",
    acl: "3.2.255",
  },
  {
    p: "ANTONY",
    l: "Moreover, he hath left you all his walks,",
    acl: "3.2.256",
  },
  {
    p: "ANTONY",
    l: "His private arbours and new-planted orchards,",
    acl: "3.2.257",
  },
  {
    p: "ANTONY",
    l: "On this side Tiber, he hath left them you,",
    acl: "3.2.258",
  },
  {
    p: "ANTONY",
    l: "And to your heirs for ever, common pleasures,",
    acl: "3.2.259",
  },
  {
    p: "ANTONY",
    l: "To walk abroad, and recreate yourselves.",
    acl: "3.2.260",
  },
  {
    p: "ANTONY",
    l: "Here was a Caesar! when comes such another?",
    acl: "3.2.261",
  },
  { p: "First Citizen", l: "Never, never. Come, away, away!", acl: "3.2.262" },
  {
    p: "First Citizen",
    l: "We'll burn his body in the holy place,",
    acl: "3.2.263",
  },
  {
    p: "First Citizen",
    l: "And with the brands fire the traitors' houses.",
    acl: "3.2.264",
  },
  { p: "First Citizen", l: "Take up the body.", acl: "3.2.265" },
  {
    p: "Second Citizen",
    l: "Go fetch fire.",
    acl: "3.2.266",
  },
  { p: "Third Citizen", l: "Pluck down benches.", acl: "3.2.267" },
  {
    p: "Fourth Citizen",
    l: "Pluck down forms, windows, any thing.",
    acl: "3.2.268",
  },
  { p: "Fourth Citizen", l: "Exeunt Citizens with the body", acl: "" },
  {
    p: "ANTONY",
    l: "Now let it work. Mischief, thou art afoot,",
    acl: "3.2.269",
  },
  { p: "ANTONY", l: "Take thou what course thou wilt!", acl: "3.2.270" },
  {
    p: "ANTONY",
    l: "Enter a Servant",
    acl: "",
  },
  { p: "ANTONY", l: "How now, fellow!", acl: "3.2.271" },
  {
    p: "Servant",
    l: "Sir, Octavius is already come to Rome.",
    acl: "3.2.272",
  },
  { p: "ANTONY", l: "Where is he?", acl: "3.2.273" },
  {
    p: "Servant",
    l: "He and Lepidus are at Caesar's house.",
    acl: "3.2.274",
  },
  {
    p: "ANTONY",
    l: "And thither will I straight to visit him:",
    acl: "3.2.275",
  },
  {
    p: "ANTONY",
    l: "He comes upon a wish. Fortune is merry,",
    acl: "3.2.276",
  },
  {
    p: "ANTONY",
    l: "And in this mood will give us any thing.",
    acl: "3.2.277",
  },
  {
    p: "Servant",
    l: "I heard him say, Brutus and Cassius",
    acl: "3.2.278",
  },
  {
    p: "Servant",
    l: "Are rid like madmen through the gates of Rome.",
    acl: "3.2.279",
  },
  {
    p: "ANTONY",
    l: "Belike they had some notice of the people,",
    acl: "3.2.280",
  },
  {
    p: "ANTONY",
    l: "How I had moved them. Bring me to Octavius.",
    acl: "3.2.281",
  },
  {
    p: "ANTONY",
    l: "Exeunt",
    acl: "",
  },
  { p: "ANTONY", l: "SCENE III. A street.", acl: "" },
  {
    p: "ANTONY",
    l: "Enter CINNA the poet",
    acl: "",
  },
  {
    p: "CINNA THE POET",
    l: "I dreamt to-night that I did feast with Caesar,",
    acl: "3.3.1",
  },
  {
    p: "CINNA THE POET",
    l: "And things unlucky charge my fantasy:",
    acl: "3.3.2",
  },
  {
    p: "CINNA THE POET",
    l: "I have no will to wander forth of doors,",
    acl: "3.3.3",
  },
  { p: "CINNA THE POET", l: "Yet something leads me forth.", acl: "3.3.4" },
  {
    p: "CINNA THE POET",
    l: "Enter Citizens",
    acl: "",
  },
  { p: "First Citizen", l: "What is your name?", acl: "3.3.5" },
  {
    p: "Second Citizen",
    l: "Whither are you going?",
    acl: "3.3.6",
  },
  { p: "Third Citizen", l: "Where do you dwell?", acl: "3.3.7" },
  {
    p: "Fourth Citizen",
    l: "Are you a married man or a bachelor?",
    acl: "3.3.8",
  },
  { p: "Second Citizen", l: "Answer every man directly.", acl: "3.3.9" },
  {
    p: "First Citizen",
    l: "Ay, and briefly.",
    acl: "3.3.10",
  },
  { p: "Fourth Citizen", l: "Ay, and wisely.", acl: "3.3.11" },
  {
    p: "Third Citizen",
    l: "Ay, and truly, you were best.",
    acl: "3.3.12",
  },
  {
    p: "CINNA THE POET",
    l: "What is my name? Whither am I going? Where do I",
    acl: "3.3.13",
  },
  {
    p: "CINNA THE POET",
    l: "dwell? Am I a married man or a bachelor? Then, to",
    acl: "3.3.14",
  },
  {
    p: "CINNA THE POET",
    l: "answer every man directly and briefly, wisely and",
    acl: "3.3.15",
  },
  {
    p: "CINNA THE POET",
    l: "truly: wisely I say, I am a bachelor.",
    acl: "3.3.16",
  },
  {
    p: "Second Citizen",
    l: "That's as much as to say, they are fools that marry:",
    acl: "3.3.17",
  },
  {
    p: "Second Citizen",
    l: "you'll bear me a bang for that, I fear. Proceed, directly.",
    acl: "3.3.18",
  },
  {
    p: "CINNA THE POET",
    l: "Directly, I am going to Caesar's funeral.",
    acl: "3.3.19",
  },
  {
    p: "First Citizen",
    l: "As a friend or an enemy?",
    acl: "3.3.20",
  },
  { p: "CINNA THE POET", l: "As a friend.", acl: "3.3.21" },
  {
    p: "Second Citizen",
    l: "That matter is answered directly.",
    acl: "3.3.22",
  },
  { p: "Fourth Citizen", l: "For your dwelling,--briefly.", acl: "3.3.23" },
  {
    p: "CINNA THE POET",
    l: "Briefly, I dwell by the Capitol.",
    acl: "3.3.24",
  },
  { p: "Third Citizen", l: "Your name, sir, truly.", acl: "3.3.25" },
  {
    p: "CINNA THE POET",
    l: "Truly, my name is Cinna.",
    acl: "3.3.26",
  },
  {
    p: "First Citizen",
    l: "Tear him to pieces, he's a conspirator.",
    acl: "3.3.27",
  },
  {
    p: "CINNA THE POET",
    l: "I am Cinna the poet, I am Cinna the poet.",
    acl: "3.3.28",
  },
  {
    p: "Fourth Citizen",
    l: "Tear him for his bad verses, tear him for his bad verses.",
    acl: "3.3.29",
  },
  { p: "CINNA THE POET", l: "I am not Cinna the conspirator.", acl: "3.3.30" },
  {
    p: "Fourth Citizen",
    l: "It is no matter, his name's Cinna, pluck but his",
    acl: "3.3.31",
  },
  {
    p: "Fourth Citizen",
    l: "name out of his heart, and turn him going.",
    acl: "3.3.32",
  },
  {
    p: "Third Citizen",
    l: "Tear him, tear him! Come, brands ho! fire-brands:",
    acl: "3.3.33",
  },
  {
    p: "Third Citizen",
    l: "to Brutus', to Cassius', burn all: some to Decius'",
    acl: "3.3.34",
  },
  {
    p: "Third Citizen",
    l: "house, and some to Casca's, some to Ligarius': away, go!",
    acl: "3.3.35",
  },
  { p: "Third Citizen", l: "Exeunt", acl: "" },
  {
    p: "Third Citizen",
    l: "ACT IV",
    acl: "",
  },
  { p: "Third Citizen", l: "SCENE I. A house in Rome.", acl: "" },
  {
    p: "Third Citizen",
    l: "ANTONY, OCTAVIUS, and LEPIDUS, seated at a table",
    acl: "",
  },
  {
    p: "ANTONY",
    l: "These many, then, shall die, their names are prick'd.",
    acl: "4.1.1",
  },
  {
    p: "OCTAVIUS",
    l: "Your brother too must die, consent you, Lepidus?",
    acl: "4.1.2",
  },
  { p: "LEPIDUS", l: "I do consent--", acl: "4.1.3" },
  {
    p: "OCTAVIUS",
    l: "Prick him down, Antony.",
    acl: "4.1.4",
  },
  { p: "LEPIDUS", l: "Upon condition Publius shall not live,", acl: "4.1.5" },
  {
    p: "LEPIDUS",
    l: "Who is your sister's son, Mark Antony.",
    acl: "4.1.6",
  },
  {
    p: "ANTONY",
    l: "He shall not live, look, with a spot I damn him.",
    acl: "4.1.7",
  },
  {
    p: "ANTONY",
    l: "But, Lepidus, go you to Caesar's house,",
    acl: "4.1.8",
  },
  {
    p: "ANTONY",
    l: "Fetch the will hither, and we shall determine",
    acl: "4.1.9",
  },
  {
    p: "ANTONY",
    l: "How to cut off some charge in legacies.",
    acl: "4.1.10",
  },
  { p: "LEPIDUS", l: "What, shall I find you here?", acl: "4.1.11" },
  {
    p: "OCTAVIUS",
    l: "Or here, or at the Capitol.",
    acl: "4.1.12",
  },
  { p: "OCTAVIUS", l: "Exit LEPIDUS", acl: "" },
  {
    p: "ANTONY",
    l: "This is a slight unmeritable man,",
    acl: "4.1.13",
  },
  { p: "ANTONY", l: "Meet to be sent on errands: is it fit,", acl: "4.1.14" },
  {
    p: "ANTONY",
    l: "The three-fold world divided, he should stand",
    acl: "4.1.15",
  },
  { p: "ANTONY", l: "One of the three to share it?", acl: "4.1.16" },
  {
    p: "OCTAVIUS",
    l: "So you thought him,",
    acl: "4.1.17",
  },
  {
    p: "OCTAVIUS",
    l: "And took his voice who should be prick'd to die,",
    acl: "4.1.18",
  },
  {
    p: "OCTAVIUS",
    l: "In our black sentence and proscription.",
    acl: "4.1.19",
  },
  {
    p: "ANTONY",
    l: "Octavius, I have seen more days than you:",
    acl: "4.1.20",
  },
  {
    p: "ANTONY",
    l: "And though we lay these honours on this man,",
    acl: "4.1.21",
  },
  {
    p: "ANTONY",
    l: "To ease ourselves of divers slanderous loads,",
    acl: "4.1.22",
  },
  {
    p: "ANTONY",
    l: "He shall but bear them as the ass bears gold,",
    acl: "4.1.23",
  },
  { p: "ANTONY", l: "To groan and sweat under the business,", acl: "4.1.24" },
  {
    p: "ANTONY",
    l: "Either led or driven, as we point the way,",
    acl: "4.1.25",
  },
  {
    p: "ANTONY",
    l: "And having brought our treasure where we will,",
    acl: "4.1.26",
  },
  {
    p: "ANTONY",
    l: "Then take we down his load, and turn him off,",
    acl: "4.1.27",
  },
  {
    p: "ANTONY",
    l: "Like to the empty ass, to shake his ears,",
    acl: "4.1.28",
  },
  {
    p: "ANTONY",
    l: "And graze in commons.",
    acl: "4.1.29",
  },
  { p: "OCTAVIUS", l: "You may do your will,", acl: "4.1.30" },
  {
    p: "OCTAVIUS",
    l: "But he's a tried and valiant soldier.",
    acl: "4.1.31",
  },
  { p: "ANTONY", l: "So is my horse, Octavius, and for that", acl: "4.1.32" },
  {
    p: "ANTONY",
    l: "I do appoint him store of provender:",
    acl: "4.1.33",
  },
  { p: "ANTONY", l: "It is a creature that I teach to fight,", acl: "4.1.34" },
  {
    p: "ANTONY",
    l: "To wind, to stop, to run directly on,",
    acl: "4.1.35",
  },
  {
    p: "ANTONY",
    l: "His corporal motion govern'd by my spirit.",
    acl: "4.1.36",
  },
  {
    p: "ANTONY",
    l: "And, in some taste, is Lepidus but so,",
    acl: "4.1.37",
  },
  {
    p: "ANTONY",
    l: "He must be taught and train'd and bid go forth,",
    acl: "4.1.38",
  },
  {
    p: "ANTONY",
    l: "A barren-spirited fellow, one that feeds",
    acl: "4.1.39",
  },
  { p: "ANTONY", l: "On abjects, orts and imitations,", acl: "4.1.40" },
  {
    p: "ANTONY",
    l: "Which, out of use and staled by other men,",
    acl: "4.1.41",
  },
  { p: "ANTONY", l: "Begin his fashion: do not talk of him,", acl: "4.1.42" },
  {
    p: "ANTONY",
    l: "But as a property. And now, Octavius,",
    acl: "4.1.43",
  },
  { p: "ANTONY", l: "Listen great things:--Brutus and Cassius", acl: "4.1.44" },
  {
    p: "ANTONY",
    l: "Are levying powers: we must straight make head:",
    acl: "4.1.45",
  },
  { p: "ANTONY", l: "Therefore let our alliance be combined,", acl: "4.1.46" },
  {
    p: "ANTONY",
    l: "Our best friends made, our means stretch'd",
    acl: "4.1.47",
  },
  { p: "ANTONY", l: "And let us presently go sit in council,", acl: "4.1.48" },
  {
    p: "ANTONY",
    l: "How covert matters may be best disclosed,",
    acl: "4.1.49",
  },
  { p: "ANTONY", l: "And open perils surest answered.", acl: "4.1.50" },
  {
    p: "OCTAVIUS",
    l: "Let us do so: for we are at the stake,",
    acl: "4.1.51",
  },
  { p: "OCTAVIUS", l: "And bay'd about with many enemies,", acl: "4.1.52" },
  {
    p: "OCTAVIUS",
    l: "And some that smile have in their hearts, I fear,",
    acl: "4.1.53",
  },
  { p: "OCTAVIUS", l: "Millions of mischiefs.", acl: "4.1.54" },
  {
    p: "OCTAVIUS",
    l: "Exeunt",
    acl: "",
  },
  {
    p: "OCTAVIUS",
    l: "SCENE II. Camp near Sardis. Before BRUTUS's tent.",
    acl: "",
  },
  {
    p: "OCTAVIUS",
    l: "Drum. Enter BRUTUS, LUCILIUS, LUCIUS, and Soldiers, TITINIUS and PINDARUS meeting them",
    acl: "",
  },
  { p: "BRUTUS", l: "Stand, ho!", acl: "4.2.1" },
  {
    p: "LUCILIUS",
    l: "Give the word, ho! and stand.",
    acl: "4.2.2",
  },
  { p: "BRUTUS", l: "What now, Lucilius! is Cassius near?", acl: "4.2.3" },
  {
    p: "LUCILIUS",
    l: "He is at hand, and Pindarus is come",
    acl: "4.2.4",
  },
  { p: "LUCILIUS", l: "To do you salutation from his master.", acl: "4.2.5" },
  {
    p: "BRUTUS",
    l: "He greets me well. Your master, Pindarus,",
    acl: "4.2.6",
  },
  { p: "BRUTUS", l: "In his own change, or by ill officers,", acl: "4.2.7" },
  {
    p: "BRUTUS",
    l: "Hath given me some worthy cause to wish",
    acl: "4.2.8",
  },
  {
    p: "BRUTUS",
    l: "Things done, undone: but, if he be at hand,",
    acl: "4.2.9",
  },
  {
    p: "BRUTUS",
    l: "I shall be satisfied.",
    acl: "4.2.10",
  },
  { p: "PINDARUS", l: "I do not doubt", acl: "4.2.11" },
  {
    p: "PINDARUS",
    l: "But that my noble master will appear",
    acl: "4.2.12",
  },
  {
    p: "PINDARUS",
    l: "Such as he is, full of regard and honour.",
    acl: "4.2.13",
  },
  {
    p: "BRUTUS",
    l: "He is not doubted. A word, Lucilius,",
    acl: "4.2.14",
  },
  { p: "BRUTUS", l: "How he received you, let me be resolved.", acl: "4.2.15" },
  {
    p: "LUCILIUS",
    l: "With courtesy and with respect enough,",
    acl: "4.2.16",
  },
  { p: "LUCILIUS", l: "But not with such familiar instances,", acl: "4.2.17" },
  {
    p: "LUCILIUS",
    l: "Nor with such free and friendly conference,",
    acl: "4.2.18",
  },
  { p: "LUCILIUS", l: "As he hath used of old.", acl: "4.2.19" },
  {
    p: "BRUTUS",
    l: "Thou hast described",
    acl: "4.2.20",
  },
  {
    p: "BRUTUS",
    l: "A hot friend cooling: ever note, Lucilius,",
    acl: "4.2.21",
  },
  {
    p: "BRUTUS",
    l: "When love begins to sicken and decay,",
    acl: "4.2.22",
  },
  { p: "BRUTUS", l: "It useth an enforced ceremony.", acl: "4.2.23" },
  {
    p: "BRUTUS",
    l: "There are no tricks in plain and simple faith,",
    acl: "4.2.24",
  },
  { p: "BRUTUS", l: "But hollow men, like horses hot at hand,", acl: "4.2.25" },
  {
    p: "BRUTUS",
    l: "Make gallant show and promise of their mettle,",
    acl: "4.2.26",
  },
  {
    p: "BRUTUS",
    l: "But when they should endure the bloody spur,",
    acl: "4.2.27",
  },
  {
    p: "BRUTUS",
    l: "They fall their crests, and, like deceitful jades,",
    acl: "4.2.28",
  },
  { p: "BRUTUS", l: "Sink in the trial. Comes his army on?", acl: "4.2.29" },
  {
    p: "LUCILIUS",
    l: "They mean this night in Sardis to be quarter'd,",
    acl: "4.2.30",
  },
  {
    p: "LUCILIUS",
    l: "The greater part, the horse in general,",
    acl: "4.2.31",
  },
  {
    p: "LUCILIUS",
    l: "Are come with Cassius.",
    acl: "4.2.32",
  },
  { p: "BRUTUS", l: "Hark! he is arrived.", acl: "4.2.33" },
  {
    p: "BRUTUS",
    l: "Low march within",
    acl: "",
  },
  { p: "BRUTUS", l: "March gently on to meet him.", acl: "4.2.34" },
  {
    p: "BRUTUS",
    l: "Enter CASSIUS and his powers",
    acl: "",
  },
  { p: "CASSIUS", l: "Stand, ho!", acl: "4.2.35" },
  {
    p: "BRUTUS",
    l: "Stand, ho! Speak the word along.",
    acl: "4.2.36",
  },
  { p: "First Soldier", l: "Stand!", acl: "4.2.37" },
  {
    p: "Second Soldier",
    l: "Stand!",
    acl: "4.2.38",
  },
  { p: "Third Soldier", l: "Stand!", acl: "4.2.39" },
  {
    p: "CASSIUS",
    l: "Most noble brother, you have done me wrong.",
    acl: "4.2.40",
  },
  {
    p: "BRUTUS",
    l: "Judge me, you gods! wrong I mine enemies?",
    acl: "4.2.41",
  },
  {
    p: "BRUTUS",
    l: "And, if not so, how should I wrong a brother?",
    acl: "4.2.42",
  },
  {
    p: "CASSIUS",
    l: "Brutus, this sober form of yours hides wrongs,",
    acl: "4.2.43",
  },
  {
    p: "CASSIUS",
    l: "And when you do them--",
    acl: "4.2.44",
  },
  { p: "BRUTUS", l: "Cassius, be content.", acl: "4.2.45" },
  {
    p: "BRUTUS",
    l: "Speak your griefs softly: I do know you well.",
    acl: "4.2.46",
  },
  { p: "BRUTUS", l: "Before the eyes of both our armies here,", acl: "4.2.47" },
  {
    p: "BRUTUS",
    l: "Which should perceive nothing but love from us,",
    acl: "4.2.48",
  },
  { p: "BRUTUS", l: "Let us not wrangle: bid them move away,", acl: "4.2.49" },
  {
    p: "BRUTUS",
    l: "Then in my tent, Cassius, enlarge your griefs,",
    acl: "4.2.50",
  },
  { p: "BRUTUS", l: "And I will give you audience.", acl: "4.2.51" },
  {
    p: "CASSIUS",
    l: "Pindarus,",
    acl: "4.2.52",
  },
  {
    p: "CASSIUS",
    l: "Bid our commanders lead their charges off",
    acl: "4.2.53",
  },
  {
    p: "CASSIUS",
    l: "A little from this ground.",
    acl: "4.2.54",
  },
  {
    p: "BRUTUS",
    l: "Lucilius, do you the like, and let no man",
    acl: "4.2.55",
  },
  {
    p: "BRUTUS",
    l: "Come to our tent till we have done our conference.",
    acl: "4.2.56",
  },
  { p: "BRUTUS", l: "Let Lucius and Titinius guard our door.", acl: "4.2.57" },
  {
    p: "BRUTUS",
    l: "Exeunt",
    acl: "",
  },
  { p: "BRUTUS", l: "SCENE III. Brutus's tent.", acl: "" },
  {
    p: "BRUTUS",
    l: "Enter BRUTUS and CASSIUS",
    acl: "",
  },
  {
    p: "CASSIUS",
    l: "That you have wrong'd me doth appear in this:",
    acl: "4.3.1",
  },
  {
    p: "CASSIUS",
    l: "You have condemn'd and noted Lucius Pella",
    acl: "4.3.2",
  },
  { p: "CASSIUS", l: "For taking bribes here of the Sardians,", acl: "4.3.3" },
  {
    p: "CASSIUS",
    l: "Wherein my letters, praying on his side,",
    acl: "4.3.4",
  },
  {
    p: "CASSIUS",
    l: "Because I knew the man, were slighted off.",
    acl: "4.3.5",
  },
  {
    p: "BRUTUS",
    l: "You wronged yourself to write in such a case.",
    acl: "4.3.6",
  },
  { p: "CASSIUS", l: "In such a time as this it is not meet", acl: "4.3.7" },
  {
    p: "CASSIUS",
    l: "That every nice offence should bear his comment.",
    acl: "4.3.8",
  },
  { p: "BRUTUS", l: "Let me tell you, Cassius, you yourself", acl: "4.3.9" },
  {
    p: "BRUTUS",
    l: "Are much condemn'd to have an itching palm,",
    acl: "4.3.10",
  },
  { p: "BRUTUS", l: "To sell and mart your offices for gold", acl: "4.3.11" },
  {
    p: "BRUTUS",
    l: "To undeservers.",
    acl: "4.3.12",
  },
  { p: "CASSIUS", l: "I an itching palm!", acl: "4.3.13" },
  {
    p: "CASSIUS",
    l: "You know that you are Brutus that speak this,",
    acl: "4.3.14",
  },
  {
    p: "CASSIUS",
    l: "Or, by the gods, this speech were else your last.",
    acl: "4.3.15",
  },
  {
    p: "BRUTUS",
    l: "The name of Cassius honours this corruption,",
    acl: "4.3.16",
  },
  {
    p: "BRUTUS",
    l: "And chastisement doth therefore hide his head.",
    acl: "4.3.17",
  },
  {
    p: "CASSIUS",
    l: "Chastisement!",
    acl: "4.3.18",
  },
  {
    p: "BRUTUS",
    l: "Remember March, the ides of March remember:",
    acl: "4.3.19",
  },
  {
    p: "BRUTUS",
    l: "Did not great Julius bleed for justice' sake?",
    acl: "4.3.20",
  },
  {
    p: "BRUTUS",
    l: "What villain touch'd his body, that did stab,",
    acl: "4.3.21",
  },
  {
    p: "BRUTUS",
    l: "And not for justice? What, shall one of us",
    acl: "4.3.22",
  },
  {
    p: "BRUTUS",
    l: "That struck the foremost man of all this world",
    acl: "4.3.23",
  },
  {
    p: "BRUTUS",
    l: "But for supporting robbers, shall we now",
    acl: "4.3.24",
  },
  {
    p: "BRUTUS",
    l: "Contaminate our fingers with base bribes,",
    acl: "4.3.25",
  },
  {
    p: "BRUTUS",
    l: "And sell the mighty space of our large honours",
    acl: "4.3.26",
  },
  {
    p: "BRUTUS",
    l: "For so much trash as may be grasped thus?",
    acl: "4.3.27",
  },
  {
    p: "BRUTUS",
    l: "I had rather be a dog, and bay the moon,",
    acl: "4.3.28",
  },
  { p: "BRUTUS", l: "Than such a Roman.", acl: "4.3.29" },
  {
    p: "CASSIUS",
    l: "Brutus, bay not me,",
    acl: "4.3.30",
  },
  {
    p: "CASSIUS",
    l: "I'll not endure it: you forget yourself,",
    acl: "4.3.31",
  },
  {
    p: "CASSIUS",
    l: "To hedge me in, I am a soldier, I,",
    acl: "4.3.32",
  },
  { p: "CASSIUS", l: "Older in practise, abler than yourself", acl: "4.3.33" },
  {
    p: "CASSIUS",
    l: "To make conditions.",
    acl: "4.3.34",
  },
  { p: "BRUTUS", l: "Go to, you are not, Cassius.", acl: "4.3.35" },
  {
    p: "CASSIUS",
    l: "I am.",
    acl: "4.3.36",
  },
  { p: "BRUTUS", l: "I say you are not.", acl: "4.3.37" },
  {
    p: "CASSIUS",
    l: "Urge me no more, I shall forget myself,",
    acl: "4.3.38",
  },
  {
    p: "CASSIUS",
    l: "Have mind upon your health, tempt me no further.",
    acl: "4.3.39",
  },
  {
    p: "BRUTUS",
    l: "Away, slight man!",
    acl: "4.3.40",
  },
  { p: "CASSIUS", l: "Is't possible?", acl: "4.3.41" },
  {
    p: "BRUTUS",
    l: "Hear me, for I will speak.",
    acl: "4.3.42",
  },
  {
    p: "BRUTUS",
    l: "Must I give way and room to your rash choler?",
    acl: "4.3.43",
  },
  {
    p: "BRUTUS",
    l: "Shall I be frighted when a madman stares?",
    acl: "4.3.44",
  },
  {
    p: "CASSIUS",
    l: "O ye gods, ye gods! must I endure all this?",
    acl: "4.3.45",
  },
  {
    p: "BRUTUS",
    l: "All this! ay, more: fret till your proud heart break,",
    acl: "4.3.46",
  },
  {
    p: "BRUTUS",
    l: "Go show your slaves how choleric you are,",
    acl: "4.3.47",
  },
  {
    p: "BRUTUS",
    l: "And make your bondmen tremble. Must I budge?",
    acl: "4.3.48",
  },
  {
    p: "BRUTUS",
    l: "Must I observe you? must I stand and crouch",
    acl: "4.3.49",
  },
  {
    p: "BRUTUS",
    l: "Under your testy humour? By the gods",
    acl: "4.3.50",
  },
  {
    p: "BRUTUS",
    l: "You shall digest the venom of your spleen,",
    acl: "4.3.51",
  },
  {
    p: "BRUTUS",
    l: "Though it do split you, for, from this day forth,",
    acl: "4.3.52",
  },
  {
    p: "BRUTUS",
    l: "I'll use you for my mirth, yea, for my laughter,",
    acl: "4.3.53",
  },
  {
    p: "BRUTUS",
    l: "When you are waspish.",
    acl: "4.3.54",
  },
  { p: "CASSIUS", l: "Is it come to this?", acl: "4.3.55" },
  {
    p: "BRUTUS",
    l: "You say you are a better soldier:",
    acl: "4.3.56",
  },
  {
    p: "BRUTUS",
    l: "Let it appear so, make your vaunting true,",
    acl: "4.3.57",
  },
  {
    p: "BRUTUS",
    l: "And it shall please me well: for mine own part,",
    acl: "4.3.58",
  },
  { p: "BRUTUS", l: "I shall be glad to learn of noble men.", acl: "4.3.59" },
  {
    p: "CASSIUS",
    l: "You wrong me every way, you wrong me, Brutus,",
    acl: "4.3.60",
  },
  { p: "CASSIUS", l: "I said, an elder soldier, not a better:", acl: "4.3.61" },
  {
    p: "CASSIUS",
    l: "Did I say 'better'?",
    acl: "4.3.62",
  },
  { p: "BRUTUS", l: "If you did, I care not.", acl: "4.3.63" },
  {
    p: "CASSIUS",
    l: "When Caesar lived, he durst not thus have moved me.",
    acl: "4.3.64",
  },
  {
    p: "BRUTUS",
    l: "Peace, peace! you durst not so have tempted him.",
    acl: "4.3.65",
  },
  {
    p: "CASSIUS",
    l: "I durst not!",
    acl: "4.3.66",
  },
  { p: "BRUTUS", l: "No.", acl: "4.3.67" },
  {
    p: "CASSIUS",
    l: "What, durst not tempt him!",
    acl: "4.3.68",
  },
  { p: "BRUTUS", l: "For your life you durst not!", acl: "4.3.69" },
  {
    p: "CASSIUS",
    l: "Do not presume too much upon my love,",
    acl: "4.3.70",
  },
  { p: "CASSIUS", l: "I may do that I shall be sorry for.", acl: "4.3.71" },
  {
    p: "BRUTUS",
    l: "You have done that you should be sorry for.",
    acl: "4.3.72",
  },
  {
    p: "BRUTUS",
    l: "There is no terror, Cassius, in your threats,",
    acl: "4.3.73",
  },
  {
    p: "BRUTUS",
    l: "For I am arm'd so strong in honesty",
    acl: "4.3.74",
  },
  { p: "BRUTUS", l: "That they pass by me as the idle wind,", acl: "4.3.75" },
  {
    p: "BRUTUS",
    l: "Which I respect not. I did send to you",
    acl: "4.3.76",
  },
  {
    p: "BRUTUS",
    l: "For certain sums of gold, which you denied me:",
    acl: "4.3.77",
  },
  {
    p: "BRUTUS",
    l: "For I can raise no money by vile means:",
    acl: "4.3.78",
  },
  { p: "BRUTUS", l: "By heaven, I had rather coin my heart,", acl: "4.3.79" },
  {
    p: "BRUTUS",
    l: "And drop my blood for drachmas, than to wring",
    acl: "4.3.80",
  },
  {
    p: "BRUTUS",
    l: "From the hard hands of peasants their vile trash",
    acl: "4.3.81",
  },
  {
    p: "BRUTUS",
    l: "By any indirection: I did send",
    acl: "4.3.82",
  },
  { p: "BRUTUS", l: "To you for gold to pay my legions,", acl: "4.3.83" },
  {
    p: "BRUTUS",
    l: "Which you denied me: was that done like Cassius?",
    acl: "4.3.84",
  },
  { p: "BRUTUS", l: "Should I have answer'd Caius Cassius so?", acl: "4.3.85" },
  {
    p: "BRUTUS",
    l: "When Marcus Brutus grows so covetous,",
    acl: "4.3.86",
  },
  {
    p: "BRUTUS",
    l: "To lock such rascal counters from his friends,",
    acl: "4.3.87",
  },
  {
    p: "BRUTUS",
    l: "Be ready, gods, with all your thunderbolts,",
    acl: "4.3.88",
  },
  { p: "BRUTUS", l: "Dash him to pieces!", acl: "4.3.89" },
  {
    p: "CASSIUS",
    l: "I denied you not.",
    acl: "4.3.90",
  },
  { p: "BRUTUS", l: "You did.", acl: "4.3.91" },
  {
    p: "CASSIUS",
    l: "I did not: he was but a fool that brought",
    acl: "4.3.92",
  },
  {
    p: "CASSIUS",
    l: "My answer back. Brutus hath rived my heart:",
    acl: "4.3.93",
  },
  {
    p: "CASSIUS",
    l: "A friend should bear his friend's infirmities,",
    acl: "4.3.94",
  },
  {
    p: "CASSIUS",
    l: "But Brutus makes mine greater than they are.",
    acl: "4.3.95",
  },
  {
    p: "BRUTUS",
    l: "I do not, till you practise them on me.",
    acl: "4.3.96",
  },
  { p: "CASSIUS", l: "You love me not.", acl: "4.3.97" },
  {
    p: "BRUTUS",
    l: "I do not like your faults.",
    acl: "4.3.98",
  },
  {
    p: "CASSIUS",
    l: "A friendly eye could never see such faults.",
    acl: "4.3.99",
  },
  {
    p: "BRUTUS",
    l: "A flatterer's would not, though they do appear",
    acl: "4.3.100",
  },
  { p: "BRUTUS", l: "As huge as high Olympus.", acl: "4.3.101" },
  {
    p: "CASSIUS",
    l: "Come, Antony, and young Octavius, come,",
    acl: "4.3.102",
  },
  { p: "CASSIUS", l: "Revenge yourselves alone on Cassius,", acl: "4.3.103" },
  {
    p: "CASSIUS",
    l: "For Cassius is aweary of the world,",
    acl: "4.3.104",
  },
  {
    p: "CASSIUS",
    l: "Hated by one he loves, braved by his brother,",
    acl: "4.3.105",
  },
  {
    p: "CASSIUS",
    l: "Cheque'd like a bondman, all his faults observed,",
    acl: "4.3.106",
  },
  {
    p: "CASSIUS",
    l: "Set in a note-book, learn'd, and conn'd by rote,",
    acl: "4.3.107",
  },
  {
    p: "CASSIUS",
    l: "To cast into my teeth. O, I could weep",
    acl: "4.3.108",
  },
  {
    p: "CASSIUS",
    l: "My spirit from mine eyes! There is my dagger,",
    acl: "4.3.109",
  },
  {
    p: "CASSIUS",
    l: "And here my naked breast, within, a heart",
    acl: "4.3.110",
  },
  {
    p: "CASSIUS",
    l: "Dearer than Plutus' mine, richer than gold:",
    acl: "4.3.111",
  },
  {
    p: "CASSIUS",
    l: "If that thou be'st a Roman, take it forth,",
    acl: "4.3.112",
  },
  {
    p: "CASSIUS",
    l: "I, that denied thee gold, will give my heart:",
    acl: "4.3.113",
  },
  {
    p: "CASSIUS",
    l: "Strike, as thou didst at Caesar, for, I know,",
    acl: "4.3.114",
  },
  {
    p: "CASSIUS",
    l: "When thou didst hate him worst, thou lovedst him better",
    acl: "4.3.115",
  },
  {
    p: "CASSIUS",
    l: "Than ever thou lovedst Cassius.",
    acl: "4.3.116",
  },
  { p: "BRUTUS", l: "Sheathe your dagger:", acl: "4.3.117" },
  {
    p: "BRUTUS",
    l: "Be angry when you will, it shall have scope,",
    acl: "4.3.118",
  },
  {
    p: "BRUTUS",
    l: "Do what you will, dishonour shall be humour.",
    acl: "4.3.119",
  },
  {
    p: "BRUTUS",
    l: "O Cassius, you are yoked with a lamb",
    acl: "4.3.120",
  },
  {
    p: "BRUTUS",
    l: "That carries anger as the flint bears fire,",
    acl: "4.3.121",
  },
  {
    p: "BRUTUS",
    l: "Who, much enforced, shows a hasty spark,",
    acl: "4.3.122",
  },
  { p: "BRUTUS", l: "And straight is cold again.", acl: "4.3.123" },
  {
    p: "CASSIUS",
    l: "Hath Cassius lived",
    acl: "4.3.124",
  },
  {
    p: "CASSIUS",
    l: "To be but mirth and laughter to his Brutus,",
    acl: "4.3.125",
  },
  {
    p: "CASSIUS",
    l: "When grief, and blood ill-temper'd, vexeth him?",
    acl: "4.3.126",
  },
  {
    p: "BRUTUS",
    l: "When I spoke that, I was ill-temper'd too.",
    acl: "4.3.127",
  },
  {
    p: "CASSIUS",
    l: "Do you confess so much? Give me your hand.",
    acl: "4.3.128",
  },
  { p: "BRUTUS", l: "And my heart too.", acl: "4.3.129" },
  {
    p: "CASSIUS",
    l: "O Brutus!",
    acl: "4.3.130",
  },
  { p: "BRUTUS", l: "What's the matter?", acl: "4.3.131" },
  {
    p: "CASSIUS",
    l: "Have not you love enough to bear with me,",
    acl: "4.3.132",
  },
  {
    p: "CASSIUS",
    l: "When that rash humour which my mother gave me",
    acl: "4.3.133",
  },
  {
    p: "CASSIUS",
    l: "Makes me forgetful?",
    acl: "4.3.134",
  },
  { p: "BRUTUS", l: "Yes, Cassius, and, from henceforth,", acl: "4.3.135" },
  {
    p: "BRUTUS",
    l: "When you are over-earnest with your Brutus,",
    acl: "4.3.136",
  },
  {
    p: "BRUTUS",
    l: "He'll think your mother chides, and leave you so.",
    acl: "4.3.137",
  },
  {
    p: "Poet",
    l: "[Within]  Let me go in to see the generals,",
    acl: "4.3.138",
  },
  {
    p: "Poet",
    l: "There is some grudge between 'em, 'tis not meet",
    acl: "4.3.139",
  },
  {
    p: "Poet",
    l: "They be alone.",
    acl: "4.3.140",
  },
  { p: "LUCILIUS", l: "[Within]  You shall not come to them.", acl: "4.3.141" },
  {
    p: "Poet",
    l: "[Within]  Nothing but death shall stay me.",
    acl: "4.3.142",
  },
  {
    p: "Poet",
    l: "Enter Poet, followed by LUCILIUS, TITINIUS, and LUCIUS",
    acl: "",
  },
  {
    p: "CASSIUS",
    l: "How now! what's the matter?",
    acl: "4.3.143",
  },
  {
    p: "Poet",
    l: "For shame, you generals! what do you mean?",
    acl: "4.3.144",
  },
  {
    p: "Poet",
    l: "Love, and be friends, as two such men should be,",
    acl: "4.3.145",
  },
  {
    p: "Poet",
    l: "For I have seen more years, I'm sure, than ye.",
    acl: "4.3.146",
  },
  {
    p: "CASSIUS",
    l: "Ha, ha! how vilely doth this cynic rhyme!",
    acl: "4.3.147",
  },
  {
    p: "BRUTUS",
    l: "Get you hence, sirrah, saucy fellow, hence!",
    acl: "4.3.148",
  },
  {
    p: "CASSIUS",
    l: "Bear with him, Brutus, 'tis his fashion.",
    acl: "4.3.149",
  },
  {
    p: "BRUTUS",
    l: "I'll know his humour, when he knows his time:",
    acl: "4.3.150",
  },
  {
    p: "BRUTUS",
    l: "What should the wars do with these jigging fools?",
    acl: "4.3.151",
  },
  { p: "BRUTUS", l: "Companion, hence!", acl: "4.3.152" },
  {
    p: "CASSIUS",
    l: "Away, away, be gone.",
    acl: "4.3.153",
  },
  { p: "CASSIUS", l: "Exit Poet", acl: "" },
  {
    p: "BRUTUS",
    l: "Lucilius and Titinius, bid the commanders",
    acl: "4.3.154",
  },
  {
    p: "BRUTUS",
    l: "Prepare to lodge their companies to-night.",
    acl: "4.3.155",
  },
  {
    p: "CASSIUS",
    l: "And come yourselves, and bring Messala with you",
    acl: "4.3.156",
  },
  { p: "CASSIUS", l: "Immediately to us.", acl: "4.3.157" },
  {
    p: "CASSIUS",
    l: "Exeunt LUCILIUS and TITINIUS",
    acl: "",
  },
  { p: "BRUTUS", l: "Lucius, a bowl of wine!", acl: "4.3.158" },
  {
    p: "BRUTUS",
    l: "Exit LUCIUS",
    acl: "",
  },
  {
    p: "CASSIUS",
    l: "I did not think you could have been so angry.",
    acl: "4.3.159",
  },
  {
    p: "BRUTUS",
    l: "O Cassius, I am sick of many griefs.",
    acl: "4.3.160",
  },
  { p: "CASSIUS", l: "Of your philosophy you make no use,", acl: "4.3.161" },
  {
    p: "CASSIUS",
    l: "If you give place to accidental evils.",
    acl: "4.3.162",
  },
  {
    p: "BRUTUS",
    l: "No man bears sorrow better. Portia is dead.",
    acl: "4.3.163",
  },
  {
    p: "CASSIUS",
    l: "Ha! Portia!",
    acl: "4.3.164",
  },
  { p: "BRUTUS", l: "She is dead.", acl: "4.3.165" },
  {
    p: "CASSIUS",
    l: "How 'scaped I killing when I cross'd you so?",
    acl: "4.3.166",
  },
  { p: "CASSIUS", l: "O insupportable and touching loss!", acl: "4.3.167" },
  {
    p: "CASSIUS",
    l: "Upon what sickness?",
    acl: "4.3.168",
  },
  { p: "BRUTUS", l: "Impatient of my absence,", acl: "4.3.169" },
  {
    p: "BRUTUS",
    l: "And grief that young Octavius with Mark Antony",
    acl: "4.3.170",
  },
  {
    p: "BRUTUS",
    l: "Have made themselves so strong:--for with her death",
    acl: "4.3.171",
  },
  {
    p: "BRUTUS",
    l: "That tidings came,--with this she fell distract,",
    acl: "4.3.172",
  },
  {
    p: "BRUTUS",
    l: "And, her attendants absent, swallow'd fire.",
    acl: "4.3.173",
  },
  {
    p: "CASSIUS",
    l: "And died so?",
    acl: "4.3.174",
  },
  { p: "BRUTUS", l: "Even so.", acl: "4.3.175" },
  {
    p: "CASSIUS",
    l: "O ye immortal gods!",
    acl: "4.3.176",
  },
  { p: "CASSIUS", l: "Re-enter LUCIUS, with wine and taper", acl: "" },
  {
    p: "BRUTUS",
    l: "Speak no more of her. Give me a bowl of wine.",
    acl: "4.3.177",
  },
  { p: "BRUTUS", l: "In this I bury all unkindness, Cassius.", acl: "4.3.178" },
  {
    p: "CASSIUS",
    l: "My heart is thirsty for that noble pledge.",
    acl: "4.3.179",
  },
  {
    p: "CASSIUS",
    l: "Fill, Lucius, till the wine o'erswell the cup,",
    acl: "4.3.180",
  },
  {
    p: "CASSIUS",
    l: "I cannot drink too much of Brutus' love.",
    acl: "4.3.181",
  },
  { p: "BRUTUS", l: "Come in, Titinius!", acl: "4.3.182" },
  {
    p: "BRUTUS",
    l: "Exit LUCIUS",
    acl: "",
  },
  { p: "BRUTUS", l: "Re-enter TITINIUS, with MESSALA", acl: "" },
  {
    p: "BRUTUS",
    l: "Welcome, good Messala.",
    acl: "4.3.183",
  },
  { p: "BRUTUS", l: "Now sit we close about this taper here,", acl: "4.3.184" },
  {
    p: "BRUTUS",
    l: "And call in question our necessities.",
    acl: "4.3.185",
  },
  { p: "CASSIUS", l: "Portia, art thou gone?", acl: "4.3.186" },
  {
    p: "BRUTUS",
    l: "No more, I pray you.",
    acl: "4.3.187",
  },
  { p: "BRUTUS", l: "Messala, I have here received letters,", acl: "4.3.188" },
  {
    p: "BRUTUS",
    l: "That young Octavius and Mark Antony",
    acl: "4.3.189",
  },
  { p: "BRUTUS", l: "Come down upon us with a mighty power,", acl: "4.3.190" },
  {
    p: "BRUTUS",
    l: "Bending their expedition toward Philippi.",
    acl: "4.3.191",
  },
  {
    p: "MESSALA",
    l: "Myself have letters of the selfsame tenor.",
    acl: "4.3.192",
  },
  {
    p: "BRUTUS",
    l: "With what addition?",
    acl: "4.3.193",
  },
  {
    p: "MESSALA",
    l: "That by proscription and bills of outlawry,",
    acl: "4.3.194",
  },
  {
    p: "MESSALA",
    l: "Octavius, Antony, and Lepidus,",
    acl: "4.3.195",
  },
  { p: "MESSALA", l: "Have put to death an hundred senators.", acl: "4.3.196" },
  {
    p: "BRUTUS",
    l: "Therein our letters do not well agree,",
    acl: "4.3.197",
  },
  {
    p: "BRUTUS",
    l: "Mine speak of seventy senators that died",
    acl: "4.3.198",
  },
  {
    p: "BRUTUS",
    l: "By their proscriptions, Cicero being one.",
    acl: "4.3.199",
  },
  { p: "CASSIUS", l: "Cicero one!", acl: "4.3.200" },
  {
    p: "MESSALA",
    l: "Cicero is dead,",
    acl: "4.3.201",
  },
  { p: "MESSALA", l: "And by that order of proscription.", acl: "4.3.202" },
  {
    p: "MESSALA",
    l: "Had you your letters from your wife, my lord?",
    acl: "4.3.203",
  },
  { p: "BRUTUS", l: "No, Messala.", acl: "4.3.204" },
  {
    p: "MESSALA",
    l: "Nor nothing in your letters writ of her?",
    acl: "4.3.205",
  },
  { p: "BRUTUS", l: "Nothing, Messala.", acl: "4.3.206" },
  {
    p: "MESSALA",
    l: "That, methinks, is strange.",
    acl: "4.3.207",
  },
  {
    p: "BRUTUS",
    l: "Why ask you? hear you aught of her in yours?",
    acl: "4.3.208",
  },
  {
    p: "MESSALA",
    l: "No, my lord.",
    acl: "4.3.209",
  },
  { p: "BRUTUS", l: "Now, as you are a Roman, tell me true.", acl: "4.3.210" },
  {
    p: "MESSALA",
    l: "Then like a Roman bear the truth I tell:",
    acl: "4.3.211",
  },
  {
    p: "MESSALA",
    l: "For certain she is dead, and by strange manner.",
    acl: "4.3.212",
  },
  {
    p: "BRUTUS",
    l: "Why, farewell, Portia. We must die, Messala:",
    acl: "4.3.213",
  },
  { p: "BRUTUS", l: "With meditating that she must die once,", acl: "4.3.214" },
  {
    p: "BRUTUS",
    l: "I have the patience to endure it now.",
    acl: "4.3.215",
  },
  {
    p: "MESSALA",
    l: "Even so great men great losses should endure.",
    acl: "4.3.216",
  },
  {
    p: "CASSIUS",
    l: "I have as much of this in art as you,",
    acl: "4.3.217",
  },
  {
    p: "CASSIUS",
    l: "But yet my nature could not bear it so.",
    acl: "4.3.218",
  },
  {
    p: "BRUTUS",
    l: "Well, to our work alive. What do you think",
    acl: "4.3.219",
  },
  { p: "BRUTUS", l: "Of marching to Philippi presently?", acl: "4.3.220" },
  {
    p: "CASSIUS",
    l: "I do not think it good.",
    acl: "4.3.221",
  },
  { p: "BRUTUS", l: "Your reason?", acl: "4.3.222" },
  {
    p: "CASSIUS",
    l: "This it is:",
    acl: "4.3.223",
  },
  { p: "CASSIUS", l: "'Tis better that the enemy seek us:", acl: "4.3.224" },
  {
    p: "CASSIUS",
    l: "So shall he waste his means, weary his soldiers,",
    acl: "4.3.225",
  },
  {
    p: "CASSIUS",
    l: "Doing himself offence, whilst we, lying still,",
    acl: "4.3.226",
  },
  {
    p: "CASSIUS",
    l: "Are full of rest, defense, and nimbleness.",
    acl: "4.3.227",
  },
  {
    p: "BRUTUS",
    l: "Good reasons must, of force, give place to better.",
    acl: "4.3.228",
  },
  {
    p: "BRUTUS",
    l: "The people 'twixt Philippi and this ground",
    acl: "4.3.229",
  },
  { p: "BRUTUS", l: "Do stand but in a forced affection,", acl: "4.3.230" },
  {
    p: "BRUTUS",
    l: "For they have grudged us contribution:",
    acl: "4.3.231",
  },
  { p: "BRUTUS", l: "The enemy, marching along by them,", acl: "4.3.232" },
  {
    p: "BRUTUS",
    l: "By them shall make a fuller number up,",
    acl: "4.3.233",
  },
  {
    p: "BRUTUS",
    l: "Come on refresh'd, new-added, and encouraged,",
    acl: "4.3.234",
  },
  {
    p: "BRUTUS",
    l: "From which advantage shall we cut him off,",
    acl: "4.3.235",
  },
  { p: "BRUTUS", l: "If at Philippi we do face him there,", acl: "4.3.236" },
  {
    p: "BRUTUS",
    l: "These people at our back.",
    acl: "4.3.237",
  },
  { p: "CASSIUS", l: "Hear me, good brother.", acl: "4.3.238" },
  {
    p: "BRUTUS",
    l: "Under your pardon. You must note beside,",
    acl: "4.3.239",
  },
  {
    p: "BRUTUS",
    l: "That we have tried the utmost of our friends,",
    acl: "4.3.240",
  },
  {
    p: "BRUTUS",
    l: "Our legions are brim-full, our cause is ripe:",
    acl: "4.3.241",
  },
  { p: "BRUTUS", l: "The enemy increaseth every day,", acl: "4.3.242" },
  {
    p: "BRUTUS",
    l: "We, at the height, are ready to decline.",
    acl: "4.3.243",
  },
  { p: "BRUTUS", l: "There is a tide in the affairs of men,", acl: "4.3.244" },
  {
    p: "BRUTUS",
    l: "Which, taken at the flood, leads on to fortune,",
    acl: "4.3.245",
  },
  { p: "BRUTUS", l: "Omitted, all the voyage of their life", acl: "4.3.246" },
  {
    p: "BRUTUS",
    l: "Is bound in shallows and in miseries.",
    acl: "4.3.247",
  },
  { p: "BRUTUS", l: "On such a full sea are we now afloat,", acl: "4.3.248" },
  {
    p: "BRUTUS",
    l: "And we must take the current when it serves,",
    acl: "4.3.249",
  },
  { p: "BRUTUS", l: "Or lose our ventures.", acl: "4.3.250" },
  {
    p: "CASSIUS",
    l: "Then, with your will, go on,",
    acl: "4.3.251",
  },
  {
    p: "CASSIUS",
    l: "We'll along ourselves, and meet them at Philippi.",
    acl: "4.3.252",
  },
  {
    p: "BRUTUS",
    l: "The deep of night is crept upon our talk,",
    acl: "4.3.253",
  },
  { p: "BRUTUS", l: "And nature must obey necessity,", acl: "4.3.254" },
  {
    p: "BRUTUS",
    l: "Which we will niggard with a little rest.",
    acl: "4.3.255",
  },
  { p: "BRUTUS", l: "There is no more to say?", acl: "4.3.256" },
  {
    p: "CASSIUS",
    l: "No more. Good night:",
    acl: "4.3.257",
  },
  {
    p: "CASSIUS",
    l: "Early to-morrow will we rise, and hence.",
    acl: "4.3.258",
  },
  {
    p: "BRUTUS",
    l: "Lucius!",
    acl: "4.3.259",
  },
  { p: "BRUTUS", l: "Enter LUCIUS", acl: "" },
  { p: "BRUTUS", l: "My gown.", acl: "4.3.260" },
  {
    p: "BRUTUS",
    l: "Exit LUCIUS",
    acl: "",
  },
  { p: "BRUTUS", l: "Farewell, good Messala:", acl: "4.3.261" },
  {
    p: "BRUTUS",
    l: "Good night, Titinius. Noble, noble Cassius,",
    acl: "4.3.262",
  },
  { p: "BRUTUS", l: "Good night, and good repose.", acl: "4.3.263" },
  {
    p: "CASSIUS",
    l: "O my dear brother!",
    acl: "4.3.264",
  },
  {
    p: "CASSIUS",
    l: "This was an ill beginning of the night:",
    acl: "4.3.265",
  },
  {
    p: "CASSIUS",
    l: "Never come such division 'tween our souls!",
    acl: "4.3.266",
  },
  { p: "CASSIUS", l: "Let it not, Brutus.", acl: "4.3.267" },
  {
    p: "BRUTUS",
    l: "Every thing is well.",
    acl: "4.3.268",
  },
  { p: "CASSIUS", l: "Good night, my lord.", acl: "4.3.269" },
  {
    p: "BRUTUS",
    l: "Good night, good brother.",
    acl: "4.3.270",
  },
  { p: "MESSALA", l: "Good night, Lord Brutus.", acl: "4.3.271" },
  {
    p: "BRUTUS",
    l: "Farewell, every one.",
    acl: "4.3.272",
  },
  { p: "BRUTUS", l: "Exeunt all but BRUTUS", acl: "" },
  {
    p: "BRUTUS",
    l: "Re-enter LUCIUS, with the gown",
    acl: "",
  },
  {
    p: "BRUTUS",
    l: "Give me the gown. Where is thy instrument?",
    acl: "4.3.273",
  },
  {
    p: "LUCIUS",
    l: "Here in the tent.",
    acl: "4.3.274",
  },
  { p: "BRUTUS", l: "What, thou speak'st drowsily?", acl: "4.3.275" },
  {
    p: "BRUTUS",
    l: "Poor knave, I blame thee not, thou art o'er-watch'd.",
    acl: "4.3.276",
  },
  { p: "BRUTUS", l: "Call Claudius and some other of my men:", acl: "4.3.277" },
  {
    p: "BRUTUS",
    l: "I'll have them sleep on cushions in my tent.",
    acl: "4.3.278",
  },
  { p: "LUCIUS", l: "Varro and Claudius!", acl: "4.3.279" },
  {
    p: "LUCIUS",
    l: "Enter VARRO and CLAUDIUS",
    acl: "",
  },
  { p: "VARRO", l: "Calls my lord?", acl: "4.3.280" },
  {
    p: "BRUTUS",
    l: "I pray you, sirs, lie in my tent and sleep,",
    acl: "4.3.281",
  },
  { p: "BRUTUS", l: "It may be I shall raise you by and by", acl: "4.3.282" },
  {
    p: "BRUTUS",
    l: "On business to my brother Cassius.",
    acl: "4.3.283",
  },
  {
    p: "VARRO",
    l: "So please you, we will stand and watch your pleasure.",
    acl: "4.3.284",
  },
  {
    p: "BRUTUS",
    l: "I will not have it so: lie down, good sirs,",
    acl: "4.3.285",
  },
  { p: "BRUTUS", l: "It may be I shall otherwise bethink me.", acl: "4.3.286" },
  {
    p: "BRUTUS",
    l: "Look, Lucius, here's the book I sought for so,",
    acl: "4.3.287",
  },
  { p: "BRUTUS", l: "I put it in the pocket of my gown.", acl: "4.3.288" },
  {
    p: "BRUTUS",
    l: "VARRO and CLAUDIUS lie down",
    acl: "",
  },
  {
    p: "LUCIUS",
    l: "I was sure your lordship did not give it me.",
    acl: "4.3.289",
  },
  {
    p: "BRUTUS",
    l: "Bear with me, good boy, I am much forgetful.",
    acl: "4.3.290",
  },
  {
    p: "BRUTUS",
    l: "Canst thou hold up thy heavy eyes awhile,",
    acl: "4.3.291",
  },
  {
    p: "BRUTUS",
    l: "And touch thy instrument a strain or two?",
    acl: "4.3.292",
  },
  { p: "LUCIUS", l: "Ay, my lord, an't please you.", acl: "4.3.293" },
  {
    p: "BRUTUS",
    l: "It does, my boy:",
    acl: "4.3.294",
  },
  {
    p: "BRUTUS",
    l: "I trouble thee too much, but thou art willing.",
    acl: "4.3.295",
  },
  {
    p: "LUCIUS",
    l: "It is my duty, sir.",
    acl: "4.3.296",
  },
  {
    p: "BRUTUS",
    l: "I should not urge thy duty past thy might,",
    acl: "4.3.297",
  },
  {
    p: "BRUTUS",
    l: "I know young bloods look for a time of rest.",
    acl: "4.3.298",
  },
  { p: "LUCIUS", l: "I have slept, my lord, already.", acl: "4.3.299" },
  {
    p: "BRUTUS",
    l: "It was well done, and thou shalt sleep again,",
    acl: "4.3.300",
  },
  {
    p: "BRUTUS",
    l: "I will not hold thee long: if I do live,",
    acl: "4.3.301",
  },
  {
    p: "BRUTUS",
    l: "I will be good to thee.",
    acl: "4.3.302",
  },
  { p: "BRUTUS", l: "Music, and a song", acl: "" },
  {
    p: "BRUTUS",
    l: "This is a sleepy tune. O murderous slumber,",
    acl: "4.3.303",
  },
  {
    p: "BRUTUS",
    l: "Lay'st thou thy leaden mace upon my boy,",
    acl: "4.3.304",
  },
  {
    p: "BRUTUS",
    l: "That plays thee music? Gentle knave, good night,",
    acl: "4.3.305",
  },
  {
    p: "BRUTUS",
    l: "I will not do thee so much wrong to wake thee:",
    acl: "4.3.306",
  },
  {
    p: "BRUTUS",
    l: "If thou dost nod, thou break'st thy instrument,",
    acl: "4.3.307",
  },
  {
    p: "BRUTUS",
    l: "I'll take it from thee, and, good boy, good night.",
    acl: "4.3.308",
  },
  {
    p: "BRUTUS",
    l: "Let me see, let me see, is not the leaf turn'd down",
    acl: "4.3.309",
  },
  {
    p: "BRUTUS",
    l: "Where I left reading? Here it is, I think.",
    acl: "4.3.310",
  },
  {
    p: "BRUTUS",
    l: "Enter the Ghost of CAESAR",
    acl: "",
  },
  {
    p: "BRUTUS",
    l: "How ill this taper burns! Ha! who comes here?",
    acl: "4.3.311",
  },
  {
    p: "BRUTUS",
    l: "I think it is the weakness of mine eyes",
    acl: "4.3.312",
  },
  { p: "BRUTUS", l: "That shapes this monstrous apparition.", acl: "4.3.313" },
  {
    p: "BRUTUS",
    l: "It comes upon me. Art thou any thing?",
    acl: "4.3.314",
  },
  {
    p: "BRUTUS",
    l: "Art thou some god, some angel, or some devil,",
    acl: "4.3.315",
  },
  {
    p: "BRUTUS",
    l: "That makest my blood cold and my hair to stare?",
    acl: "4.3.316",
  },
  { p: "BRUTUS", l: "Speak to me what thou art.", acl: "4.3.317" },
  {
    p: "GHOST",
    l: "Thy evil spirit, Brutus.",
    acl: "4.3.318",
  },
  { p: "BRUTUS", l: "Why comest thou?", acl: "4.3.319" },
  {
    p: "GHOST",
    l: "To tell thee thou shalt see me at Philippi.",
    acl: "4.3.320",
  },
  { p: "BRUTUS", l: "Well, then I shall see thee again?", acl: "4.3.321" },
  {
    p: "GHOST",
    l: "Ay, at Philippi.",
    acl: "4.3.322",
  },
  { p: "BRUTUS", l: "Why, I will see thee at Philippi, then.", acl: "4.3.323" },
  {
    p: "BRUTUS",
    l: "Exit Ghost",
    acl: "",
  },
  { p: "BRUTUS", l: "Now I have taken heart thou vanishest:", acl: "4.3.324" },
  {
    p: "BRUTUS",
    l: "Ill spirit, I would hold more talk with thee.",
    acl: "4.3.325",
  },
  {
    p: "BRUTUS",
    l: "Boy, Lucius! Varro! Claudius! Sirs, awake! Claudius!",
    acl: "4.3.326",
  },
  {
    p: "LUCIUS",
    l: "The strings, my lord, are false.",
    acl: "4.3.327",
  },
  {
    p: "BRUTUS",
    l: "He thinks he still is at his instrument.",
    acl: "4.3.328",
  },
  {
    p: "BRUTUS",
    l: "Lucius, awake!",
    acl: "4.3.329",
  },
  { p: "LUCIUS", l: "My lord?", acl: "4.3.330" },
  {
    p: "BRUTUS",
    l: "Didst thou dream, Lucius, that thou so criedst out?",
    acl: "4.3.331",
  },
  { p: "LUCIUS", l: "My lord, I do not know that I did cry.", acl: "4.3.332" },
  {
    p: "BRUTUS",
    l: "Yes, that thou didst: didst thou see any thing?",
    acl: "4.3.333",
  },
  { p: "LUCIUS", l: "Nothing, my lord.", acl: "4.3.334" },
  {
    p: "BRUTUS",
    l: "Sleep again, Lucius. Sirrah Claudius!",
    acl: "4.3.335",
  },
  { p: "BRUTUS", l: "To VARRO", acl: "" },
  {
    p: "BRUTUS",
    l: "Fellow thou, awake!",
    acl: "4.3.336",
  },
  { p: "VARRO", l: "My lord?", acl: "4.3.337" },
  {
    p: "CLAUDIUS",
    l: "My lord?",
    acl: "4.3.338",
  },
  {
    p: "BRUTUS",
    l: "Why did you so cry out, sirs, in your sleep?",
    acl: "4.3.339",
  },
  {
    p: "CLAUDIUS",
    l: "Did we, my lord?",
    acl: "4.3.340",
  },
  { p: "BRUTUS", l: "Ay: saw you any thing?", acl: "4.3.341" },
  {
    p: "VARRO",
    l: "No, my lord, I saw nothing.",
    acl: "4.3.342",
  },
  { p: "CLAUDIUS", l: "Nor I, my lord.", acl: "4.3.343" },
  {
    p: "BRUTUS",
    l: "Go and commend me to my brother Cassius,",
    acl: "4.3.344",
  },
  {
    p: "BRUTUS",
    l: "Bid him set on his powers betimes before,",
    acl: "4.3.345",
  },
  {
    p: "BRUTUS",
    l: "And we will follow.",
    acl: "4.3.346",
  },
  { p: "CLAUDIUS", l: "It shall be done, my lord.", acl: "4.3.347" },
  {
    p: "CLAUDIUS",
    l: "Exeunt",
    acl: "",
  },
  { p: "CLAUDIUS", l: "ACT V", acl: "" },
  {
    p: "CLAUDIUS",
    l: "SCENE I. The plains of Philippi.",
    acl: "",
  },
  { p: "CLAUDIUS", l: "Enter OCTAVIUS, ANTONY, and their army", acl: "" },
  {
    p: "OCTAVIUS",
    l: "Now, Antony, our hopes are answered:",
    acl: "5.1.1",
  },
  { p: "OCTAVIUS", l: "You said the enemy would not come down,", acl: "5.1.2" },
  {
    p: "OCTAVIUS",
    l: "But keep the hills and upper regions,",
    acl: "5.1.3",
  },
  {
    p: "OCTAVIUS",
    l: "It proves not so: their battles are at hand,",
    acl: "5.1.4",
  },
  {
    p: "OCTAVIUS",
    l: "They mean to warn us at Philippi here,",
    acl: "5.1.5",
  },
  { p: "OCTAVIUS", l: "Answering before we do demand of them.", acl: "5.1.6" },
  {
    p: "ANTONY",
    l: "Tut, I am in their bosoms, and I know",
    acl: "5.1.7",
  },
  {
    p: "ANTONY",
    l: "Wherefore they do it: they could be content",
    acl: "5.1.8",
  },
  {
    p: "ANTONY",
    l: "To visit other places, and come down",
    acl: "5.1.9",
  },
  {
    p: "ANTONY",
    l: "With fearful bravery, thinking by this face",
    acl: "5.1.10",
  },
  {
    p: "ANTONY",
    l: "To fasten in our thoughts that they have courage,",
    acl: "5.1.11",
  },
  { p: "ANTONY", l: "But 'tis not so.", acl: "5.1.12" },
  {
    p: "ANTONY",
    l: "Enter a Messenger",
    acl: "",
  },
  { p: "Messenger", l: "Prepare you, generals:", acl: "5.1.13" },
  {
    p: "Messenger",
    l: "The enemy comes on in gallant show,",
    acl: "5.1.14",
  },
  {
    p: "Messenger",
    l: "Their bloody sign of battle is hung out,",
    acl: "5.1.15",
  },
  {
    p: "Messenger",
    l: "And something to be done immediately.",
    acl: "5.1.16",
  },
  { p: "ANTONY", l: "Octavius, lead your battle softly on,", acl: "5.1.17" },
  {
    p: "ANTONY",
    l: "Upon the left hand of the even field.",
    acl: "5.1.18",
  },
  {
    p: "OCTAVIUS",
    l: "Upon the right hand I, keep thou the left.",
    acl: "5.1.19",
  },
  {
    p: "ANTONY",
    l: "Why do you cross me in this exigent?",
    acl: "5.1.20",
  },
  { p: "OCTAVIUS", l: "I do not cross you, but I will do so.", acl: "5.1.21" },
  {
    p: "OCTAVIUS",
    l: "March",
    acl: "",
  },
  {
    p: "OCTAVIUS",
    l: "Drum. Enter BRUTUS, CASSIUS, and their Army, LUCILIUS, TITINIUS, MESSALA, and others",
    acl: "",
  },
  { p: "BRUTUS", l: "They stand, and would have parley.", acl: "5.1.22" },
  {
    p: "CASSIUS",
    l: "Stand fast, Titinius: we must out and talk.",
    acl: "5.1.23",
  },
  {
    p: "OCTAVIUS",
    l: "Mark Antony, shall we give sign of battle?",
    acl: "5.1.24",
  },
  {
    p: "ANTONY",
    l: "No, Caesar, we will answer on their charge.",
    acl: "5.1.25",
  },
  {
    p: "ANTONY",
    l: "Make forth, the generals would have some words.",
    acl: "5.1.26",
  },
  {
    p: "OCTAVIUS",
    l: "Stir not until the signal.",
    acl: "5.1.27",
  },
  {
    p: "BRUTUS",
    l: "Words before blows: is it so, countrymen?",
    acl: "5.1.28",
  },
  {
    p: "OCTAVIUS",
    l: "Not that we love words better, as you do.",
    acl: "5.1.29",
  },
  {
    p: "BRUTUS",
    l: "Good words are better than bad strokes, Octavius.",
    acl: "5.1.30",
  },
  {
    p: "ANTONY",
    l: "In your bad strokes, Brutus, you give good words:",
    acl: "5.1.31",
  },
  {
    p: "ANTONY",
    l: "Witness the hole you made in Caesar's heart,",
    acl: "5.1.32",
  },
  {
    p: "ANTONY",
    l: "Crying 'Long live! hail, Caesar!'",
    acl: "5.1.33",
  },
  { p: "CASSIUS", l: "Antony,", acl: "5.1.34" },
  {
    p: "CASSIUS",
    l: "The posture of your blows are yet unknown,",
    acl: "5.1.35",
  },
  {
    p: "CASSIUS",
    l: "But for your words, they rob the Hybla bees,",
    acl: "5.1.36",
  },
  {
    p: "CASSIUS",
    l: "And leave them honeyless.",
    acl: "5.1.37",
  },
  { p: "ANTONY", l: "Not stingless too.", acl: "5.1.38" },
  {
    p: "BRUTUS",
    l: "O, yes, and soundless too,",
    acl: "5.1.39",
  },
  {
    p: "BRUTUS",
    l: "For you have stol'n their buzzing, Antony,",
    acl: "5.1.40",
  },
  {
    p: "BRUTUS",
    l: "And very wisely threat before you sting.",
    acl: "5.1.41",
  },
  {
    p: "ANTONY",
    l: "Villains, you did not so, when your vile daggers",
    acl: "5.1.42",
  },
  {
    p: "ANTONY",
    l: "Hack'd one another in the sides of Caesar:",
    acl: "5.1.43",
  },
  {
    p: "ANTONY",
    l: "You show'd your teeth like apes, and fawn'd like hounds,",
    acl: "5.1.44",
  },
  {
    p: "ANTONY",
    l: "And bow'd like bondmen, kissing Caesar's feet,",
    acl: "5.1.45",
  },
  { p: "ANTONY", l: "Whilst damned Casca, like a cur, behind", acl: "5.1.46" },
  {
    p: "ANTONY",
    l: "Struck Caesar on the neck. O you flatterers!",
    acl: "5.1.47",
  },
  {
    p: "CASSIUS",
    l: "Flatterers! Now, Brutus, thank yourself:",
    acl: "5.1.48",
  },
  {
    p: "CASSIUS",
    l: "This tongue had not offended so to-day,",
    acl: "5.1.49",
  },
  { p: "CASSIUS", l: "If Cassius might have ruled.", acl: "5.1.50" },
  {
    p: "OCTAVIUS",
    l: "Come, come, the cause: if arguing make us sweat,",
    acl: "5.1.51",
  },
  {
    p: "OCTAVIUS",
    l: "The proof of it will turn to redder drops. Look,",
    acl: "5.1.52",
  },
  {
    p: "OCTAVIUS",
    l: "I draw a sword against conspirators,",
    acl: "5.1.53",
  },
  {
    p: "OCTAVIUS",
    l: "When think you that the sword goes up again?",
    acl: "5.1.54",
  },
  {
    p: "OCTAVIUS",
    l: "Never, till Caesar's three and thirty wounds",
    acl: "5.1.55",
  },
  {
    p: "OCTAVIUS",
    l: "Be well avenged, or till another Caesar",
    acl: "5.1.56",
  },
  {
    p: "OCTAVIUS",
    l: "Have added slaughter to the sword of traitors.",
    acl: "5.1.57",
  },
  {
    p: "BRUTUS",
    l: "Caesar, thou canst not die by traitors' hands,",
    acl: "5.1.58",
  },
  {
    p: "BRUTUS",
    l: "Unless thou bring'st them with thee.",
    acl: "5.1.59",
  },
  { p: "OCTAVIUS", l: "So I hope,", acl: "5.1.60" },
  {
    p: "OCTAVIUS",
    l: "I was not born to die on Brutus' sword.",
    acl: "5.1.61",
  },
  {
    p: "BRUTUS",
    l: "O, if thou wert the noblest of thy strain,",
    acl: "5.1.62",
  },
  {
    p: "BRUTUS",
    l: "Young man, thou couldst not die more honourable.",
    acl: "5.1.63",
  },
  {
    p: "CASSIUS",
    l: "A peevish schoolboy, worthless of such honour,",
    acl: "5.1.64",
  },
  {
    p: "CASSIUS",
    l: "Join'd with a masker and a reveller!",
    acl: "5.1.65",
  },
  { p: "ANTONY", l: "Old Cassius still!", acl: "5.1.66" },
  {
    p: "OCTAVIUS",
    l: "Come, Antony, away!",
    acl: "5.1.67",
  },
  {
    p: "OCTAVIUS",
    l: "Defiance, traitors, hurl we in your teeth:",
    acl: "5.1.68",
  },
  {
    p: "OCTAVIUS",
    l: "If you dare fight to-day, come to the field,",
    acl: "5.1.69",
  },
  { p: "OCTAVIUS", l: "If not, when you have stomachs.", acl: "5.1.70" },
  {
    p: "OCTAVIUS",
    l: "Exeunt OCTAVIUS, ANTONY, and their army",
    acl: "",
  },
  {
    p: "CASSIUS",
    l: "Why, now, blow wind, swell billow and swim bark!",
    acl: "5.1.71",
  },
  {
    p: "CASSIUS",
    l: "The storm is up, and all is on the hazard.",
    acl: "5.1.72",
  },
  { p: "BRUTUS", l: "Ho, Lucilius! hark, a word with you.", acl: "5.1.73" },
  {
    p: "LUCILIUS",
    l: "[Standing forth]\tMy lord?",
    acl: "5.1.74",
  },
  { p: "LUCILIUS", l: "BRUTUS and LUCILIUS converse apart", acl: "" },
  {
    p: "CASSIUS",
    l: "Messala!",
    acl: "5.1.75",
  },
  { p: "MESSALA", l: "[Standing forth]  What says my general?", acl: "5.1.76" },
  {
    p: "CASSIUS",
    l: "Messala,",
    acl: "5.1.77",
  },
  { p: "CASSIUS", l: "This is my birth-day, as this very day", acl: "5.1.78" },
  {
    p: "CASSIUS",
    l: "Was Cassius born. Give me thy hand, Messala:",
    acl: "5.1.79",
  },
  {
    p: "CASSIUS",
    l: "Be thou my witness that against my will,",
    acl: "5.1.80",
  },
  {
    p: "CASSIUS",
    l: "As Pompey was, am I compell'd to set",
    acl: "5.1.81",
  },
  { p: "CASSIUS", l: "Upon one battle all our liberties.", acl: "5.1.82" },
  {
    p: "CASSIUS",
    l: "You know that I held Epicurus strong",
    acl: "5.1.83",
  },
  { p: "CASSIUS", l: "And his opinion: now I change my mind,", acl: "5.1.84" },
  {
    p: "CASSIUS",
    l: "And partly credit things that do presage.",
    acl: "5.1.85",
  },
  {
    p: "CASSIUS",
    l: "Coming from Sardis, on our former ensign",
    acl: "5.1.86",
  },
  {
    p: "CASSIUS",
    l: "Two mighty eagles fell, and there they perch'd,",
    acl: "5.1.87",
  },
  {
    p: "CASSIUS",
    l: "Gorging and feeding from our soldiers' hands,",
    acl: "5.1.88",
  },
  {
    p: "CASSIUS",
    l: "Who to Philippi here consorted us:",
    acl: "5.1.89",
  },
  {
    p: "CASSIUS",
    l: "This morning are they fled away and gone,",
    acl: "5.1.90",
  },
  {
    p: "CASSIUS",
    l: "And in their steads do ravens, crows and kites,",
    acl: "5.1.91",
  },
  {
    p: "CASSIUS",
    l: "Fly o'er our heads and downward look on us,",
    acl: "5.1.92",
  },
  {
    p: "CASSIUS",
    l: "As we were sickly prey: their shadows seem",
    acl: "5.1.93",
  },
  { p: "CASSIUS", l: "A canopy most fatal, under which", acl: "5.1.94" },
  {
    p: "CASSIUS",
    l: "Our army lies, ready to give up the ghost.",
    acl: "5.1.95",
  },
  { p: "MESSALA", l: "Believe not so.", acl: "5.1.96" },
  {
    p: "CASSIUS",
    l: "I but believe it partly,",
    acl: "5.1.97",
  },
  { p: "CASSIUS", l: "For I am fresh of spirit and resolved", acl: "5.1.98" },
  {
    p: "CASSIUS",
    l: "To meet all perils very constantly.",
    acl: "5.1.99",
  },
  { p: "BRUTUS", l: "Even so, Lucilius.", acl: "5.1.100" },
  {
    p: "CASSIUS",
    l: "Now, most noble Brutus,",
    acl: "5.1.101",
  },
  {
    p: "CASSIUS",
    l: "The gods to-day stand friendly, that we may,",
    acl: "5.1.102",
  },
  {
    p: "CASSIUS",
    l: "Lovers in peace, lead on our days to age!",
    acl: "5.1.103",
  },
  {
    p: "CASSIUS",
    l: "But since the affairs of men rest still incertain,",
    acl: "5.1.104",
  },
  {
    p: "CASSIUS",
    l: "Let's reason with the worst that may befall.",
    acl: "5.1.105",
  },
  {
    p: "CASSIUS",
    l: "If we do lose this battle, then is this",
    acl: "5.1.106",
  },
  {
    p: "CASSIUS",
    l: "The very last time we shall speak together:",
    acl: "5.1.107",
  },
  { p: "CASSIUS", l: "What are you then determined to do?", acl: "5.1.108" },
  {
    p: "BRUTUS",
    l: "Even by the rule of that philosophy",
    acl: "5.1.109",
  },
  { p: "BRUTUS", l: "By which I did blame Cato for the death", acl: "5.1.110" },
  {
    p: "BRUTUS",
    l: "Which he did give himself, I know not how,",
    acl: "5.1.111",
  },
  { p: "BRUTUS", l: "But I do find it cowardly and vile,", acl: "5.1.112" },
  {
    p: "BRUTUS",
    l: "For fear of what might fall, so to prevent",
    acl: "5.1.113",
  },
  {
    p: "BRUTUS",
    l: "The time of life: arming myself with patience",
    acl: "5.1.114",
  },
  {
    p: "BRUTUS",
    l: "To stay the providence of some high powers",
    acl: "5.1.115",
  },
  { p: "BRUTUS", l: "That govern us below.", acl: "5.1.116" },
  {
    p: "CASSIUS",
    l: "Then, if we lose this battle,",
    acl: "5.1.117",
  },
  { p: "CASSIUS", l: "You are contented to be led in triumph", acl: "5.1.118" },
  {
    p: "CASSIUS",
    l: "Thorough the streets of Rome?",
    acl: "5.1.119",
  },
  {
    p: "BRUTUS",
    l: "No, Cassius, no: think not, thou noble Roman,",
    acl: "5.1.120",
  },
  {
    p: "BRUTUS",
    l: "That ever Brutus will go bound to Rome,",
    acl: "5.1.121",
  },
  {
    p: "BRUTUS",
    l: "He bears too great a mind. But this same day",
    acl: "5.1.122",
  },
  {
    p: "BRUTUS",
    l: "Must end that work the ides of March begun,",
    acl: "5.1.123",
  },
  {
    p: "BRUTUS",
    l: "And whether we shall meet again I know not.",
    acl: "5.1.124",
  },
  {
    p: "BRUTUS",
    l: "Therefore our everlasting farewell take:",
    acl: "5.1.125",
  },
  {
    p: "BRUTUS",
    l: "For ever, and for ever, farewell, Cassius!",
    acl: "5.1.126",
  },
  {
    p: "BRUTUS",
    l: "If we do meet again, why, we shall smile,",
    acl: "5.1.127",
  },
  {
    p: "BRUTUS",
    l: "If not, why then, this parting was well made.",
    acl: "5.1.128",
  },
  {
    p: "CASSIUS",
    l: "For ever, and for ever, farewell, Brutus!",
    acl: "5.1.129",
  },
  {
    p: "CASSIUS",
    l: "If we do meet again, we'll smile indeed,",
    acl: "5.1.130",
  },
  {
    p: "CASSIUS",
    l: "If not, 'tis true this parting was well made.",
    acl: "5.1.131",
  },
  {
    p: "BRUTUS",
    l: "Why, then, lead on. O, that a man might know",
    acl: "5.1.132",
  },
  {
    p: "BRUTUS",
    l: "The end of this day's business ere it come!",
    acl: "5.1.133",
  },
  { p: "BRUTUS", l: "But it sufficeth that the day will end,", acl: "5.1.134" },
  {
    p: "BRUTUS",
    l: "And then the end is known. Come, ho! away!",
    acl: "5.1.135",
  },
  { p: "BRUTUS", l: "Exeunt", acl: "" },
  {
    p: "BRUTUS",
    l: "SCENE II. The same. The field of battle.",
    acl: "",
  },
  { p: "BRUTUS", l: "Alarum. Enter BRUTUS and MESSALA", acl: "" },
  {
    p: "BRUTUS",
    l: "Ride, ride, Messala, ride, and give these bills",
    acl: "5.2.1",
  },
  { p: "BRUTUS", l: "Unto the legions on the other side.", acl: "5.2.2" },
  {
    p: "BRUTUS",
    l: "Loud alarum",
    acl: "",
  },
  { p: "BRUTUS", l: "Let them set on at once, for I perceive", acl: "5.2.3" },
  {
    p: "BRUTUS",
    l: "But cold demeanor in Octavius' wing,",
    acl: "5.2.4",
  },
  { p: "BRUTUS", l: "And sudden push gives them the overthrow.", acl: "5.2.5" },
  {
    p: "BRUTUS",
    l: "Ride, ride, Messala: let them all come down.",
    acl: "5.2.6",
  },
  { p: "BRUTUS", l: "Exeunt", acl: "" },
  {
    p: "BRUTUS",
    l: "SCENE III. Another part of the field.",
    acl: "",
  },
  { p: "BRUTUS", l: "Alarums. Enter CASSIUS and TITINIUS", acl: "" },
  {
    p: "CASSIUS",
    l: "O, look, Titinius, look, the villains fly!",
    acl: "5.3.1",
  },
  { p: "CASSIUS", l: "Myself have to mine own turn'd enemy:", acl: "5.3.2" },
  {
    p: "CASSIUS",
    l: "This ensign here of mine was turning back,",
    acl: "5.3.3",
  },
  {
    p: "CASSIUS",
    l: "I slew the coward, and did take it from him.",
    acl: "5.3.4",
  },
  {
    p: "TITINIUS",
    l: "O Cassius, Brutus gave the word too early,",
    acl: "5.3.5",
  },
  { p: "TITINIUS", l: "Who, having some advantage on Octavius,", acl: "5.3.6" },
  {
    p: "TITINIUS",
    l: "Took it too eagerly: his soldiers fell to spoil,",
    acl: "5.3.7",
  },
  { p: "TITINIUS", l: "Whilst we by Antony are all enclosed.", acl: "5.3.8" },
  {
    p: "TITINIUS",
    l: "Enter PINDARUS",
    acl: "",
  },
  {
    p: "PINDARUS",
    l: "Fly further off, my lord, fly further off,",
    acl: "5.3.9",
  },
  {
    p: "PINDARUS",
    l: "Mark Antony is in your tents, my lord",
    acl: "5.3.10",
  },
  {
    p: "PINDARUS",
    l: "Fly, therefore, noble Cassius, fly far off.",
    acl: "5.3.11",
  },
  {
    p: "CASSIUS",
    l: "This hill is far enough. Look, look, Titinius,",
    acl: "5.3.12",
  },
  {
    p: "CASSIUS",
    l: "Are those my tents where I perceive the fire?",
    acl: "5.3.13",
  },
  {
    p: "TITINIUS",
    l: "They are, my lord.",
    acl: "5.3.14",
  },
  { p: "CASSIUS", l: "Titinius, if thou lovest me,", acl: "5.3.15" },
  {
    p: "CASSIUS",
    l: "Mount thou my horse, and hide thy spurs in him,",
    acl: "5.3.16",
  },
  {
    p: "CASSIUS",
    l: "Till he have brought thee up to yonder troops,",
    acl: "5.3.17",
  },
  {
    p: "CASSIUS",
    l: "And here again, that I may rest assured",
    acl: "5.3.18",
  },
  {
    p: "CASSIUS",
    l: "Whether yond troops are friend or enemy.",
    acl: "5.3.19",
  },
  {
    p: "TITINIUS",
    l: "I will be here again, even with a thought.",
    acl: "5.3.20",
  },
  { p: "TITINIUS", l: "Exit", acl: "" },
  {
    p: "CASSIUS",
    l: "Go, Pindarus, get higher on that hill,",
    acl: "5.3.21",
  },
  {
    p: "CASSIUS",
    l: "My sight was ever thick, regard Titinius,",
    acl: "5.3.22",
  },
  {
    p: "CASSIUS",
    l: "And tell me what thou notest about the field.",
    acl: "5.3.23",
  },
  { p: "CASSIUS", l: "PINDARUS ascends the hill", acl: "" },
  {
    p: "CASSIUS",
    l: "This day I breathed first: time is come round,",
    acl: "5.3.24",
  },
  {
    p: "CASSIUS",
    l: "And where I did begin, there shall I end,",
    acl: "5.3.25",
  },
  {
    p: "CASSIUS",
    l: "My life is run his compass. Sirrah, what news?",
    acl: "5.3.26",
  },
  { p: "PINDARUS", l: "[Above]  O my lord!", acl: "5.3.27" },
  {
    p: "CASSIUS",
    l: "What news?",
    acl: "5.3.28",
  },
  {
    p: "PINDARUS",
    l: "[Above]  Titinius is enclosed round about",
    acl: "5.3.29",
  },
  {
    p: "PINDARUS",
    l: "With horsemen, that make to him on the spur,",
    acl: "5.3.30",
  },
  {
    p: "PINDARUS",
    l: "Yet he spurs on. Now they are almost on him.",
    acl: "5.3.31",
  },
  {
    p: "PINDARUS",
    l: "Now, Titinius! Now some light. O, he lights too.",
    acl: "5.3.32",
  },
  { p: "PINDARUS", l: "He's ta'en.", acl: "5.3.33" },
  { p: "PINDARUS", l: "Shout", acl: "" },
  {
    p: "PINDARUS",
    l: "And, hark! they shout for joy.",
    acl: "5.3.34",
  },
  { p: "CASSIUS", l: "Come down, behold no more.", acl: "5.3.35" },
  {
    p: "CASSIUS",
    l: "O, coward that I am, to live so long,",
    acl: "5.3.36",
  },
  {
    p: "CASSIUS",
    l: "To see my best friend ta'en before my face!",
    acl: "5.3.37",
  },
  {
    p: "CASSIUS",
    l: "PINDARUS descends",
    acl: "",
  },
  { p: "CASSIUS", l: "Come hither, sirrah:", acl: "5.3.38" },
  {
    p: "CASSIUS",
    l: "In Parthia did I take thee prisoner,",
    acl: "5.3.39",
  },
  {
    p: "CASSIUS",
    l: "And then I swore thee, saving of thy life,",
    acl: "5.3.40",
  },
  {
    p: "CASSIUS",
    l: "That whatsoever I did bid thee do,",
    acl: "5.3.41",
  },
  {
    p: "CASSIUS",
    l: "Thou shouldst attempt it. Come now, keep thine oath,",
    acl: "5.3.42",
  },
  {
    p: "CASSIUS",
    l: "Now be a freeman: and with this good sword,",
    acl: "5.3.43",
  },
  {
    p: "CASSIUS",
    l: "That ran through Caesar's bowels, search this bosom.",
    acl: "5.3.44",
  },
  {
    p: "CASSIUS",
    l: "Stand not to answer: here, take thou the hilts,",
    acl: "5.3.45",
  },
  {
    p: "CASSIUS",
    l: "And, when my face is cover'd, as 'tis now,",
    acl: "5.3.46",
  },
  {
    p: "CASSIUS",
    l: "Guide thou the sword.",
    acl: "5.3.47",
  },
  { p: "CASSIUS", l: "PINDARUS stabs him", acl: "" },
  {
    p: "CASSIUS",
    l: "Caesar, thou art revenged,",
    acl: "5.3.48",
  },
  { p: "CASSIUS", l: "Even with the sword that kill'd thee.", acl: "5.3.49" },
  {
    p: "CASSIUS",
    l: "Dies",
    acl: "",
  },
  {
    p: "PINDARUS",
    l: "So, I am free, yet would not so have been,",
    acl: "5.3.50",
  },
  {
    p: "PINDARUS",
    l: "Durst I have done my will. O Cassius,",
    acl: "5.3.51",
  },
  {
    p: "PINDARUS",
    l: "Far from this country Pindarus shall run,",
    acl: "5.3.52",
  },
  {
    p: "PINDARUS",
    l: "Where never Roman shall take note of him.",
    acl: "5.3.53",
  },
  { p: "PINDARUS", l: "Exit", acl: "" },
  {
    p: "PINDARUS",
    l: "Re-enter TITINIUS with MESSALA",
    acl: "",
  },
  {
    p: "MESSALA",
    l: "It is but change, Titinius, for Octavius",
    acl: "5.3.54",
  },
  {
    p: "MESSALA",
    l: "Is overthrown by noble Brutus' power,",
    acl: "5.3.55",
  },
  { p: "MESSALA", l: "As Cassius' legions are by Antony.", acl: "5.3.56" },
  {
    p: "TITINIUS",
    l: "These tidings will well comfort Cassius.",
    acl: "5.3.57",
  },
  { p: "MESSALA", l: "Where did you leave him?", acl: "5.3.58" },
  {
    p: "TITINIUS",
    l: "All disconsolate,",
    acl: "5.3.59",
  },
  {
    p: "TITINIUS",
    l: "With Pindarus his bondman, on this hill.",
    acl: "5.3.60",
  },
  {
    p: "MESSALA",
    l: "Is not that he t hat lies upon the ground?",
    acl: "5.3.61",
  },
  {
    p: "TITINIUS",
    l: "He lies not like the living. O my heart!",
    acl: "5.3.62",
  },
  {
    p: "MESSALA",
    l: "Is not that he?",
    acl: "5.3.63",
  },
  { p: "TITINIUS", l: "No, this was he, Messala,", acl: "5.3.64" },
  {
    p: "TITINIUS",
    l: "But Cassius is no more. O setting sun,",
    acl: "5.3.65",
  },
  {
    p: "TITINIUS",
    l: "As in thy red rays thou dost sink to-night,",
    acl: "5.3.66",
  },
  {
    p: "TITINIUS",
    l: "So in his red blood Cassius' day is set,",
    acl: "5.3.67",
  },
  {
    p: "TITINIUS",
    l: "The sun of Rome is set! Our day is gone,",
    acl: "5.3.68",
  },
  {
    p: "TITINIUS",
    l: "Clouds, dews, and dangers come, our deeds are done!",
    acl: "5.3.69",
  },
  {
    p: "TITINIUS",
    l: "Mistrust of my success hath done this deed.",
    acl: "5.3.70",
  },
  {
    p: "MESSALA",
    l: "Mistrust of good success hath done this deed.",
    acl: "5.3.71",
  },
  { p: "MESSALA", l: "O hateful error, melancholy's child,", acl: "5.3.72" },
  {
    p: "MESSALA",
    l: "Why dost thou show to the apt thoughts of men",
    acl: "5.3.73",
  },
  {
    p: "MESSALA",
    l: "The things that are not? O error, soon conceived,",
    acl: "5.3.74",
  },
  {
    p: "MESSALA",
    l: "Thou never comest unto a happy birth,",
    acl: "5.3.75",
  },
  {
    p: "MESSALA",
    l: "But kill'st the mother that engender'd thee!",
    acl: "5.3.76",
  },
  {
    p: "TITINIUS",
    l: "What, Pindarus! where art thou, Pindarus?",
    acl: "5.3.77",
  },
  { p: "MESSALA", l: "Seek him, Titinius, whilst I go to meet", acl: "5.3.78" },
  {
    p: "MESSALA",
    l: "The noble Brutus, thrusting this report",
    acl: "5.3.79",
  },
  { p: "MESSALA", l: "Into his ears, I may say, thrusting it,", acl: "5.3.80" },
  {
    p: "MESSALA",
    l: "For piercing steel and darts envenomed",
    acl: "5.3.81",
  },
  {
    p: "MESSALA",
    l: "Shall be as welcome to the ears of Brutus",
    acl: "5.3.82",
  },
  {
    p: "MESSALA",
    l: "As tidings of this sight.",
    acl: "5.3.83",
  },
  { p: "TITINIUS", l: "Hie you, Messala,", acl: "5.3.84" },
  {
    p: "TITINIUS",
    l: "And I will seek for Pindarus the while.",
    acl: "5.3.85",
  },
  { p: "TITINIUS", l: "Exit MESSALA", acl: "" },
  {
    p: "TITINIUS",
    l: "Why didst thou send me forth, brave Cassius?",
    acl: "5.3.86",
  },
  {
    p: "TITINIUS",
    l: "Did I not meet thy friends? and did not they",
    acl: "5.3.87",
  },
  {
    p: "TITINIUS",
    l: "Put on my brows this wreath of victory,",
    acl: "5.3.88",
  },
  {
    p: "TITINIUS",
    l: "And bid me give it thee? Didst thou not hear their shouts?",
    acl: "5.3.89",
  },
  {
    p: "TITINIUS",
    l: "Alas, thou hast misconstrued every thing!",
    acl: "5.3.90",
  },
  {
    p: "TITINIUS",
    l: "But, hold thee, take this garland on thy brow,",
    acl: "5.3.91",
  },
  { p: "TITINIUS", l: "Thy Brutus bid me give it thee, and I", acl: "5.3.92" },
  {
    p: "TITINIUS",
    l: "Will do his bidding. Brutus, come apace,",
    acl: "5.3.93",
  },
  { p: "TITINIUS", l: "And see how I regarded Caius Cassius.", acl: "5.3.94" },
  {
    p: "TITINIUS",
    l: "By your leave, gods:--this is a Roman's part",
    acl: "5.3.95",
  },
  {
    p: "TITINIUS",
    l: "Come, Cassius' sword, and find Titinius' heart.",
    acl: "5.3.96",
  },
  {
    p: "TITINIUS",
    l: "Kills himself",
    acl: "",
  },
  {
    p: "TITINIUS",
    l: "Alarum. Re-enter MESSALA, with BRUTUS, CATO, STRATO, VOLUMNIUS, and LUCILIUS",
    acl: "",
  },
  {
    p: "BRUTUS",
    l: "Where, where, Messala, doth his body lie?",
    acl: "5.3.97",
  },
  {
    p: "MESSALA",
    l: "Lo, yonder, and Titinius mourning it.",
    acl: "5.3.98",
  },
  { p: "BRUTUS", l: "Titinius' face is upward.", acl: "5.3.99" },
  {
    p: "CATO",
    l: "He is slain.",
    acl: "5.3.100",
  },
  { p: "BRUTUS", l: "O Julius Caesar, thou art mighty yet!", acl: "5.3.101" },
  {
    p: "BRUTUS",
    l: "Thy spirit walks abroad and turns our swords",
    acl: "5.3.102",
  },
  { p: "BRUTUS", l: "In our own proper entrails.", acl: "5.3.103" },
  {
    p: "BRUTUS",
    l: "Low alarums",
    acl: "",
  },
  { p: "CATO", l: "Brave Titinius!", acl: "5.3.104" },
  {
    p: "CATO",
    l: "Look, whether he have not crown'd dead Cassius!",
    acl: "5.3.105",
  },
  {
    p: "BRUTUS",
    l: "Are yet two Romans living such as these?",
    acl: "5.3.106",
  },
  {
    p: "BRUTUS",
    l: "The last of all the Romans, fare thee well!",
    acl: "5.3.107",
  },
  { p: "BRUTUS", l: "It is impossible that ever Rome", acl: "5.3.108" },
  {
    p: "BRUTUS",
    l: "Should breed thy fellow. Friends, I owe more tears",
    acl: "5.3.109",
  },
  {
    p: "BRUTUS",
    l: "To this dead man than you shall see me pay.",
    acl: "5.3.110",
  },
  {
    p: "BRUTUS",
    l: "I shall find time, Cassius, I shall find time.",
    acl: "5.3.111",
  },
  {
    p: "BRUTUS",
    l: "Come, therefore, and to Thasos send his body:",
    acl: "5.3.112",
  },
  {
    p: "BRUTUS",
    l: "His funerals shall not be in our camp,",
    acl: "5.3.113",
  },
  { p: "BRUTUS", l: "Lest it discomfort us. Lucilius, come,", acl: "5.3.114" },
  {
    p: "BRUTUS",
    l: "And come, young Cato, let us to the field.",
    acl: "5.3.115",
  },
  { p: "BRUTUS", l: "Labeo and Flavius, set our battles on:", acl: "5.3.116" },
  {
    p: "BRUTUS",
    l: "'Tis three o'clock, and, Romans, yet ere night",
    acl: "5.3.117",
  },
  { p: "BRUTUS", l: "We shall try fortune in a second fight.", acl: "5.3.118" },
  {
    p: "BRUTUS",
    l: "Exeunt",
    acl: "",
  },
  { p: "BRUTUS", l: "SCENE IV. Another part of the field.", acl: "" },
  {
    p: "BRUTUS",
    l: "Alarum. Enter fighting, Soldiers of both armies, then BRUTUS, CATO, LUCILIUS, and others",
    acl: "",
  },
  {
    p: "BRUTUS",
    l: "Yet, countrymen, O, yet hold up your heads!",
    acl: "5.4.1",
  },
  {
    p: "CATO",
    l: "What bastard doth not? Who will go with me?",
    acl: "5.4.2",
  },
  { p: "CATO", l: "I will proclaim my name about the field:", acl: "5.4.3" },
  {
    p: "CATO",
    l: "I am the son of Marcus Cato, ho!",
    acl: "5.4.4",
  },
  { p: "CATO", l: "A foe to tyrants, and my country's friend,", acl: "5.4.5" },
  {
    p: "CATO",
    l: "I am the son of Marcus Cato, ho!",
    acl: "5.4.6",
  },
  { p: "BRUTUS", l: "And I am Brutus, Marcus Brutus, I,", acl: "5.4.7" },
  {
    p: "BRUTUS",
    l: "Brutus, my country's friend, know me for Brutus!",
    acl: "5.4.8",
  },
  { p: "BRUTUS", l: "Exit", acl: "" },
  {
    p: "LUCILIUS",
    l: "O young and noble Cato, art thou down?",
    acl: "5.4.9",
  },
  {
    p: "LUCILIUS",
    l: "Why, now thou diest as bravely as Titinius,",
    acl: "5.4.10",
  },
  {
    p: "LUCILIUS",
    l: "And mayst be honour'd, being Cato's son.",
    acl: "5.4.11",
  },
  { p: "First Soldier", l: "Yield, or thou diest.", acl: "5.4.12" },
  {
    p: "LUCILIUS",
    l: "Only I yield to die:",
    acl: "5.4.13",
  },
  {
    p: "LUCILIUS",
    l: "There is so much that thou wilt kill me straight,",
    acl: "5.4.14",
  },
  {
    p: "LUCILIUS",
    l: "Offering money",
    acl: "",
  },
  {
    p: "LUCILIUS",
    l: "Kill Brutus, and be honour'd in his death.",
    acl: "5.4.15",
  },
  {
    p: "First Soldier",
    l: "We must not. A noble prisoner!",
    acl: "5.4.16",
  },
  {
    p: "Second Soldier",
    l: "Room, ho! Tell Antony, Brutus is ta'en.",
    acl: "5.4.17",
  },
  {
    p: "First Soldier",
    l: "I'll tell the news. Here comes the general.",
    acl: "5.4.18",
  },
  { p: "First Soldier", l: "Enter ANTONY", acl: "" },
  {
    p: "First Soldier",
    l: "Brutus is ta'en, Brutus is ta'en, my lord.",
    acl: "5.4.19",
  },
  { p: "ANTONY", l: "Where is he?", acl: "5.4.20" },
  {
    p: "LUCILIUS",
    l: "Safe, Antony, Brutus is safe enough:",
    acl: "5.4.21",
  },
  { p: "LUCILIUS", l: "I dare assure thee that no enemy", acl: "5.4.22" },
  {
    p: "LUCILIUS",
    l: "Shall ever take alive the noble Brutus:",
    acl: "5.4.23",
  },
  {
    p: "LUCILIUS",
    l: "The gods defend him from so great a shame!",
    acl: "5.4.24",
  },
  {
    p: "LUCILIUS",
    l: "When you do find him, or alive or dead,",
    acl: "5.4.25",
  },
  {
    p: "LUCILIUS",
    l: "He will be found like Brutus, like himself.",
    acl: "5.4.26",
  },
  {
    p: "ANTONY",
    l: "This is not Brutus, friend, but, I assure you,",
    acl: "5.4.27",
  },
  {
    p: "ANTONY",
    l: "A prize no less in worth: keep this man safe,",
    acl: "5.4.28",
  },
  {
    p: "ANTONY",
    l: "Give him all kindness: I had rather have",
    acl: "5.4.29",
  },
  { p: "ANTONY", l: "Such men my friends than enemies. Go on,", acl: "5.4.30" },
  {
    p: "ANTONY",
    l: "And see whether Brutus be alive or dead,",
    acl: "5.4.31",
  },
  { p: "ANTONY", l: "And bring us word unto Octavius' tent", acl: "5.4.32" },
  {
    p: "ANTONY",
    l: "How every thing is chanced.",
    acl: "5.4.33",
  },
  { p: "ANTONY", l: "Exeunt", acl: "" },
  {
    p: "ANTONY",
    l: "SCENE V. Another part of the field.",
    acl: "",
  },
  {
    p: "ANTONY",
    l: "Enter BRUTUS, DARDANIUS, CLITUS, STRATO, and VOLUMNIUS",
    acl: "",
  },
  {
    p: "BRUTUS",
    l: "Come, poor remains of friends, rest on this rock.",
    acl: "5.5.1",
  },
  {
    p: "CLITUS",
    l: "Statilius show'd the torch-light, but, my lord,",
    acl: "5.5.2",
  },
  {
    p: "CLITUS",
    l: "He came not back: he is or ta'en or slain.",
    acl: "5.5.3",
  },
  {
    p: "BRUTUS",
    l: "Sit thee down, Clitus: slaying is the word,",
    acl: "5.5.4",
  },
  {
    p: "BRUTUS",
    l: "It is a deed in fashion. Hark thee, Clitus.",
    acl: "5.5.5",
  },
  { p: "BRUTUS", l: "Whispers", acl: "" },
  {
    p: "CLITUS",
    l: "What, I, my lord? No, not for all the world.",
    acl: "5.5.6",
  },
  { p: "BRUTUS", l: "Peace then! no words.", acl: "5.5.7" },
  {
    p: "CLITUS",
    l: "I'll rather kill myself.",
    acl: "5.5.8",
  },
  { p: "BRUTUS", l: "Hark thee, Dardanius.", acl: "5.5.9" },
  {
    p: "BRUTUS",
    l: "Whispers",
    acl: "",
  },
  { p: "DARDANIUS", l: "Shall I do such a deed?", acl: "5.5.10" },
  {
    p: "CLITUS",
    l: "O Dardanius!",
    acl: "5.5.11",
  },
  { p: "DARDANIUS", l: "O Clitus!", acl: "5.5.12" },
  {
    p: "CLITUS",
    l: "What ill request did Brutus make to thee?",
    acl: "5.5.13",
  },
  {
    p: "DARDANIUS",
    l: "To kill him, Clitus. Look, he meditates.",
    acl: "5.5.14",
  },
  {
    p: "CLITUS",
    l: "Now is that noble vessel full of grief,",
    acl: "5.5.15",
  },
  { p: "CLITUS", l: "That it runs over even at his eyes.", acl: "5.5.16" },
  {
    p: "BRUTUS",
    l: "Come hither, good Volumnius, list a word.",
    acl: "5.5.17",
  },
  { p: "VOLUMNIUS", l: "What says my lord?", acl: "5.5.18" },
  {
    p: "BRUTUS",
    l: "Why, this, Volumnius:",
    acl: "5.5.19",
  },
  { p: "BRUTUS", l: "The ghost of Caesar hath appear'd to me", acl: "5.5.20" },
  {
    p: "BRUTUS",
    l: "Two several times by night, at Sardis once,",
    acl: "5.5.21",
  },
  {
    p: "BRUTUS",
    l: "And, this last night, here in Philippi fields:",
    acl: "5.5.22",
  },
  {
    p: "BRUTUS",
    l: "I know my hour is come.",
    acl: "5.5.23",
  },
  { p: "VOLUMNIUS", l: "Not so, my lord.", acl: "5.5.24" },
  {
    p: "BRUTUS",
    l: "Nay, I am sure it is, Volumnius.",
    acl: "5.5.25",
  },
  {
    p: "BRUTUS",
    l: "Thou seest the world, Volumnius, how it goes,",
    acl: "5.5.26",
  },
  {
    p: "BRUTUS",
    l: "Our enemies have beat us to the pit:",
    acl: "5.5.27",
  },
  { p: "BRUTUS", l: "Low alarums", acl: "" },
  {
    p: "BRUTUS",
    l: "It is more worthy to leap in ourselves,",
    acl: "5.5.28",
  },
  {
    p: "BRUTUS",
    l: "Than tarry till they push us. Good Volumnius,",
    acl: "5.5.29",
  },
  {
    p: "BRUTUS",
    l: "Thou know'st that we two went to school together:",
    acl: "5.5.30",
  },
  {
    p: "BRUTUS",
    l: "Even for that our love of old, I prithee,",
    acl: "5.5.31",
  },
  {
    p: "BRUTUS",
    l: "Hold thou my sword-hilts, whilst I run on it.",
    acl: "5.5.32",
  },
  {
    p: "VOLUMNIUS",
    l: "That's not an office for a friend, my lord.",
    acl: "5.5.33",
  },
  {
    p: "VOLUMNIUS",
    l: "Alarum still",
    acl: "",
  },
  {
    p: "CLITUS",
    l: "Fly, fly, my lord, there is no tarrying here.",
    acl: "5.5.34",
  },
  {
    p: "BRUTUS",
    l: "Farewell to you, and you, and you, Volumnius.",
    acl: "5.5.35",
  },
  {
    p: "BRUTUS",
    l: "Strato, thou hast been all this while asleep,",
    acl: "5.5.36",
  },
  {
    p: "BRUTUS",
    l: "Farewell to thee too, Strato. Countrymen,",
    acl: "5.5.37",
  },
  {
    p: "BRUTUS",
    l: "My heart doth joy that yet in all my life",
    acl: "5.5.38",
  },
  {
    p: "BRUTUS",
    l: "I found no man but he was true to me.",
    acl: "5.5.39",
  },
  { p: "BRUTUS", l: "I shall have glory by this losing day", acl: "5.5.40" },
  {
    p: "BRUTUS",
    l: "More than Octavius and Mark Antony",
    acl: "5.5.41",
  },
  { p: "BRUTUS", l: "By this vile conquest shall attain unto.", acl: "5.5.42" },
  {
    p: "BRUTUS",
    l: "So fare you well at once, for Brutus' tongue",
    acl: "5.5.43",
  },
  { p: "BRUTUS", l: "Hath almost ended his life's history:", acl: "5.5.44" },
  {
    p: "BRUTUS",
    l: "Night hangs upon mine eyes, my bones would rest,",
    acl: "5.5.45",
  },
  {
    p: "BRUTUS",
    l: "That have but labour'd to attain this hour.",
    acl: "5.5.46",
  },
  {
    p: "BRUTUS",
    l: "Alarum. Cry within, 'Fly, fly, fly!'",
    acl: "5.5.46",
  },
  { p: "CLITUS", l: "Fly, my lord, fly.", acl: "5.5.47" },
  {
    p: "BRUTUS",
    l: "Hence! I will follow.",
    acl: "5.5.48",
  },
  { p: "BRUTUS", l: "Exeunt CLITUS, DARDANIUS, and VOLUMNIUS", acl: "" },
  {
    p: "BRUTUS",
    l: "I prithee, Strato, stay thou by thy lord:",
    acl: "5.5.49",
  },
  { p: "BRUTUS", l: "Thou art a fellow of a good respect,", acl: "5.5.50" },
  {
    p: "BRUTUS",
    l: "Thy life hath had some smatch of honour in it:",
    acl: "5.5.51",
  },
  {
    p: "BRUTUS",
    l: "Hold then my sword, and turn away thy face,",
    acl: "5.5.52",
  },
  {
    p: "BRUTUS",
    l: "While I do run upon it. Wilt thou, Strato?",
    acl: "5.5.53",
  },
  {
    p: "STRATO",
    l: "Give me your hand first. Fare you well, my lord.",
    acl: "5.5.54",
  },
  {
    p: "BRUTUS",
    l: "Farewell, good Strato.",
    acl: "5.5.55",
  },
  { p: "BRUTUS", l: "Runs on his sword", acl: "" },
  {
    p: "BRUTUS",
    l: "Caesar, now be still:",
    acl: "5.5.56",
  },
  {
    p: "BRUTUS",
    l: "I kill'd not thee with half so good a will.",
    acl: "5.5.57",
  },
  {
    p: "BRUTUS",
    l: "Dies",
    acl: "",
  },
  {
    p: "BRUTUS",
    l: "Alarum. Retreat. Enter OCTAVIUS, ANTONY, MESSALA, LUCILIUS, and the army",
    acl: "",
  },
  { p: "OCTAVIUS", l: "What man is that?", acl: "5.5.58" },
  {
    p: "MESSALA",
    l: "My master's man. Strato, where is thy master?",
    acl: "5.5.59",
  },
  {
    p: "STRATO",
    l: "Free from the bondage you are in, Messala:",
    acl: "5.5.60",
  },
  {
    p: "STRATO",
    l: "The conquerors can but make a fire of him,",
    acl: "5.5.61",
  },
  { p: "STRATO", l: "For Brutus only overcame himself,", acl: "5.5.62" },
  {
    p: "STRATO",
    l: "And no man else hath honour by his death.",
    acl: "5.5.63",
  },
  {
    p: "LUCILIUS",
    l: "So Brutus should be found. I thank thee, Brutus,",
    acl: "5.5.64",
  },
  {
    p: "LUCILIUS",
    l: "That thou hast proved Lucilius' saying true.",
    acl: "5.5.65",
  },
  {
    p: "OCTAVIUS",
    l: "All that served Brutus, I will entertain them.",
    acl: "5.5.66",
  },
  {
    p: "OCTAVIUS",
    l: "Fellow, wilt thou bestow thy time with me?",
    acl: "5.5.67",
  },
  { p: "STRATO", l: "Ay, if Messala will prefer me to you.", acl: "5.5.68" },
  {
    p: "OCTAVIUS",
    l: "Do so, good Messala.",
    acl: "5.5.69",
  },
  { p: "MESSALA", l: "How died my master, Strato?", acl: "5.5.70" },
  {
    p: "STRATO",
    l: "I held the sword, and he did run on it.",
    acl: "5.5.71",
  },
  { p: "MESSALA", l: "Octavius, then take him to follow thee,", acl: "5.5.72" },
  {
    p: "MESSALA",
    l: "That did the latest service to my master.",
    acl: "5.5.73",
  },
  { p: "ANTONY", l: "This was the noblest Roman of them all:", acl: "5.5.74" },
  {
    p: "ANTONY",
    l: "All the conspirators save only he",
    acl: "5.5.75",
  },
  {
    p: "ANTONY",
    l: "Did that they did in envy of great Caesar,",
    acl: "5.5.76",
  },
  {
    p: "ANTONY",
    l: "He only, in a general honest thought",
    acl: "5.5.77",
  },
  {
    p: "ANTONY",
    l: "And common good to all, made one of them.",
    acl: "5.5.78",
  },
  {
    p: "ANTONY",
    l: "His life was gentle, and the elements",
    acl: "5.5.79",
  },
  {
    p: "ANTONY",
    l: "So mix'd in him that Nature might stand up",
    acl: "5.5.80",
  },
  {
    p: "ANTONY",
    l: "And say to all the world 'This was a man!'",
    acl: "5.5.81",
  },
  {
    p: "OCTAVIUS",
    l: "According to his virtue let us use him,",
    acl: "5.5.82",
  },
  {
    p: "OCTAVIUS",
    l: "With all respect and rites of burial.",
    acl: "5.5.83",
  },
  {
    p: "OCTAVIUS",
    l: "Within my tent his bones to-night shall lie,",
    acl: "5.5.84",
  },
  {
    p: "OCTAVIUS",
    l: "Most like a soldier, order'd honourably.",
    acl: "5.5.85",
  },
  {
    p: "OCTAVIUS",
    l: "So call the field to rest, and let's away,",
    acl: "5.5.86",
  },
  {
    p: "OCTAVIUS",
    l: "To part the glories of this happy day.",
    acl: "5.5.87",
  },
  { p: "OCTAVIUS", l: "Exeunt", acl: "" },
];
