import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "./App.css";
import {
  AppBar,
  Avatar,
  Box,
  Container,
  createTheme,
  Grid,
  IconButton,
  Paper,
  Stack,
  ThemeProvider,
  Toolbar,
  Typography,
} from "@mui/material";
import { play } from "./data";
import { players } from "./data";

import StarBorderRoundedIcon from "@mui/icons-material/StarBorderRounded";
import StarHalfRoundedIcon from "@mui/icons-material/StarHalfRounded";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useState } from "react";
import {Quote} from "./data/quote";

const StartType = {
  Filled: 2,
  Half: 1,
  Empty: 0,
};

function Line(props) {
  return <Typography variant="body1">{props.text}</Typography>;
}

function makeArray(length, element) {
  return Array.apply(null, Array(length)).map((_) => element);
}

function Star(props) {
  return props.type === StartType.Filled ? (
    <StarRoundedIcon />
  ) : props.type === StartType.Half ? (
    <StarHalfRoundedIcon />
  ) : (
    <StarBorderRoundedIcon />
  );
}

function Rating(props) {
  const isLastHaftStar = props.nStars % 1 > 0;
  const filledStars = Math.floor(props.nStars);
  const emptyStarts = isLastHaftStar ? 5 - filledStars - 1 : 5 - filledStars;
  const stars = isLastHaftStar
    ? makeArray(filledStars, StartType.Filled)
        .concat([StartType.Half])
        .concat(makeArray(emptyStarts, StartType.Empty))
    : makeArray(filledStars, StartType.Filled).concat(
        makeArray(emptyStarts, StartType.Empty)
      );
  return (
    <div>
      {stars.map((type, idx) => (
        <Star type={type} key={idx} />
      ))}
    </div>
  );
}

function caesarRating(players) {
  const allRatings = players.filter((p) => p.rating).map((p) => p.rating);
  const rating =
    allRatings.reduce((acc, item) => acc + item, 0) / allRatings.length;
  return rating;
}

function CaesarCard(props) {
  return (
    <Paper elevation={5} style={{ textAlign: "justify", padding: 30 }}>
      <Container>
        <Stack direction={"column"} spacing={1}>
          <Stack direction={"row"} spacing={2}>
            <Avatar>JC</Avatar>
            <Stack direction={"column"} spacing={0}>
              <Typography variant="h6">Julius Caesar</Typography>
              <Typography variant="body2">Tyrant in chief</Typography>
            </Stack>
          </Stack>
          <Rating nStars={caesarRating(players)} />
          <Typography variant="button">Historical note</Typography>
          <Typography variant="body1">
            Julius Caesar was an extremely influential figure in world history.
            His family name is still remembered in many languages as a word
            signifying ruler or leader; in slavic languages this is tsar, in
            German it is Kaiser, and in Islamic languages it is qaysar. Caesar
            was not such a tyrannical leader as you may think, he advocated for
            rights for Roman commoners instead of the elite, as was the norm at
            the time. This explains the hatred many senators had for him, and at
            the same time the love the commoners held for him. Caesar’s 4
            triumphs resulted in thousands of tons of silver being brought into
            Rome, which he spent on ensuring the retirement pay of many Roman
            soldiers (who were mostly plebeians). However, Caesar’s ruling
            principles did not abide by those in the Roman republic, in their
            eyes, he acted too similar to that of an Emperor. Not long after
            Caesar crossed the Rubicon and claimed the city of Rome, he was
            assassinated by a large group of senators. What followed after
            Caesar’s death was not peace, but a period of unrest and civil war
            within Rome. After all the dust had settled, the Roman Empire was
            established. Ironically, this empire was much more similar to
            Caesar’s ruling style than the republic the senators so desired.
          </Typography>
        </Stack>
      </Container>
    </Paper>
  );
}

function toSelectedQuote({name, idx}) {
  return `${name}-${idx}`;
}

function fromSelectedQuote(selectedQuote) {
  const [name, idx] = selectedQuote.split("-");
  return { name, idx };
}

function getQuote(selected) {
  const {name, idx} = fromSelectedQuote(selected);
  const quotes = players.filter(p => p.name === name)[0].quotes;
  let quote = quotes[idx];
  console.log(quote)
  return quote;
}

function Reviewer(props) {
  return (
    <Container style={{ textAlign: "justify", padding: 30 }}>
      <Stack direction={"column"} spacing={1}>
        <Stack direction={"row"} spacing={2}>
          <Avatar>{props.initials}</Avatar>
          <Stack direction={"column"}>
            <Typography variant="h6">{props.name}</Typography>
            <Typography variant="body2">{props.description}</Typography>
          </Stack>
        </Stack>
        <Rating nStars={props.rating} />
        <Typography variant="body1">{props.review}</Typography>
        <ToggleButtonGroup
          color="primary"
          value={props.selectedQuote}
          exclusive
          onChange={(_, x) => props.onQuoteChange(fromSelectedQuote(x))}
        >
          {props.quotes.map((quote, idx) => (
              <ToggleButton value={toSelectedQuote({name: props.name, idx})} key={idx}>{`Quote ${idx+1}`}</ToggleButton>
          ))}

        </ToggleButtonGroup>
      </Stack>
    </Container>
  );
}

function Play() {
  return (
    <div className="App">
      {play.map((line, idx) => (
        <Line text={line.l} key={idx} />
      ))}
    </div>
  );
}

const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#1976d2",
    },
  },
});

function appBarLabel(label) {
  return (
    <Toolbar>
      <IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
        <RestaurantIcon />
      </IconButton>
      <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
        {label}
      </Typography>
    </Toolbar>
  );
}

function App() {
  const [selectedQuote, changeSelectedQuote] = useState();

  const onQuoteChange = (value) => {
    changeSelectedQuote(toSelectedQuote(value));
  };

  return (
    <ThemeProvider theme={theme}>
      <Stack direction={"column"} spacing={1}>
        <AppBar position="static" color="primary" enableColorOnDark>
          {appBarLabel("Caesar reviews")}
        </AppBar>
        <Container>
          <Box sx={{ my: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CaesarCard />
              </Grid>
              <Grid item xs={6}>
                {players
                  .filter((p) => p.rating)
                  .map((player, idx) => (
                    <Reviewer
                      {...player}
                      selectedQuote={selectedQuote}
                      onQuoteChange={onQuoteChange}
                      key={idx}
                    />
                  ))}
              </Grid>
              <Grid item xs={6}>
                {selectedQuote ? <Quote {...getQuote(selectedQuote)}/> : ""}
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Stack>
    </ThemeProvider>
  );
}

export default App;
