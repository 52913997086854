import React from "react"
import {play} from "./play";
import {Container, Stack, Typography} from "@mui/material";

function findLineIndex(acl){
    return play.findIndex(l => l.acl === acl)
}

function findLines(start, end) {
    const s = findLineIndex(start);
    const e = findLineIndex(end);
    return [...play].slice(s,e+1).map(x => x.l);
}

function getLabel(start) {
    const [act, scene] = start.split('.');
    return `Supporting quote (from act ${act}, scene ${scene})`;
}

export function Quote(props) {
    const lines = findLines(props.start, props.end);
    return (
        <Container style={{ textAlign: "justify", padding: 30 }}>
            <Stack spacing={3} direction={"column"}>
                <Typography variant="h5" style={{ textAlign: "center"}}>Explanation</Typography>
                <Typography variant={"body1"}>{props.text}</Typography>
            <Typography variant="h5" style={{ textAlign: "center"}}>{getLabel(props.start)}</Typography>
            <Container>
                {lines.map((l, i) => <Typography variant={"body1"} fontStyle="italic" key={i}>{l}</Typography>)}
            </Container>
            </Stack>
        </Container>
    )
}