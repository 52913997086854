export const players = [
  {
    name: "Calpurnia",
    initials: "CA",
    rating: 4,
    description: "Wife",
    review:
      "My beloved Caesar, the strongest and most powerful husband anyone has ever had. I wish nothing but safety for you. Although, your ego can be overwhelming sometimes, and I hope that you would listen to me more. I must not be such a weak woman if you took me as your bride, so please listen to my opinion, it may save your life once or twice.",
    quotes: [
      {
        start: "2.2.51",
        end: "2.2.57",
        text: "Calpurnia tries to prevent Caesar from going to the senate on the day of his murder. She claims that in her dreams she saw a gruesome foreshadowing of his death that day. As his wife, she does not wish for his death, and tries to prevent him from leaving the house. But in this quote, she also calls Caesar egotistical, meaning that even she understands his flaws.",
      },
      {
        start: "2.2.8",
        end: "2.2.9",
        text: "Caesar leaving the house despite this request from his wife means that he likely does not often listen to her. This explains Calpurnia’s later words that he is an egotist, as Caesar was persuaded to leave by Decius praising his name, and twisting the dream to seem like a boon rather than a nightmare.",
      },
    ],
  },
  {
    name: "Antony",
    initials: "AN",
    rating: 4.5,
    description: "Humble servant",
    review:
      "Oh lord Caesar, you are wonderful, powerful, and creative. I cannot see how anyone within the senate could possibly disagree with you. You are the apex of Rome, and your strength is unmatched. I stand with you till the end, and swear loyalty!",
    quotes: [
      {
        start: "1.2.13",
        end: "1.2.13",
        text: "As the audience sees Caesar and Antony for the first time, this quote appears. The audience immediately recognizes Antony's devotion to Caesar. Both characters have mutual respect and admiration for each other.",
      },
      {
        start: "2.1.162",
        end: "2.1.168",
        text: "As the conspirators plot to assassinate Caesar, Cassius explains why they should also murder Antony, imparting crucial information about Antony's character. Antony's hazardous plotting abilities, as well as his excellent connections, make him powerful, according to Cassius. Even if Brutus ignores Cassius' advice, the audience will see that Cassius' description of Antony was true.",
      },
      {
        start: "3.1.142",
        end: "3.1.149",
        text: "Soon after Caesar's death, Antony's servant delivers word from Antony to Brutus. Antony promises allegiance to Brutus in his message, and although though he loved and was faithful to Caesar, he swears that if Brutus can explain why Caesar had to be assassinated, Antony will be even more loyal to Brutus. While Brutus regards Antony's goals as honorable, Antony's hidden ambitions are not yet revealed.",
      },
      {
        start: "3.1.174",
        end: "3.1.177",
        text: "Antony appears before Brutus and Cassius for the first time since they assassinated Caesar. He lays down his love for Caesar, but he also accepts his death if Brutus and Cassius intended to kill him as well. Antony gains Brutus' trust by emphasizing this noble sacrifice and stating that there is no better place to die than alongside Caesar.",
      },
    ],
  },
  {
    name: "Brutus",
    initials: "BR",
    rating: 2,
    description: "Certified assassin/conspirator",
    review:
      "My old friend, you used to be so nice. But this rule that you are perpetrating is not for the best of Rome. There is a possibility that you are a tyrant, and that is unforgivable. My good relations with you must come to an end, for the betterment of Rome.",
    quotes: [
      {
        start: "1.2.88",
        end: "1.2.95",
        text: "Brutus explains the internal struggle he has with his public and private selves. On one hand, Brutus professes his love for Caesar, but on the other, he concedes that his duty to the Roman people will take precedence over his personal love for Caesar. While Brutus is respectable for his devotion to Rome, it is his internal strife that ultimately brings him down.",
      },
      {
        start: "4.3.21",
        end: "4.3.29",
        text: "Brutus and Cassius fight over their initial objective and why they assassinated Caesar. Brutus is questioning Cassius' allegiance to Rome while reiterating why he agreed to murder Caesar. Brutus once again demonstrates his honorable intentions and dedication to the good of Rome by making this bold declaration and standing up to Cassius.",
      },
    ],
  },
  {
    name: "Cassius",
    initials: "CA",
    rating: 0.5,
    description: "Certified assassin/conspirator",
    review:
      "Absolutely awful! This weakling does not deserve to be the head of Rome! It is completely ridiculous that he should be allowed such power. Why not I such as Cassius to lead??? I am far stronger than Caesar and his tyranny!",
    quotes: [
      {
        start: "1.2.125",
        end: "1.2.137",
        label: "Act 1. Scene 2.",
        text: "Cassius expresses his discontent with Caesar's ascension to power to Brutus in Act I. He tells Brutus several accounts of Caesar's weakness, showing his unfitness to lead. Such as the time Caesar nearly drowned and became ill. Cassius feels they are both stronger and better qualified to lead than Caesar. Cassius' arguments reveal his own goals while also pointing to the power struggle that is brewing in Rome. Cassius is ultimately challenging Caesar's strength and power in order to sow distrust of Caesar in Brutus' mind.",
      },
      {
        start: "1.3.92",
        end: "1.3.103",
        text: "Cassius confronts Brutus about Caesar's tyranny and how he would rather kill himself before surrendering to the current situation. Cassius uses this remark to speak about his own character's potential to break away from Caesar's thrall. Cassius declares that he is more powerful than the tyranny since he has the ability to terminate it at any time by killing himself. This quote of his later becomes ironic, as Cassius ends up killing himself, long after Caesar is dead.",
      },
    ],
  },
];
